import React, {useContext, useEffect, useRef, useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Routes from "./Routes";
import {
    Box, Button, CircularProgress, createTheme,
    CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ThemeProvider, Typography
} from "@mui/material";
import AppContext from "./AppContext";
import API, {HandleError} from "./API";
import {useSnackbar} from "notistack";
import {INACTIVITY_TIME} from "./Vars";
import {Close} from "@mui/icons-material";
import {red} from "@mui/material/colors";

window.isUserActive = true;
var isInactivityPopupShown = false;
const performanceTheme = createTheme({
    // props: {
    //     // Name of the component
    //     MuiButtonBase: {
    //         // The properties to apply
    //         disableRipple: true, // No more ripple, on the whole application!
    //     },
    // },
    transitions: {
        // So we have `transition: none;` everywhere
        create: () => 'none',
    },
});
const performanceThemeDark = createTheme({
    palette: {
        mode: 'dark',
    },
    transitions: {
        create: () => 'none',
    },
});

const regularTheme = createTheme();
const regularThemeDark = createTheme({
    palette: {
        mode: 'dark',
    }
})

export default function App() {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);
    const appRef = useRef({});
    let subRoutes = {};

    useEffect(() => {
        appRef.current = app;
    }, [app])

    const [showInactivity, setShowInactivity] = useState(false);

    const ping = async () => {
        if(!window.document.hidden && appRef?.current?.isLoggedIn()) {
            let lastPing = window.sessionStorage.getItem('last_ping');
            let minPing = new Date();

            minPing.setSeconds(minPing.getSeconds() - 30)
            if(lastPing === null || new Date(lastPing) < minPing) {
                window.sessionStorage.setItem('last_ping', (new Date()).toString());
                try {
                    const res = await API({
                        method: 'GET', url: `/ping`,
                        headers: {
                            'Authorization': `Bearer ${appRef.current.token}`,
                        },
                        data: {}
                    })

                    if (res.status < 200 || res.status > 299)
                        throw res.data;

                    appRef.current.storePing(res.data.user, res.data.ui);
                }
                catch (e) {
                    HandleError(e, 'PING', enqueueSnackbar, app.logout);
                }
            }
        }
    }

    const openInactivityPopup = () => {
        console.log('stop record activity!');
        ['mousemove', 'touchmove', 'scroll', 'click', 'keypress'].forEach(function(event){
            window.removeEventListener(event, _onActivity);
        });
        window.isUserActive = false;
        setShowInactivity(true);
        isInactivityPopupShown = true;
    }
    const closeInactivityPopup = () => {
        window.isUserActive = true;
        setShowInactivity(false);
        isInactivityPopupShown = false;
        recordActivity();
    }

    const _onActivity = (e) => {
        if(!isInactivityPopupShown)
            window.isUserActive = true;

        clearTimeout(window.userActivityTimer);
        window.userActivityTimer = setTimeout(() => {
            window.isUserActive = false;
            openInactivityPopup();
        }, 1000 * INACTIVITY_TIME);
    }
    const recordActivity = () => {
        console.log('record activity!');
        clearInterval(window.userActivityTimer);
        window.userActivityTimer = setTimeout(() => {
            window.isUserActive = false;
            openInactivityPopup();
        }, 1000 * INACTIVITY_TIME);
        ['mousemove', 'touchmove', 'scroll', 'click', 'keypress'].forEach(function(event){
            window.addEventListener(event, _onActivity);
        });
    }
    useEffect(() => {
        setInterval(ping, 5000);
        recordActivity();
    }, [])
    return (
      <Router>
          <ThemeProvider theme={app.ui.performanceMode ? (app.ui.darkMode ? performanceThemeDark : performanceTheme) : (app.ui.darkMode ? regularThemeDark : regularTheme)}>
              <Box sx={{ display: 'flex' }}>
                  <CssBaseline />
                  <Switch>
                      {Object.keys(Routes).map((path, idx) => {
                          let r = Routes[path];
                          if(path.substr(0, 1) !== '/') {
                              if(r?.items) {
                                  Object.keys(r.items).forEach((subPath, subIdx) => {
                                      subRoutes[subPath] = r.items[subPath];
                                  });
                              }
                              return null;
                          }
                          else {
                              let can = r?.cap ? app.can(r.cap.scope, r.cap.name) : true;
                              if(!can)
                                  return null;

                              if(r?.public)
                                  return <Route key={"" + idx} exact={!r?.any} path={path} component={r.component} />
                              else
                                  return <PrivateRoute key={"" + idx} exact={!r?.any} path={path} component={r.component} />
                          }
                      })}
                      {Object.keys(subRoutes).map((path, idx) => {
                          let r = subRoutes[path];
                          let can = r?.cap ? app.can(r.cap.scope, r.cap.name) : true;
                          if(!can)
                              return null;

                          if(r?.public)
                              return <Route key={"" + idx} exact={!r?.any} path={path} component={r.component} />
                          else
                              return <PrivateRoute key={"" + idx} exact={!r?.any} path={path} component={r.component}/>
                      })}
                      <Route render={() => <Redirect to="/" />} />
                  </Switch>
                  <Dialog open={showInactivity && window.lockingTimer !== null} maxWidth="sm" fullWidth={false}>
                      <DialogTitle sx={{ m: 0, p: 2 }}>
                          <Typography variant="h6" component="div">
                              Inactivity Warning
                          </Typography>
                      </DialogTitle>
                      <DialogContent dividers>
                          <Box component="div" sx={{ mt: 1 }}>
                              You have been inactive for over 2 minutes.
                              <br />
                              Someone may have took over the item you were editing.
                          </Box>
                          <DialogActions>
                              <Button onClick={closeInactivityPopup} sx={{color: (theme) => theme.palette.grey[700]}}>
                                  Okay
                              </Button>
                          </DialogActions>
                      </DialogContent>
                  </Dialog>
              </Box>
          </ThemeProvider>
      </Router>
    );
}