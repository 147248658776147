import * as React from "react";
import {Button, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {Check, DoneAll, Flag, HourglassEmptyOutlined, Remove} from "@mui/icons-material";

export default function FlagsMenu({ flags, filters, setFilters, table, manager }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFlagChange = (name, value) => {
        const newFilters = {...filters}
        newFilters[name] = value;
        setFilters({...newFilters});
    }


    var selectedFlags = 0;
    Object.keys(flags).forEach((key, idx) => {
        if(key === 'result') {
            if(['pass', 'fail', 'none'].indexOf(filters[key]) !== -1) {
                selectedFlags++;
            }
        }
        else if(parseInt(filters[key]) === 1 || parseInt(filters[key]) === 0)
            selectedFlags++;
        else if(['all', 'semi', 'none'].indexOf(filters[key]) !== -1)
            selectedFlags++;
    })

    return (
        <div>
            <Button sx={{mt: 2}}
                    onClick={handleClick}
                    disabled={table.loading}
                    variant={selectedFlags > 0 ? 'contained' : 'text'}
            >
                <Flag />
                Flags {selectedFlags > 0 ? `(${selectedFlags})` : ''}
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {Object.keys(flags).map((key, idx) => {
                    return <MenuItem key={key}>
                        <span style={{minWidth: '120px'}}>
                            {flags[key]}
                        </span>
                        <ToggleButtonGroup
                            size="small"
                            value={key === 'result' || (manager === 'students' && key !== 'processed' && key !== 'is_company') ? filters[key] : parseInt(filters[key])}
                            exclusive
                            onChange={(e, value) => handleFlagChange(key, value) }
                        >
                            <ToggleButton value={key === 'result' ? 'pass' : (manager === 'students' && key !== 'processed' && key !== 'is_company' ? 'all' : 1)}>
                                <Tooltip title={key === 'result' ? 'Passed' : (manager === 'students' && key !== 'processed' && key !== 'is_company' ? 'All' : 'Yes')}>
                                    {manager === 'students' && key !== 'result' && key !== 'processed' && key !== 'is_company' ? <DoneAll /> : <Check />}
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value={key === 'result' ? 'fail' : (manager === 'students' && key !== 'processed' && key !== 'is_company' ? 'semi' : 0)}>
                                <Tooltip title={key === 'result' ? 'Failed' : (manager === 'students' && key !== 'processed' && key !== 'is_company' ? 'Semi' : 'No')}>
                                    {manager === 'students' && key !== 'result' && key !== 'processed' && key !== 'is_company' ? <Check /> : <Remove />}
                                </Tooltip>
                            </ToggleButton>
                            {key !== 'result' && key !== 'processed' && key !== 'is_company' && manager === 'students' &&
                            <ToggleButton value="none">
                                <Tooltip title="None">
                                    <Remove />
                                </Tooltip>
                            </ToggleButton>}
                            {key === 'result' &&
                            <ToggleButton value={'none'}>
                                <Tooltip title="Not taken yet">
                                    <HourglassEmptyOutlined />
                                </Tooltip>
                            </ToggleButton>}
                        </ToggleButtonGroup>
                    </MenuItem>
                })}
            </Menu>
        </div>
    );
}