import {useContext, useState} from "react";
import * as React from 'react';
import {Avatar, IconButton, InputBase, ListItemAvatar, Paper} from "@mui/material";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography} from "@mui/material";
import AppContext from "../AppContext";
import {
    Check,
    Close,
    DoneAllOutlined,
    HourglassEmptyOutlined,
    Search,
    Textsms,
    TextsmsOutlined
} from "@mui/icons-material";
import {green} from "@mui/material/colors";
import {fdatetime} from "../Common";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";

const MailHistory = ({ history, setHistory, item }) => {
    const app = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const [openCategories, setOpenCategories] = useState([]);
    const [openTemplates, setOpenTemplates] = useState({});
    const [activeTemplate, setActiveTemplate] = useState('');
    const [search, setSearch] = useState('');
    const [sending, setSending] = useState(false);

    const handleSearch = (e) => {
        setSearch(e.target.value);
        setOpenCategories([]);
        setOpenTemplates({});
    }

    const categoryVisible = (section) => {
        return openCategories.indexOf(section) !== -1
    }
    const toggleCategory = (section) => {
        let categories = [...openCategories];
        const idx = categories.indexOf(section);
        if(idx !== -1)
            categories.splice(idx, 1);
        else
            categories.push(section);

        setOpenCategories([...categories])
        return true;
    }

    const toggleTemplate = (e, template) => {
        let m = {...openTemplates}
        m[template.ID] = !m?.[template.ID]
        setOpenTemplates(m);
    }

    const askSend = (e, template) => {
        e.stopPropagation();
        if(sending)
            return;

        setActiveTemplate(template.ID);
    }

    const confirmSend = async (e) => {
        e.stopPropagation();
        setSending(true);
        try {
            const res = await API({
                method: 'POST', url: `/student/${item?.ID}/mail`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {
                    template: activeTemplate
                }
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            if(res.data?.results)
                setHistory(res.data.results);

            enqueueSnackbar('Mail was queued successfully.', { variant: 'success' });
            setSending(false);
            setActiveTemplate('');
        }
        catch(e) {
            HandleError(e, 'Mail Error', enqueueSnackbar, app.logout);
            setSending(false);
            setActiveTemplate('');
        }
    }

    const discardSend = (e) => {
        e.stopPropagation();
        if(sending)
            return;

        setActiveTemplate('');
    }

    var filtered = {};
    if(search !== '') {
        Object.keys(app.ui.mail_templates).forEach((category, x) => {
            if(category.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                filtered[category] = app.ui.mail_templates[category]
                return;
            }
            Object.keys(app.ui.mail_templates[category]).forEach((template_name, y) => {
                let template = app.ui.mail_templates[category][template_name]
                if(template.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    template.description.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                    if(!filtered.hasOwnProperty(category))
                        filtered[category] = [];

                    filtered[category].push(template);
                }
            });
        });
    }
    else
        filtered = {...app.ui.mail_templates}

    return <>
        <Paper
            component="div"
            sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search templates"
                onChange={handleSearch} value={search}
            />
            <IconButton type="submit" sx={{ p: '10px' }}>
                <Search />
            </IconButton>
        </Paper>
        {Object.keys(filtered).map((category, idx) => {
            var totalSent = 0;
            Object.keys(filtered[category]).map((template_id, y) => {
                let template = filtered[category][template_id];
                const tplHistory = history.hasOwnProperty(template.ID) ? history[template.ID] : [];
                totalSent += tplHistory.length;
            });
            return <Accordion key={'acc-' + idx} expanded={categoryVisible(category)}
                              onChange={() => toggleCategory(category)} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                >
                    <Typography sx={{ width: '66%', flexShrink: 0 }}>
                        {category}
                    </Typography>
                    {totalSent > 0 &&
                    <Typography sx={{ marginLeft: 'auto', paddingRight: '20px', color: 'rgba(25, 118, 210, 0.3)' }}>
                        <Tooltip title={`Category has sent templates.`}>
                            <TextsmsOutlined />
                        </Tooltip>
                    </Typography>}
                </AccordionSummary>
                <AccordionDetails sx={{pt: 0, pb: 1, px: 1}}>
                    <List
                        sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
                        component="nav">
                        {Object.keys(filtered[category]).map((template_id, y) => {
                            let template = filtered[category][template_id];
                            const tplHistory = history.hasOwnProperty(template.ID) ? history[template.ID] : [];
                            return  <>
                                <ListItemButton key={'listitembutton-' + y} onClick={(e) => toggleTemplate(e, template)}>
                                    {activeTemplate === template_id ? (
                                        sending ? <>
                                            <ListItemIcon>
                                                <Tooltip title="Sending...">
                                                    <SendIcon />
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={`Sending "${template.name}" template...`} secondary={'Please wait until request is finished.'} />
                                        </> :
                                            <>
                                                <ListItemIcon>
                                                    <Tooltip title="Discard">
                                                        <Close color="error" onClick={discardSend} />
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={`Send "${template.name}" template?`} secondary={template.description} />
                                                <ListItemAvatar>
                                                    <Tooltip title="Confirm">
                                                        <Avatar sx={{ bgcolor: green[500] }}>
                                                            <Check color="white" onClick={confirmSend} />
                                                        </Avatar>
                                                    </Tooltip>
                                                </ListItemAvatar>
                                            </>
                                    ) : <>
                                        <ListItemIcon onClick={(e) => askSend(e, template)} >
                                            <Tooltip title="Send template">
                                                <SendIcon color="primary" />
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={template.name} secondary={template.description} />
                                    </>}
                                    {tplHistory.length > 0 && activeTemplate !== template_id &&
                                    <ListItemIcon>
                                        {tplHistory.length} &nbsp;
                                        {openTemplates?.[template.ID] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemIcon>
                                    }
                                </ListItemButton>
                                {tplHistory.length > 0 &&
                                <>
                                    <Collapse in={openTemplates?.[template.ID]} timeout={0} unmountOnExit>
                                        <List component="div" dense disablePadding>
                                            {tplHistory.map((h, z) => {
                                                return <ListItemButton key={'list-item-subbutton-' + z} sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        {h.status === 'pending' ? <HourglassEmptyOutlined /> : <DoneAllOutlined color="success" />}
                                                    </ListItemIcon>
                                                    <ListItemText primary={h.status === 'pending' ? `Scheduled to be sent at ${fdatetime(h.sendAt)}.` : `Sent on ${fdatetime(h.sentAt)}.`} />
                                                </ListItemButton>
                                            })}
                                        </List>
                                    </Collapse>
                                </>
                                }
                            </>
                        })}

                        {/*<ListItemButton onClick={handleClick}>*/}
                        {/*    <ListItemIcon>*/}
                        {/*        <InboxIcon />*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText primary="Inbox" />*/}
                        {/*    {open ? <ExpandLess /> : <ExpandMore />}*/}
                        {/*</ListItemButton>*/}
                        {/*<Collapse in={open} timeout="auto" unmountOnExit>*/}
                        {/*    <List component="div" disablePadding>*/}
                        {/*        <ListItemButton sx={{ pl: 4 }}>*/}
                        {/*            <ListItemIcon>*/}
                        {/*                <StarBorder />*/}
                        {/*            </ListItemIcon>*/}
                        {/*            <ListItemText primary="Starred" />*/}
                        {/*        </ListItemButton>*/}
                        {/*    </List>*/}
                        {/*</Collapse>*/}
                    </List>
                </AccordionDetails>
            </Accordion>
        })}
    </>;
}

export default MailHistory;