import * as React from 'react';
import {
    Box, Button, Checkbox, Chip, Link, Menu, MenuItem,
    Paper, Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    TableSortLabel, Tooltip, Typography
} from "@mui/material";
import TableHead from '@mui/material/TableHead';
import { visuallyHidden } from '@mui/utils';
import TableToolbar from "./TableToolbar";
import {useContext, useEffect} from "react";
import {fdate, fdatetime, make_url, price, stringToColor, strip_url_params} from "../Common";
import AvatarLetters from "./AvatarLetters";
import AppContext from "../AppContext";
import './ResultsTable.css';
import {CheckCircleOutline, LockClockOutlined, Wifi} from "@mui/icons-material";

function CreateCourseMenu({ date, cbr_location, onCreateCourseClick }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (product) => {
        setAnchorEl(null);
        if(['RVM1-C', 'VM2-C', 'VM3-C'].indexOf(product) !== -1)
            onCreateCourseClick(product, date, cbr_location);
    };

    return (
        <div>
            <Button onClick={handleClick}>
                Create...
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleClose('RVM1-C')}>RVM1-C</MenuItem>
                <MenuItem onClick={() => handleClose('VM2-C')}>VM2-C</MenuItem>
                <MenuItem onClick={() => handleClose('VM3-C')}>VM3-C</MenuItem>
            </Menu>
        </div>
    );
}

export default function ResultsTable({ manager, refreshTable, table, setTable, resetFilters, filters, setFilters,
                                         onMainRowClick, onDeleteClick, onBulkClick, selectedRow,
                                         mailPopup, setMailPopup, premadeFilters, children, onCreateCourseClick }) {
    const app = useContext(AppContext);
    useEffect(() => {
        refreshTable();

        let params = {
            page: table.page,
            per_page: table.per_page,
            order: table.order,
            order_by: table.order_by,
        };
        for(var k in filters) {
            if(filters[k] !== '' && filters[k] !== null && filters[k] !== undefined)
                params[k] = filters[k];
        }

        delete params['search_by_options'];

        let url = make_url(strip_url_params(window.location.href), params);
        window.history.replaceState(null, null, url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.page, table.per_page, table.order, table.order_by, filters])

    const selectAll = (e) => {
        if (e.target.checked) {
            const newSelected = table.results.map((n) => n.ID);
            setTable({...table, selected: newSelected});
            return;
        }
        setTable({...table, selected: []});
    }

    const handleChangePage = (e, newPage) => {
        setTable({...table, page: parseInt(newPage + 1)});
    }

    const handleChangeRowsPerPage = (e) => {
        setTable({...table, per_page: parseInt(e.target.value, 10), page: 1})
    }

    const toggleSelect = (ID) => {
        let selected = table.selected;
        const idx = table.selected.indexOf(ID);
        if(idx !== -1)
            selected.splice(idx, 1);
        else
            selected.push(ID);

        setTable({...table, selected: selected })
    }

    const sortByField = (field) => {
        let order = table.order;
        if(table.order_by === field)
            order = order === 'asc' ? 'desc' : 'asc';
        setTable({...table, order_by: field, order: order})
    }

    const openMailPopup = (row) => {
        setMailPopup({...mailPopup, reload: true, item: row, open: true });
    }
    const formatCourses = (courses, props = {}) => {
        if(Object.keys(courses)?.length > 0) {
            let out = [];
            Object.keys(courses).forEach((product, idx) => {
                let color = '';
                let course = courses[product];
                if(course.booking.cancelled || course.booking.status.toLowerCase().indexOf('err') === 0)
                    color = 'error';
                else if(course.booking.absent)
                    color = 'warning';
                else if(course.booking.status === 'reserved' || course.booking.confirmed)
                    color = 'success';
                else if(course.booking.status === 'registered')
                    color = 'info';

                const props = color ? { size: 'small', variant: 'outlined', color: color } : { size: 'small' };
                let status = [];
                if(course.booking.status)
                    status = [
                        `Status: ${course.booking.status.toUpperCase()}`,
                        `Time: ${course.booking.time || "N/a"}`,
                        `Code: ${course.booking.code || "N/a"}`,
                        `Account: ${course.booking.account || "N/a"}`,
                        `CBR Location: ${course.booking.cbr_location || "N/a"}`
                    ]
                else
                    status = ['No status'];

                if(course.booking.cancelled)
                    status.unshift(`CANCELLED!`)
                if(course.booking.absent)
                    status.unshift(`ABSENT!`)
                if((!course.booking.cancelled && !course.booking.absent) && course.booking.confirmed)
                    status.unshift(`CONFIRMED`)

                out.push(<div key={out.length} style={{marginBottom: '3px'}}>
                    <Tooltip arrow={true} enterDelay={0} placement='left-start' title={<div style={{whiteSpace: 'pre-line'}}>
                        {status.join("\n")}
                    </div>}>
                        <Chip label={course.product} {...props} />
                    </Tooltip>
                    &nbsp; {`${fdate(course.date)} in ${course.location}`}
                </div>)
            })
            return out;
        }
        else
            return <Chip variant="outlined" color="error" label="Invalid value!" />
    }

    const format_value = (field, value, row) => {
        if(table.loading)
            return <Typography component="div" variant="body2"><Skeleton /></Typography>;

        if(['createdAt', 'updatedAt', 'touchedAt', 'lockedAt'].indexOf(field) !== -1)
            return value ? fdatetime(value) : 'N/a';

        if(['date', 'exam_date'].indexOf(field) !== -1)
            return value ? fdate(value) : value;

        if(['suspended', 'cancelled'].indexOf(field) !== -1)
            return value ? <Chip label="Yes" color="error" size="small" variant="outlined" /> :
                <Chip label="No" color="primary" size="small" variant="outlined" />

        if(['active'].indexOf(field) !== -1)
            return value ? <Chip label="Active" color="primary" size="small" variant="outlined" /> :
                <Chip label="Inactive" color="error" size="small" variant="outlined" />

        if(field === 'roles') {
            const out = [];
            for(let k in value)
                out.push(value[k].nicename)

            return out.join(', ');
        }

        if(manager === 'users') {
            if(field === 'full_name') {
                return <><AvatarLetters name={row} /> {value} {row?.is_online ? <Tooltip title="Online"><Wifi fontSize={"sm"} color={"success"} /></Tooltip> : null}</>
            }
        }
        else if(manager === 'students') {
            if(field === 'full_name') {
                let helpButtons = [];
                let admin_notes = null;

                if(app.can('student', 'mail'))
                    helpButtons.push(<Chip key={helpButtons.length} onClick={() => openMailPopup(row)} label="Mail" size="small" variant="outlined" color="info" />);

                if(row?.company)
                    helpButtons.push(<Tooltip key={helpButtons.length} placement="right-start" title={<div style={{whiteSpace: 'pre-line', fontSize: '12px'}}>
                        {row?.company}
                    </div>}><Chip variant="outlined" label={"Company"} size="small" /></Tooltip>);

                if(row?.admin_notes && row.admin_notes.length > 0) {
                    let admin_notes_preview = [];
                    for(let x = 0; x < row.admin_notes.length; x++) {
                        admin_notes_preview.push(`[${fdatetime(row.admin_notes[x]['date'])}] ${row.admin_notes[x]['user']['full_name']}: ${row.admin_notes[x]['text']}`);
                    }
                    admin_notes = <Tooltip key={helpButtons.length} placement="right-start" title={<div style={{whiteSpace: 'pre-line', fontSize: '12px'}}>
                        {admin_notes_preview.join("\n")}
                    </div>}><Chip variant="outlined" label={"Notes"} size="small" /></Tooltip>
                    helpButtons.push(admin_notes)
                }
                return <><AvatarLetters name={row} /> {value} {row.confirmed ? <Tooltip title="Confirmed"><CheckCircleOutline color="success" fontSize="inherit" /></Tooltip> : ''}
                {helpButtons.length ? <div className="row-buttons">{helpButtons}</div> : null}</>
            }
            if(field === 'courses') {
                return formatCourses(value);
            }
            else if(field === 'status')
                return app.statusLabel(value);
            else if(field === 'payment_info') {
                return app.paymentLabel(row);
            }
        }
        else if(manager === 'orders') {
            if(field === 'student') {
                if(value)
                    return <Link rel="noreferrer" underline="none" target="_blank"
                                 href={make_url('/students', { open: value.ID, search: value.ID })}>
                        {value.full_name} #{value.ID}
                    </Link>;
                else
                    return <Link rel="noreferrer" underline="none" target="_blank"
                              href={make_url('/students', { open: row.student_id, search: row.student_id })}>
                        #{row.student_id}
                    </Link>;
            }
            else if(field === 'status')
                return app.orderStatusLabel(value);
            else if(field === 'product')
                return app.orderProductLabel(value);
            else if(field === 'amount') {
                let refund = parseFloat(row.refunded_amount);
                let paid_amount = parseFloat(row.amount) - refund;
                if(!isFinite(refund) || refund <= 0)
                    return <span>€{price(value)}</span>
                else if(parseFloat(row.amount) <= refund)
                    return <Tooltip title={`Order has been refunded in full.`}>
                        <span><Chip size="small" variant="outlined" color="warning" label={`REF €${price(row.refunded_amount)}`} /></span>
                    </Tooltip>
                else
                    return <Tooltip title={`Order has partially refunded so the actual amount they paid is €${price(paid_amount)}`}>
                        <span>€{price(value)} <Chip size="small" variant="outlined" color="warning" label={`REF €${price(row.refunded_amount)}`} /></span>
                    </Tooltip>
            }
            else if(field === 'transaction_id') {
                return <code>{value}</code>
            }
        }
        else if(manager === 'courses') {
            if(field === 'spots')
                return <>
                    <Tooltip title="Reserved exams">
                        <Chip label={value.reserved} size="small" color="success" />
                    </Tooltip> |&nbsp;
                    <Tooltip title="Available purchased exams">
                        <Chip label={value.purchased} size="small" color="primary" />
                    </Tooltip> |&nbsp;
                    <Tooltip title="Available spots for purchase on CBR since last sync.">
                        <Chip label={value.spots} size="small" color="warning" />
                    </Tooltip>
                    {value.students > 0 &&
                    <Tooltip title="Shows how many students have signed up for this course. Red = demand is higher than available PURCHASED spots.">
                        <Chip sx={{ml: 1}} color={value.students > value.purchased ? 'error' : 'success'} variant="outlined" size="small" label={`Demand: ${value.students}`} />
                    </Tooltip>}
                </>
        }
        else if(manager === 'exams') {
            if(field === 'times') {
                return <>
                    {Object.keys(value).map((time, idx) => {
                        let v = value[time]
                        return <div style={{marginBottom: '3px'}}>
                            <Chip label={time} size="small" color="default" variant="outlined" /> &nbsp;
                            <Tooltip title="Reserved exams">
                                <Chip label={v.reserved} size="small" color="success"/>
                            </Tooltip> |&nbsp;
                            <Tooltip title="Available purchased exams">
                                <Chip label={v.purchased} size="small" color="primary"/>
                            </Tooltip> |&nbsp;
                            <Tooltip title="Available spots for purchase on CBR since last sync.">
                                <Chip label={v.spots} size="small" color="warning"/>
                            </Tooltip>
                        </div>
                    })}
                </>
            }
            else if(field === 'assigned') {
                // if(row?.product == 'V3C') {
                //     if(value)
                //         return <Tooltip title="Assigned to course">
                //             <Chip label={'V3C'} size="small" color="success"/>
                //         </Tooltip>
                //     else
                //         return <Button onClick={() => onCreateCourseClick('V3C', row?.date, row?.cbr_location)}>
                //             Create V3C
                //         </Button>
                // }
                if(row.linked_courses.length === 3)
                    return <>
                        {row.linked_courses.map((p, idx) => {
                        return <Tooltip title="Assigned to course" key={idx}>
                            <Chip label={p} size="small" color="success" sx={{mr: 1}}/>
                        </Tooltip>
                    })}</>
                else if(row.linked_courses.length === 0)
                    return <Tooltip title="Not assigned to any course, click to create new course">
                        <CreateCourseMenu date={row?.date} cbr_location={row?.cbr_location} onCreateCourseClick={onCreateCourseClick} />
                    </Tooltip>
                else
                    return <>
                        {['RVM1-C', 'VM2-C', 'VM3-C'].map((p, idx) => {
                            return row.linked_courses.indexOf(p) !== -1 ?
                                <Tooltip title="Assigned to course">
                                    <Chip label={p} size="small" color="success" sx={{mr: 1}}/>
                                </Tooltip> :
                                <Button
                                    onClick={() => onCreateCourseClick(p, row?.date, row?.cbr_location)}
                                >
                                    Create {p}
                                </Button>
                        })}
                    </>
            }
        }
        else if(manager === 'events') {
            if(field === 'event') {
                return <>{app.ui.event_names?.[value] ?? value}<div className="event-commit-id">{row?.commit_id}</div></>
            }
            else if(field === 'user_id') {
                if(app.ui.users?.[value])
                    return app.ui.users[value] + ' #' + value;

                return 'Unknown User #' + value;
            }
            else if(field === 'description') {
                if(typeof value === 'object') {
                    if([2, 11, 13, 31, 41, 51].indexOf(row?.event) !== -1) {
                        let descOut = [];
                        for(let k in value) {
                            let eOld = value[k]?.old;
                            let eNew = value[k]?.new;
                            if(typeof eOld === 'object')
                                eOld = JSON.stringify(eOld);
                            if(typeof eNew === 'object')
                                eNew = JSON.stringify(eNew);

                            descOut.push(<div className="event-desc-item" key={descOut.length}>
                                <strong>{k}</strong>
                                <Chip variant="outlined" size="small" label={eOld} /> => <Chip variant="outlined" size="small" label={eNew} color="info" /></div>)
                        }
                        return descOut;
                    }
                    return JSON.stringify(value);
                }
                else
                    return JSON.stringify(value);
            }
        }
        return value;
    }

    const rowStyle = (row) => {
        if(manager !== 'events' || !app.ui.colorEvents)
            return null;

        return { background: stringToColor(row.commit_id, 100, app.ui.darkMode ? 10 : 95) }
    }

    return <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
            <TableToolbar table={table} manager={manager} resetFilters={resetFilters} filters={filters} setFilters={setFilters} onReloadClick={refreshTable} setTable={setTable} premadeFilters={premadeFilters} onDeleteClick={onDeleteClick} onBulkClick={onBulkClick} />
            {React.cloneElement(children, { table, setTable, filters, setFilters })}
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                {table.loading ? <Typography component="div" variant="body1"><Skeleton /></Typography> : <Checkbox
                                    disabled={table.loading}
                                    color="primary"
                                    indeterminate={table.selected.length > 0 && table.selected.length < table.results.length}
                                    checked={table.results.length > 0 && table.selected.length === table.results.length}
                                    onChange={selectAll}
                                />}
                            </TableCell>
                            {(() => {
                                let cells = []
                                for(let c in table.columns) {
                                    let col = table.columns[c];
                                    if(!col?.visible)
                                        continue;

                                    cells.push(<TableCell
                                        key={col.field}
                                        data-column={col.field}
                                        align="left"
                                        padding="normal"
                                        sortDirection={col?.sortable && table.order_by === col.field ? table.order : false}
                                    >
                                        {col?.sortable && !table.loading ?
                                        <TableSortLabel
                                            active={table.order_by === col.field}
                                            direction={table.order}
                                            onClick={() => { sortByField(col.field) }}
                                        >
                                            {c}
                                            {table.order_by === col.field ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {table.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel> : c }
                                    </TableCell>)
                                }
                                return cells;
                            })()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table.results.map((row, idx) => {
                            const selected = table.selected.indexOf(row.ID) !== -1;
                            return (
                                <TableRow
                                    tabIndex={-1}
                                    key={idx}
                                    selected={selected}
                                    className={row?.suspended ? 'error' : ''}
                                    style={rowStyle(row)}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={selected}
                                            onClick={() => { toggleSelect(row.ID)}}
                                        />
                                    </TableCell>
                                    {(() => {
                                        let cells = [];
                                        let count = 0;
                                        for(let c in table.columns) {
                                            const col = table.columns[c];
                                            if(!col?.visible)
                                                continue;

                                            let val = format_value(col.field, row?.[col.field], row);
                                            let lock = row?.lock_user?.ID ? row.lock_user : false;

                                            cells.push(<TableCell key={'col_' + c} onDoubleClick={count === 0 && typeof onMainRowClick === "function" ? () => onMainRowClick(row) : null}>
                                                {val}
                                                {count === 0 && lock !== false && <div className="locked-label"><LockClockOutlined fontSize={"custom"} /> by {lock.full_name}</div>}
                                            </TableCell>);
                                            count++;
                                        }
                                        return cells;
                                    })()}
                                </TableRow>
                            );
                        })}
                        {!table.loading && !table.results.length ? <TableRow
                            tabIndex={-1}
                        >
                            <TableCell colSpan={Object.keys(table.columns).length + 1}>
                                No results found.
                            </TableCell>
                        </TableRow> : null}
                        {table.loading && !table.results.length && <>
                            {Array.from(Array(table.per_page).keys()).map((row, idx) =>
                                <TableRow
                                    tabIndex={-1}
                                    key={idx}
                                >
                                    <TableCell>
                                        <Typography component="div" variant="body1"><Skeleton /></Typography>
                                    </TableCell>
                                    {Array.from(Array(Object.keys(table.columns).length).keys()).map((row, idx) => {
                                        return <TableCell key={idx}>
                                            <Typography component="div" variant="body2"><Skeleton /></Typography>
                                        </TableCell>
                                    })}
                                </TableRow>
                            )}
                        </>}
                            </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                SelectProps={{ disabled: table.loading }}
                backIconButtonProps={table.loading ? { disabled: table.loading } : null}
                nextIconButtonProps={table.loading ? { disabled: table.loading } : null}
                component="div"
                count={table.total_count}
                rowsPerPage={table.per_page > 30 ? 30 : table.per_page}
                page={table.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </Box>
}