import * as React from 'react';
import Popup from "./Popup";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Divider, FormControl, FormGroup,
    IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Tooltip,
    Typography
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {DeleteOutlined, PushPin, PushPinOutlined} from "@mui/icons-material";
import AvatarLetters from "./AvatarLetters";
import {fdatetime} from "../Common";
import './StudentNotes.css';

export default function StudentNotes({ popup, setPopup, studentForm, setStudentForm, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);
    const [notes, setNotes] = useState([])
    const [newNote, setNewNote] = useState('');

    useEffect(() => {
        if(popup.item?.ID && popup.reload)
            onLoad();

    }, [popup.item, popup.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    const onLoad = async () => {
        setPopup({...popup, loading: true, reload: false, title: `Loading ${popup.item.full_name}'s admin notes...` })
        try {
            const res = await API({
                method: 'GET', url: `/student/${popup.item.ID}/notes`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            setPopup({...popup, loading: false,  reload: false, title: `${popup.item.full_name} #${popup.item.ID}'s Admin Notes`})
            setNotes(res.data.results)
            if(typeof setStudentForm === "function" && studentForm) {
                let newFields = {...studentForm.fields}
                newFields['admin_notes'] = [...res.data.results];
                setStudentForm({...studentForm, fields: newFields})
            }
        }
        catch(e) {
            onClose()
            HandleError(e, 'Load Notes', enqueueSnackbar, app.logout);
        }
    }

    const onClose = () => {
        setPopup({...popup, open: false, title: '', loading: false, reload: false });
        setNotes([])
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPopup({...popup, loading: true, title: `Adding note...`})
        try {
            const res = await API({
                method: 'POST', url: `/student/${popup.item.ID}/note`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {
                    text: newNote
                }
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            setPopup({...popup, loading: false, title: `${popup.item.full_name} #${popup.item.ID}'s Admin Notes`})
            setNewNote('');
            setNotes(res.data.results)
            if(typeof setStudentForm === "function" && studentForm) {
                let newFields = {...studentForm.fields}
                newFields['admin_notes'] = [...res.data.results];
                setStudentForm({...studentForm, fields: newFields})
            }
        }
        catch(e) {
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.full_name}'s admin notes...`})
            HandleError(e, 'Add Note', enqueueSnackbar, app.logout);
        }
    }

    const handleNoteChange = (e) => {
        setNewNote(e.target.value);
    }

    const togglePin = async (id) => {
        setPopup({...popup, loading: true, title: `Pinning note...`})
        let pin = 0;
        for(let x = 0; x < notes.length; x++) {
            if(id == notes[x].ID) {
                pin = notes[x].pinned ? 0 : 1;
                break;
            }
        }

        try {
            const res = await API({
                method: 'PUT', url: `/student/${popup.item.ID}/note/${id}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {
                    pinned: pin
                }
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            setPopup({...popup, loading: false, title: `${popup.item.full_name} #${popup.item.ID}'s Admin Notes`})
            setNotes(res.data.results)
            if(typeof setStudentForm === "function" && studentForm) {
                let newFields = {...studentForm.fields}
                newFields['admin_notes'] = [...res.data.results];
                setStudentForm({...studentForm, fields: newFields})
            }
        }
        catch(e) {
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.full_name}'s admin notes...`})
            HandleError(e, 'Pin Note', enqueueSnackbar, app.logout);
        }
    }
    const deleteNote = async (id) => {
        if(!window.confirm('Are you sure you want to delete this note?'))
            return;

        setPopup({...popup, loading: true, title: `Deleting note...`})
        try {
            const res = await API({
                method: 'DELETE', url: `/student/${popup.item.ID}/note/${id}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.full_name}'s admin notes...`})
        }
        catch(e) {
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.full_name}'s admin notes...`})
            HandleError(e, 'Delete Note', enqueueSnackbar, app.logout);
        }
    }

    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose} dialogActions={<DialogActions>
            <div style={{flex: '1 0 0'}} />
            <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>
                Close
            </Button>
        </DialogActions>}>
            {popup.loading || !popup.item?.ID ? <Box sx={{py: 6, textAlign: 'center'}}><CircularProgress /></Box> :
                <>
                    <Box component="div" sx={{ mt: 1}}>
                        <FormGroup>
                            <FormControl sx={{ mb: 1, minWidth: 140 }}>
                                <TextField
                                    id="new_note"
                                    label="New Note"
                                    variant="outlined"
                                    value={newNote}
                                    autoFocus={true}
                                    onChange={handleNoteChange}
                                    multiline
                                    rows={2} />
                            </FormControl>
                        </FormGroup>
                        <Button onClick={handleSubmit}>Save</Button>
                    </Box>
                    <Box component="div" sx={{ mt: 2 }}>
                        <Divider component="div" />
                        <List className="admin-notes-list" sx={{ width: '100%',
                            maxHeight: 300,
                            overflow: 'auto',
                            bgcolor: 'background.paper' }}>
                            {notes.map((note, idx) => {
                                return <React.Fragment key={idx}>
                                {idx > 0 && <Divider variant="inset" component="li" />}
                                    <ListItem key={idx} alignItems="flex-start" className={note.pinned ? 'pinned' : ''}>
                                        <Tooltip title={`${note.user.full_name} #${note.user.ID}`}>
                                            <ListItemAvatar>
                                                    <AvatarLetters name={note.user.full_name} />
                                            </ListItemAvatar>
                                        </Tooltip>
                                        <ListItemText
                                            primary={note.text}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {note.user.full_name}
                                                    </Typography>
                                                    {' at ' + fdatetime(note.date)}
                                                </React.Fragment>
                                            }
                                        />
                                        <div className="admin-note-actions">
                                            {app.can('student', 'pin_notes') && <IconButton size="small" onClick={() => togglePin(note.ID)} color="warning" component="span">
                                                {note.pinned ? <PushPin fontSize="inherit" /> : <PushPinOutlined fontSize="inherit" />}
                                            </IconButton>}
                                            {app.user.ID === note.user.ID && <IconButton size="small" onClick={() => deleteNote(note.ID)} color="error" component="span">
                                                <DeleteOutlined fontSize="inherit" />
                                            </IconButton>}
                                        </div>
                                    </ListItem>
                                </React.Fragment>
                            })}
                            {notes.length <= 0 && <Typography variant="body2" color="gray">No notes added yet.</Typography>}
                        </List>
                    </Box>
                    {children}
                </>}
        </Popup>
    )
}