import moment from "moment-timezone";
import {fake_dates} from "./Vars";
import {addDays, addMonths, addWeeks, endOfMonth, endOfWeek, startOfMonth, startOfWeek} from "date-fns";
import API, {HandleError} from "./API";

export function fill_table_from_url(ref) {
    const url_params = parse_url_params(window.location.href);
    const allowed = ['order', 'order_by', 'page', 'per_page']
    const numbers = ['page', 'per_page']
    for(let p in url_params) {
        if(allowed.indexOf(p) === -1)
            continue;

        if(numbers.indexOf(p) !== -1) {
            if (isFinite(parseInt(url_params[p])))
                ref[p] = parseInt(url_params[p])
        }
        else
            ref[p] = decodeURIComponent(url_params[p])
    }
    return ref;
}
export function fill_filters_from_url(ref, numbers) {
    const url_params = parse_url_params(window.location.href);
    if(Array.isArray(numbers))
        numbers = [];

    for(let p in url_params) {
        if(!p)
            continue;

        if(Object.keys(ref).indexOf(p) === -1) {
            continue;
        }

        if(numbers.indexOf(p) !== -1) {
            if (isFinite(parseInt(url_params[p])))
                ref[p] = parseInt(url_params[p])
        }
        else if(p === 'search_by') {
            let val = decodeURIComponent(url_params[p]);
            if(ref.search_by_options.indexOf(val) !== -1)
                ref[p] = val;
        }
        else
            ref[p] = url_params[p] instanceof Array ? url_params[p] : decodeURIComponent(url_params[p])
    }
    return ref;
}
export function make_url(url, params, overwrite) {
    if(url === '')
        url = process.env.PUBLIC_URL;
    else if(url.substr(0, 1) === '/') {
        url = process.env.PUBLIC_URL + url;
    }

    var existing = parse_url_params(url);
    url = strip_url_params(url);
    var query = '';

    if( ! (params instanceof Object))
        params = {};

    for(let k in params) {
        if(params.hasOwnProperty(k) && existing.hasOwnProperty(k)) {
            if(overwrite)
                delete existing[k];
            else
                delete params[k];
        }
    }

    params = {...params, ...existing};
    for(let k in params) {
        if(query !== '')
            query += '&';

        if(params.hasOwnProperty(k) && params[k] instanceof Array) {
            for(let i = 0; i < params[k].length; i++) {
                query += k + '[]=' + encodeURIComponent(params[k][i]);

                if(i < params[k].length - 1)
                    query += '&';
            }
        }
        else if(params.hasOwnProperty(k)) {
            query += k + '=' + encodeURIComponent(params[k]);
        }
    }

    url = strip_last_characters(url, '/');
    if(query !== '') {
        url += url.indexOf('?') >= 0 ? '&' : '?';
        url += query;
    }

    return url;
}
export function strip_url_params(url) {
    url = url.split('?');

    return url[0];
}
export function remove_url_param(url, param) {
    var rtn = url.split("?")[0],
        p,
        params_arr = [],
        queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            p = params_arr[i].split("=")[0];
            if (p === param) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}
export function add_url_param(url, param, value) {
    param = encodeURIComponent(param);
    value = encodeURIComponent(value);
    var params = parse_url_params(url);
    params[param] = value;
    url = strip_url_params(url);

    var query = [];
    for(var k in params) {
        if(params.hasOwnProperty(k))
            query.push(k + '=' + params[k]);
    }

    query = query.join('&');
    return url + '?' + query;
}
export function parse_url_params(url) {
    var params = {};
    var query = url.split('?');
    if(query.length < 2)
        return params;

    delete query[0];
    query = query.join('?');

    if(query === '')
        return params;

    query = query.substr(1);

    if(query === '')
        return params;

    var tmp  = query.split('&');
    const arrayRegex = /([^\[]*)/;
    for(var x = 0; x < tmp.length; x++) {
        var q = tmp[x];
        var tmp2 = q.split('=');
        var key = tmp2[0];
        var value = q.substr(key.length + 1, q.length - key.length + 1);
        value = decodeURIComponent(value);
        let m;
        let newKey = key; //'';
        if ((m = arrayRegex.exec(key)) !== null) {
            newKey = m[0]
        }

        if(params.hasOwnProperty(newKey)) {
            if( ! (params[newKey] instanceof Array)) {
                params[newKey] = [params[newKey]];
            }
            params[newKey].push(value);
        }
        else {
            if(newKey !== key)
                params[newKey] = [value];
            else
                params[newKey] = value;
        }
    }
    return params;
}

export function url_params_match(url, second_url) {
    if(second_url === '')
        second_url = window.location.href;

    var p1 = parse_url_params(url);
    var p2 = parse_url_params(second_url);

    for(var k in p1) {
        if(p1.hasOwnProperty(k) && (!p2.hasOwnProperty(k) || p2[k] !== p1[k]))
            return false;
    }
    return true;
}

export function strip_first_characters(str, characters) {
    while(str.startsWith(characters))
        str = str.substr(1);

    return str;
}
export function strip_last_characters(str, characters) {
    while(str.endsWith(characters))
        str = str.substr(0, str.length - 1);

    return str;
}

export function mysql_date(date) {
    const d = moment(date);
    if(!d.isValid())
        return null;

    return d.format('YYYY-MM-DD');
}
export function mysql_time(time) {
    const d = moment(time);
    if(!d.isValid())
        return null;

    return d.format('HH:mm');
}
export function fdate(date) {
    if(fake_dates.hasOwnProperty(date))
        return fake_dates[date];

    return moment(date).tz('Europe/Amsterdam').format('D-M-Y');
}
export function fdatetime(date) {
    return moment(date).tz('Europe/Amsterdam').format('D-M-Y hh:mm:ss');
}
export function ftime(date) {
    return moment(date).tz('Europe/Amsterdam').format('hh:mm:ss');
}

export function price(amount) {
    return parseFloat(amount).toFixed(2);
}
//
// export function stringToColor(string) {
//     if(!string)
//         return '#777777';
//
//     let hash = 0;
//     let i;
//
//     for (i = 0; i < string.length; i += 1) {
//         hash = string.charCodeAt(i) + ((hash << 5) - hash);
//     }
//
//     let color = '#';
//
//     for (i = 0; i < 3; i += 1) {
//         const value = (hash >> (i * 8)) & 0xff;
//         color += `00${value.toString(16)}`.substr(-2);
//     }
//
//     return color;
// }

function hashCode(str) {
    let hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

export const stringToColor = (str, saturation, lightness) => {
    if(!str)
        return 'hsl(0, 0%, 49%)'; //'#7d7d7d';

    saturation = saturation ?? 100;
    lightness = lightness ?? 50;
    return `hsl(${hashCode(str) % 360}, ${saturation}%, ${lightness}%)`;
}

export const escHtml = (text) => {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

window.lockingTimer = null;
export const lockItem = (token, scope, id, onSuccess) => {
    if(window.isUserActive) {
        clearInterval(window.lockingTimer);
        window.lockingTimer = setInterval(async () => {
            if(!window.isUserActive) {
                return;
            }
            try {
                const res = await API({
                    method: 'POST', url: `/${scope}/lock/${id}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data: {}
                })

                if (res.status < 200 || res.status > 299)
                    throw res.data;

                if(typeof onSuccess === "function")
                    onSuccess(res.data);
            }
            catch(e) {
                HandleError(e, 'Lock Item');
            }
        }, 10000);
    }
}
export const unlockItem = async (token, scope, id) => {
    clearInterval(window.lockingTimer);
    window.lockingTimer = null;
    try {
        const res = await API({
            method: 'DELETE', url: `/${scope}/lock/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: {}
        })

        if (res.status < 200 || res.status > 299)
            throw res.data;
    }
    catch(e) {
        console.error(e);
    }
}

const customCourseDateRanges = (date) => [
    {
        label: 'Future',
        startDate: date,
        endDate: addMonths(date, 36),
    },
    {
        label: 'Next Month',
        startDate: startOfMonth(addMonths(date, 1)),
        endDate: endOfMonth(addMonths(date, 11)),
    },
    {
        label: 'Next Week',
        startDate: startOfWeek(addWeeks(date, 1)),
        endDate: endOfWeek(addWeeks(date, 1)),
    },
    {
        label: 'Tomorrow',
        startDate: addDays(date, 1),
        endDate: addDays(date, 1),
    },
    {
        label: 'Today',
        startDate: date,
        endDate: date,
    },
    {
        label: 'Yesterday',
        startDate: addDays(date, -1),
        endDate: addDays(date, -1),
    },
    {
        label: 'This Week',
        startDate: startOfWeek(date),
        endDate: endOfWeek(date),
    },
    {
        label: 'This Month',
        startDate: startOfMonth(date),
        endDate: endOfMonth(date),
    },
    // {
    //     label: 'Last Month',
    //     startDate: startOfMonth(addMonths(date, -1)),
    //     endDate: endOfMonth(addMonths(date, -1)),
    // },
];

export const courseDateRanges = customCourseDateRanges(new Date());