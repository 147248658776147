import {
    Divider,
    IconButton, Menu,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {
    Check,
    Delete,
    DoneAll,
    Edit,
    FilterList,
    HourglassEmptyOutlined,
    RefreshOutlined,
    Remove
} from "@mui/icons-material";
import * as React from "react";
import {useState} from "react";

export default function TableToolbar({ table, setTable, manager, resetFilters, filters, setFilters, onReloadClick, premadeFilters, onDeleteClick, onBulkClick }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const filtersOpen = Boolean(anchorEl);
    const handleFiltersClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFiltersClose = () => {
        setAnchorEl(null);
    };

    const applyPremadeFilters = (f) => {
        console.log(resetFilters);
        setFilters({...resetFilters, ...f});
        handleFiltersClose();
    }
    return (
        <>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(table.selected.length > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {table.selected.length > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {table.selected.length} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {table.title}
                    </Typography>
                )}

                {table.selected.length > 0 ? (
                    <>
                        <Tooltip title="Bulk Edit">
                            <IconButton onClick={onBulkClick}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        {typeof onDeleteClick === "function" && <>
                        <Divider orientation="vertical" flexItem sx={{mx: 2}} light={true} />
                        <Tooltip title="Delete">
                            <IconButton onClick={onDeleteClick}>
                                <Delete />
                            </IconButton>
                        </Tooltip></>}
                    </>
                ) : (
                    <>
                        <Tooltip title="Reload table">
                            <span>
                                <IconButton disabled={table.loading} onClick={onReloadClick}>
                                    <RefreshOutlined />
                                </IconButton>
                            </span>
                        </Tooltip>
                        {premadeFilters && typeof premadeFilters === 'object' && Object.keys(premadeFilters)?.length > 0 && <>
                            <Tooltip title="Pre-made Filters">
                                <IconButton onClick={handleFiltersClick}>
                                    <FilterList />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="premade-filters-menu"
                                anchorEl={anchorEl}
                                open={filtersOpen}
                                onClose={handleFiltersClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {Object.keys(premadeFilters).map((label, idx) => {
                                    return <MenuItem key={idx}>
                                        <span onClick={() => applyPremadeFilters(premadeFilters[label])}>
                                            {label}
                                        </span>
                                    </MenuItem>
                                })}
                            </Menu>
                            </>}
                    </>
                )}
            </Toolbar>
        </>
    );
};