import * as React from 'react';
import Popup from "./Popup";
import {
    Box,
    Button, Card, CardActions, CardContent, Checkbox,
    CircularProgress,
    DialogActions, FormControlLabel, FormGroup,
    Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Switch,
    TextField,
    Typography
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import { red } from '@mui/material/colors';
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import AvatarLetters from "./AvatarLetters";
import moment from "moment";
import {CheckCircleOutline, WarningAmberOutlined} from "@mui/icons-material";
import QRCode from "react-qr-code";
import {fdatetime, lockItem, unlockItem} from "../Common";
import {Alert} from "@mui/lab";

export default function UserPopup({ popup, setPopup, table, setTable, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);

    const [form, setForm] = useState({
        errorFields: {},
        error: false,
        action: '',
        beginLock: false,
        fields: {
            ID: 0,
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            roles: [],
            suspended: 0,
        },
        editable_roles: [],
        item: {}
    });

    const [tfaSecret, setTfaSecret] = useState('');

    useEffect(() => {
        if(popup.item?.ID && popup.reload)
            onLoad();

    }, [popup.item, popup.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(form.beginLock) {
            lockItem(app.token, 'user', form.item.ID, (data) => {
                if(form.item.lock_user?.ID !== app.user.ID) {
                    let newItem = {...form.item}
                    newItem['lock_user'] = {'ID': app.user.ID, 'full_name': app.user.full_name}
                    setForm({...form, item: newItem})
                }
            })
        }
        else {
            clearInterval(window.lockingTimer);
        }
    }, [form.beginLock])


    const isLocked = () => {
        return form.item?.lock_user?.ID > 0 && form.item.lock_user.ID !== app.user.ID;
    }

    const resetForm = () => {
        let newForm = {...form}
        newForm.action = '';
        newForm.editable_roles = [];
        newForm.fields = {
            ID: 0,
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            roles: [],
            suspended: 0,
            twofactor: 0
        }
        setForm(newForm);
    }
    const onLoad = async () => {
        setPopup({...popup, loading: true, reload: false })
        try {
            const res = await API({
                method: 'GET', url: `/user/${popup.item.ID}?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const item = res.data.result;

            let roles = []
            for(let k in item.roles)
                roles[k] = item.roles[k].ID;

            let editable_roles = [];
            if(item.ID !== app.user.ID) {
                for (let rid in app.ui.roles) {
                    let role = app.ui.roles[rid];
                    let editable = app.can('user', 'role', role.name);
                    if (editable) {
                        editable_roles.push(role.name);
                    }
                }
            }

            setPopup({...popup, loading: false, item: item, reload: false, title: `Edit ${item.full_name} #${item.ID}`})
            setForm({...form,
                loading: false,
                action: '',
                fields: {
                    ID: item.ID,
                    email: item.email,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    password: '',
                    roles: roles,
                    suspended: item.suspended,
                    twofactor: item.twofactor
                },
                editable_roles: editable_roles,
                item: {...item},
                beginLock: true
            })
        }
        catch(e) {
            onClose()
            HandleError(e, 'Load User', enqueueSnackbar, app.logout);
        }
    }

    const onClose = () => {
        unlockItem(app.token, 'user', popup.item.ID);
        setForm({...form, beginLock: false})
        setPopup({...popup, open: false, item: null, title: '', loading: false, reload: true });
        setTfaSecret('');
        resetForm();
    }

    const onDelete = () => {
        setForm({...form, action: 'delete'})
        setPopup({...popup, loading: true})
        setTimeout(() => {
            setPopup({...popup, loading: false})
        }, 2000);
    }

    const onSave = async () => {
        setPopup({...popup, loading: true, title: `Saving ${popup.item.full_name} #${popup.item.ID}...`})
        setForm({...form, loading: true, action: 'update'})
        try {


            const form_data = {...prepareFields()}
            const res = await API({
                method: 'PUT', url: `/user/${popup.item.ID}?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: form_data
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            setTfaSecret(res.data.result?.twofactor_secret_url);

            //update visible row in table.
            const newResults = [...table.results]
            for(var i = 0; i < newResults.length; i++) {
                if(newResults[i].ID === res.data.result.ID) {
                    for(var k in res.data.result) {
                        if(newResults[i].hasOwnProperty(k))
                            newResults[i][k] = res.data.result[k];
                    }
                }
            }
            setTable({...table, results: newResults})
            setForm({...form, loading: false, action: ''})
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.full_name} #${popup.item.ID}...`})
        }
        catch(e) {
            setForm({...form, loading: false, action: ''})
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.full_name} #${popup.item.ID}...`})
            HandleError(e, 'Save User', enqueueSnackbar, app.logout);
        }
    }

    const handleInput = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.value;
        setForm(newForm);
    }
    const handleSwitch = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.checked ? 1 : 0;
        setForm(newForm);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleRoleCheckbox = (id, name) => {
        let newForm = {...form}
        if(newForm.fields.roles.hasOwnProperty(name)) {
            delete newForm.fields.roles[name];
        }
        else {
            newForm.fields.roles[name] = id;
        }
        console.log(newForm.fields.roles);
        setForm(newForm);
    }

    const prepareFields = () => {
        const fields = {...form.fields};
        fields.roles = Object.values(fields.roles);
        for(var k in fields) {
            if(!canEdit(k))
                delete fields[k]
        }
        return fields;
    }

    const canEdit = (field) => {
        const profile = form.fields.ID === app.user.ID;
        if(profile && field === 'suspended')
            return false;

        if(!profile && field === 'twofactor' && !form.fields.twofactor)
            return false;

        return app.can(profile ? 'profile' : 'user', 'fields', [field]);
    }

    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose}>
            {popup.loading || form.loading ? <CircularProgress /> :
            <>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Typography component="h6" color="red">
                        {form.error}
                    </Typography>
                    {isLocked() && <Alert severity="warning" sx={{mb: 1}}>
                        Currently locked by {form.item.lock_user.full_name}. Update is disabled.
                    </Alert>}
                    <Grid container component="main" columnSpacing={6}>
                        <Grid item xs={12} sm={12} md={2} align="center" pt={2}>
                            <AvatarLetters name={form.fields} size={56} />
                            <div style={{marginTop: '30px'}}>
                                <small>Date Created</small><br />
                                <small>{form.fields?.ID ? fdatetime(form.fields.createdAt) : ''}</small>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container component="main" columnSpacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        error={form.errorFields.hasOwnProperty('first_name')}
                                        id="first_name"
                                        label="First Name"
                                        name="first_name"
                                        autoComplete="off"
                                        value={form.fields?.first_name}
                                        autoFocus
                                        onChange={handleInput}
                                        disabled={popup.loading}
                                        helperText={form.errorFields?.first_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        error={form.errorFields.hasOwnProperty('last_name')}
                                        id="last_name"
                                        label="Last Name"
                                        name="last_name"
                                        autoComplete="off"
                                        value={form.fields?.last_name}
                                        onChange={handleInput}
                                        disabled={popup.loading}
                                        helperText={form.errorFields?.last_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        error={form.errorFields.hasOwnProperty('email')}
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        autoComplete="off"
                                        value={form.fields?.email}
                                        onChange={handleInput}
                                        disabled={popup.loading}
                                        helperText={form.errorFields?.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <FormGroup sx={{mt: 3}}>
                                        <FormControlLabel
                                            label="Suspend"
                                            labelPlacement="end"
                                            control={
                                                <Switch disabled={!canEdit('suspended')} checked={form.fields.suspended ? true : false}  name="suspended" color="error" onChange={handleSwitch}/>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        error={form.errorFields.hasOwnProperty('password')}
                                        id="password"
                                        label="New Password"
                                        name="password"
                                        type="password"
                                        autoComplete="off"
                                        value={form.fields?.password}
                                        onChange={handleInput}
                                        disabled={popup.loading}
                                        helperText={form.errorFields?.password}
                                    />
                                </Grid>
                            </Grid>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Two-Factor Authentication
                                    </Typography>
                                    <Typography variant="h5" component="div" color={popup.item?.twofactor ? 'primary' : 'error'}>
                                        {popup.item?.twofactor ? <CheckCircleOutline sx={{top: 3, position: 'relative'}} /> : <WarningAmberOutlined sx={{top: 3, position: 'relative'}} />}
                                        {popup.item?.twofactor ? ' 2FA Enabled' : ' 2FA Disabled'}
                                    </Typography>
                                    {tfaSecret &&
                                    <Paper variant="outlined" square align="center">
                                        <Typography sx={{ mt: 1.5 }} color="text.secondary">
                                            Scan this QR code using your Authenticator App.
                                        </Typography>
                                        <Typography sx={{ mt: 1.5, fontSize: 12 }} component="small" color="warning.main">
                                            QR Code will disappear once you close this window!
                                        </Typography>
                                        <Box sx={{my: 2}}>
                                            <QRCode value={tfaSecret} size={128} />
                                        </Box>
                                    </Paper>}
                                </CardContent>
                                <CardActions>
                                    <FormGroup sx={{mt: 1}}>
                                        <FormControlLabel
                                            label="Enable 2FA Authentication"
                                            labelPlacement="end"
                                            control={
                                                <Switch disabled={!canEdit('twofactor')} checked={form.fields.twofactor ? true : false}  name="twofactor" onChange={handleSwitch}/>
                                            }
                                        />
                                    </FormGroup>
                                </CardActions>
                            </Card>
                        </Grid>
                        {<Grid item xs={12} sm={12} md={4}>
                            <List sx={{ width: '100%', bgcolor: (theme) => theme.palette.action.hover, float: 'right' }}
                                  subheader={<ListSubheader component="div" sx={{ bgcolor: 'transparent'}}>
                                      Roles
                                  </ListSubheader>}>
                                {app.ui.roles.map((role, idx) => {
                                    let visible = form.fields.ID !== app.user.ID && form.editable_roles.indexOf(role.name) !== -1 && role.level <= app.user.level;
                                    return (
                                        <ListItem key={idx} disablePadding>
                                            <ListItemButton role={undefined} disabled={popup.loading || !visible} onClick={() => handleRoleCheckbox(role.ID, role.name)} dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={form.fields.roles.hasOwnProperty(role.name)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        disabled={popup.loading || !visible}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={role.nicename} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Grid>}
                    </Grid>
                </Box>
                {children}
                <DialogActions>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        {form.fields.ID !== app.user.ID && !isLocked() && app.can('user', 'delete', Object.keys(form.fields.roles)) &&
                        <Button onClick={onDelete} disabled={popup.loading} color="error">Delete</Button>}
                        {popup.loading && form.action === 'delete' && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: red[400],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                    <div style={{flex: '1 0 0'}} />
                    <Box sx={{ m: 1, position: 'relative' }}>
                        {!isLocked() && app.can(popup.item?.ID === app.user.ID ? 'profile' : 'user', 'update', Object.keys(form.fields.roles))
                        && <Button onClick={onSave} disabled={popup.loading}>Save</Button>}
                        {popup.loading && form.action === 'update' && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'primary',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                    <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
                </DialogActions>
            </>}
        </Popup>
    )
}