import {
    Box, Checkbox,
    Divider,
    FormControl,
    FormGroup, IconButton, InputAdornment,
    InputLabel, ListItemText,
    MenuItem, OutlinedInput,
    Select,
    Stack
} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import {Clear} from "@mui/icons-material";
import AppContext from "../AppContext";


var searchTimer;
export default function FiltersEvent({ table, setTable, filters, setFilters }) {
    const app = useContext(AppContext);

    const [dpOpen, setDpOpen] = useState(false);
    const [search, setSearch] = useState(filters.search);
    const mountedRef = useRef();
    const setRange = (range) => {
        const start = moment(range.startDate);
        const end = moment(range.endDate);
        setFilters({...filters, createdAt: start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD')})
    }
    const openDp = () => setDpOpen(true);
    const toggleDp = () => setDpOpen(!dpOpen);

    useEffect(() => {
        if(mountedRef.current) {
            clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                setFilters({...filters, search: search })
            }, 500);
        }
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mountedRef.current = true;
    }, [])

    const searchBy = {
        'description': 'Description',
        'object_id': 'Object ID',
        'commit_id': 'Commit ID',
    }
    const handleEvent = (e) => {
        const {
            target: { value },
        } = e;
        let v = typeof value === 'string' ? value.split(',') : value;
        console.log('event value', v);
        setFilters({
            ...filters,
            event: v
        });
    }
    const handleUser = (e) => {
        let s = e.target.value;
        if(typeof s === "number")
            s = parseInt(e.target.value);
        setFilters({...filters, user_id: s })
    }
    const handleSearch = (e) => {
        setSearch(e.target.value);
    }
    const handleSearchBy = (e) => {
        setFilters({...filters, search_by: e.target.value })
    }
    const clearFilter = (f) => {
        const newFilters = {...filters}
        newFilters[f] = '';
        setFilters(newFilters);
    }
    console.log(filters, 'events!');
    return <Box sx={{ width: '100%', p: 2, borderBottom: '1px solid #bbb' }}>
        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 140 }}>
                    <InputLabel id="filter-event-label">Event</InputLabel>
                    <Select
                        labelId="filter-event-label"
                        id="filter-event"
                        value={filters.event}
                        onChange={handleEvent}
                        multiple
                        renderValue={(selected) => {
                            return selected.map((v, i) => app.ui.event_names[v]).join(', ')
                        }
                        }
                        input={<OutlinedInput label="Event" />}
                        readOnly={table.loading}
                    >
                        {Object.keys(app.ui.event_names).map((item, idx) => {
                            return <MenuItem key={idx} value={item}>
                                <Checkbox checked={filters.event.indexOf(item) > -1} />
                                <ListItemText primary={app.ui.event_names[item]} />
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel htmlFor="filter-user_id">User</InputLabel>
                    <Select
                        id="filter-user_id"
                        value={filters.user_id}
                        onChange={handleUser}
                        label="User"
                        readOnly={table.loading}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {Object.keys(app.ui.users).map((user_id, idx) => {
                            return <MenuItem key={idx} value={user_id}>{app.ui.users[user_id] + ' #' + user_id}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>
            <div className="dateRangePicker-wrapper">
                <FormControl sx={{ m: 1, minWidth: 260 }}>
                    <InputLabel htmlFor="filter-createdAt">Created At</InputLabel>
                    <OutlinedInput id="filter-createdAt" readOnly={table.loading} onFocus={openDp} type="text" value={filters.createdAt} label="Created At"
                       endAdornment={
                           <InputAdornment position="end">
                               <IconButton
                                   onClick={() => clearFilter('createdAt')}
                                   edge="end"
                               >
                                   {filters.createdAt ? <Clear /> : null}
                               </IconButton>
                           </InputAdornment>
                       }
                    />
                </FormControl>
                <DateRangePicker
                    open={dpOpen}
                    toggle={toggleDp}
                    onChange={setRange}
                />
            </div>

            <div style={{marginLeft: 'auto', marginRight: 0 }}>
                <Stack direction="row" spacing={2}>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <InputLabel htmlFor="filter-search">Search</InputLabel>
                            <OutlinedInput type="search" id="filter-search" readOnly={table.loading} label="Search" onChange={handleSearch} value={search}  />
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <InputLabel htmlFor="filter-search_by">Search by</InputLabel>
                            <Select
                                id="filter-search_by"
                                value={filters.search_by}
                                onChange={handleSearchBy}
                                label="Search by"
                                readOnly={table.loading}
                            >
                                <MenuItem value="">
                                    <em>Anything</em>
                                </MenuItem>
                                {Object.keys(searchBy).map((item, idx) => {
                                    return <MenuItem key={idx} value={item}>{searchBy[item]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Stack>
            </div>
        </Stack>
    </Box>
}