import * as React from 'react';
import Popup from "./Popup";
import {
    TableContainer,
    Table,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Divider,
    Grid, Paper,
    Stack,
    Typography, TableBody, TableRow, TableCell, Link, Tooltip, Chip, ListItem, ListItemButton, ListItemText, List
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {deepOrange, red} from '@mui/material/colors';
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {fdatetime, make_url, price} from "../Common";
import {styled} from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";
import ReactJson from "react-json-view";
import moment from "moment";

export default function CalendarEventPopup({ popup, setPopup, table, setTable, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);
    const [students, setStudents] = useState([])


    useEffect(() => {
        console.log(popup);
        if(popup?.item?.ID && popup.reload)
            onLoad();

    }, [popup.item, popup.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    const onClose = () => {
        setPopup({...popup, open: false, item: null, title: '', loading: false, reload: false });
        setStudents([]);
    }

    const onLoad = async () => {
        if(!popup?.item?.ID) {
            //setStudents([])
            //return
        }

        setPopup({...popup, loading: true, reload: false })

        try {
            const res = await API({
                method: 'GET', url: `/calendar/${popup?.item?.ID}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;


            setPopup({...popup, loading: false, reload: false})

            setStudents([...res.data.results])
        }
        catch(e) {
            onClose()
            HandleError(e, 'Load Calendar Event', enqueueSnackbar, app.logout);
        }
    }

    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose}>
            <Box component="div" sx={{ mt: 1 }}>
                <Grid container component="main" columnSpacing={4} justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={7}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 100 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" colSpan="2" scope="row">COURSE DETAILS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Product</TableCell>
                                        <TableCell><code>{popup?.item?.product}</code></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Date</TableCell>
                                        <TableCell>{moment(popup?.item?.start).format('YYYY-MM-DD')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Location</TableCell>
                                        <TableCell>{popup?.item?.location}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Address</TableCell>
                                        <TableCell>{popup?.item?.desc}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <Typography className="chipSharpCornersParent" variant="overline" display="block" >
                            Sign ups &nbsp;
                            <Tooltip title="Signed up students">
                                <Chip label={popup.loading ? '...' : students.length} size="small" color="success" />
                            </Tooltip>
                        </Typography>
                        <List sx={{ width: '100%', p: 0}}>
                            {popup.loading ? <ListItem disablePadding>
                                <ListItemButton dense>
                                    <ListItemText primary={'Loading...'} />
                                </ListItemButton>
                            </ListItem> : <></>}
                            {students.map((student, y) => {
                                return <ListItem
                                    key={y}
                                    disablePadding
                                >
                                        <ListItemButton dense component={Link} target="_blank"
                                                        href={make_url('/students', { search: student.ID, open: student.ID })}>
                                            <ListItemText primary={`${y + 1}. ${student.name}`} />
                                        </ListItemButton>
                                </ListItem>
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Box>
            {children}
            <DialogActions>
                <div style={{flex: '1 1 1'}} />
                <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
            </DialogActions>
        </Popup>
    )
}