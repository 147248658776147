import {
    Box,
    Divider,
    FormControl,
    FormGroup, IconButton, InputAdornment,
    InputLabel,
    MenuItem, OutlinedInput,
    Select,
    Stack
} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import {Clear} from "@mui/icons-material";
import AppContext from "../AppContext";


var searchTimer;
export default function FiltersUser({ table, setTable, filters, setFilters }) {
    const app = useContext(AppContext);

    const [dpOpen, setDpOpen] = useState(false);
    const [search, setSearch] = useState(filters.search);
    const mountedRef = useRef();
    const setRange = (range) => {
        const start = moment(range.startDate);
        const end = moment(range.endDate);
        setFilters({...filters, createdAt: start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD')})
    }
    const openDp = () => setDpOpen(true);
    const toggleDp = () => setDpOpen(!dpOpen);

    useEffect(() => {
        if(mountedRef.current) {
            clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                setFilters({...filters, search: search })
            }, 500);
        }
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mountedRef.current = true;
    }, [])

    const roles = {...app.ui.roles}

    const suspended = {
        0: 'No',
        1: 'Yes'
    }

    const searchBy = {
        'full_name': 'Name',
        'email': 'E-Mail'
    }
    const handleSuspended = (e) => {
        let s = e.target.value;
        if(typeof s === "number")
            s = parseInt(e.target.value);
        setFilters({...filters, suspended: s })
    }
    const handleSearch = (e) => {
        setSearch(e.target.value);
    }
    const handleSearchBy = (e) => {
        setFilters({...filters, search_by: e.target.value })
    }
    const clearFilter = (f) => {
        const newFilters = {...filters}
        newFilters[f] = '';
        setFilters(newFilters);
    }
    const handleRole = (e) => {
        let role = parseInt(e.target.value);
        if(!isFinite(role))
            role = '';
        setFilters({...filters, role_in: role })
    }
    return <Box sx={{ width: '100%', p: 2, borderBottom: '1px solid #bbb' }}>
        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 140 }}>
                    <InputLabel htmlFor="filter-suspended">Suspended</InputLabel>
                    <Select
                        id="filter-suspended"
                        value={filters.suspended}
                        onChange={handleSuspended}
                        label="Suspended"
                        readOnly={table.loading}
                    >
                        <MenuItem value="">
                            <em>Any</em>
                        </MenuItem>
                        {Object.keys(suspended).map((item, idx) => {
                            return <MenuItem key={idx} value={item}>{suspended[item]}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel htmlFor="filter-role">Role</InputLabel>
                    <Select
                        id="filter-role"
                        value={filters.role_in}
                        onChange={handleRole}
                        label="Role"
                        readOnly={table.loading}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {Object.values(roles).map((role, idx) => {
                            return <MenuItem key={idx} value={role.ID}>{role.nicename}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>
            <div className="dateRangePicker-wrapper">
                <FormControl sx={{ m: 1, minWidth: 260 }}>
                    <InputLabel htmlFor="filter-createdAt">Created At</InputLabel>
                    <OutlinedInput id="filter-createdAt" readOnly={table.loading} onFocus={openDp} type="text" value={filters.createdAt} label="Created At"
                       endAdornment={
                           <InputAdornment position="end">
                               <IconButton
                                   onClick={() => clearFilter('createdAt')}
                                   edge="end"
                               >
                                   {filters.createdAt ? <Clear /> : null}
                               </IconButton>
                           </InputAdornment>
                       }
                    />
                </FormControl>
                <DateRangePicker
                    open={dpOpen}
                    toggle={toggleDp}
                    onChange={setRange}
                />
            </div>

            <div style={{marginLeft: 'auto', marginRight: 0 }}>
                <Stack direction="row" spacing={2}>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <InputLabel htmlFor="filter-search">Search</InputLabel>
                            <OutlinedInput type="search" id="filter-search" readOnly={table.loading} label="Search" onChange={handleSearch} value={search}  />
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <InputLabel htmlFor="filter-search_by">Search by</InputLabel>
                            <Select
                                id="filter-search_by"
                                value={filters.search_by}
                                onChange={handleSearchBy}
                                label="Search by"
                                readOnly={table.loading}
                            >
                                <MenuItem value="">
                                    <em>Anything</em>
                                </MenuItem>
                                {Object.keys(searchBy).map((item, idx) => {
                                    return <MenuItem key={idx} value={item}>{searchBy[item]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Stack>
            </div>
        </Stack>
    </Box>
}