import * as React from 'react';
import Main from "../components/Main";
import { Box } from "@mui/material";

export default function DemoPage() {
    return <Main pageTitle="DEMO PAGE [WORK IN PROGRESS]">
        <Box sx={{ width: '100%', p: 6 }}>
            This page is work in progress.
        </Box>
    </Main>
}