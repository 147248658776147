import * as React from 'react';
import Main from "../components/Main";
import {make_url} from "../Common";
import {useContext, useState} from "react";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";
import AppContext from "../AppContext";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent, Checkbox,
    Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, TextField,
    Typography
} from "@mui/material";
import AvatarLetters from "../components/AvatarLetters";

export default function UserCreate() {
    const app = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const [form, setForm] = useState({
        errorFields: {},
        error: false,
        action: '',
        fields: {
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            roles: [],
        }
    });

    const editable_roles = [];
    for (let rid in app.ui.roles) {
        let role = app.ui.roles[rid];
        let editable = app.can('user', 'create', role.name);
        if (editable) {
            editable_roles.push(role.name);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const resetForm = () => {
        let newForm = {...form}
        newForm.action = '';
        newForm.fields = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            roles: [],
        }
        setForm(newForm);
    }

    const handleInput = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleRoleCheckbox = (id, name) => {
        let f = {...form.fields}
        if(f.roles.hasOwnProperty(name)) {
            delete f.roles[name];
        }
        else {
            f.roles[name] = id;
        }
        console.log(f.roles);
        setForm({...form, fields: f});
    }

    const onSave = async () => {
        setForm({...form, loading: true, errorFields: {}})
        try {
            const form_data = {...form.fields}
            form_data.roles = Object.values(form_data.roles);
            const res = await API({
                method: 'POST', url: `/user`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: form_data
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            enqueueSnackbar('User created successfully.', { variant: 'success' });
            setTimeout(() => {
                window.location.href = make_url('/users', {
                    order: 'desc',
                    orderby: 'createdAt',
                    open: res.data.result.ID
                })
            }, 1000);
        }
        catch(e) {
            HandleError(e, 'Create User', enqueueSnackbar, app.logout);
            if(e?.response?.data?.error?.errors) {
                const errorFields = {}
                for (let k in e.response.data.error.errors)
                    errorFields[k] = e.response.data.error.errors[k];

                setForm({...form, loading: false, errorFields: errorFields})
            }
            else {
                setForm({...form, loading: false, errorFields: {}})
            }
        }
    }

    return <Main pageTitle="Create User">
        <Box>
            <Card sx={{ maxWidth: 800 }} elevation={0}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        New User
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        You can add new users to the platform.
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Typography component="h6" color="red">
                            {form.error}
                        </Typography>
                        <Grid container component="main" columnSpacing={6}>
                            <Grid item xs={12} sm={12} md={2} align="center" pt={2}>
                                {form.fields.first_name && form.fields.last_name && <AvatarLetters name={`${form.fields.first_name} ${form.fields.last_name}`} size={56} />}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid container component="main" columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            error={form.errorFields.hasOwnProperty('first_name')}
                                            id="first_name"
                                            label="First Name"
                                            name="first_name"
                                            autoComplete="off"
                                            value={form.fields?.first_name}
                                            autoFocus
                                            onChange={handleInput}
                                            readOnly={form.loading}
                                            helperText={form.errorFields?.first_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            error={form.errorFields.hasOwnProperty('last_name')}
                                            id="last_name"
                                            label="Last Name"
                                            name="last_name"
                                            autoComplete="off"
                                            value={form.fields?.last_name}
                                            onChange={handleInput}
                                            readOnly={form.loading}
                                            helperText={form.errorFields?.last_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            error={form.errorFields.hasOwnProperty('email')}
                                            id="email"
                                            label="E-mail"
                                            name="email"
                                            autoComplete="off"
                                            value={form.fields?.email}
                                            onChange={handleInput}
                                            readOnly={form.loading}
                                            helperText={form.errorFields?.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            type="password"
                                            error={form.errorFields.hasOwnProperty('password')}
                                            id="password"
                                            label="Password"
                                            name="password"
                                            autoComplete="off"
                                            value={form.fields?.password}
                                            onChange={handleInput}
                                            readOnly={form.loading}
                                            helperText={form.errorFields?.password}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {<Grid item xs={12} sm={12} md={4}>
                                <List sx={{ width: '100%', bgcolor: '#f3f4f5', float: 'right' }}
                                      subheader={<ListSubheader component="div" sx={{ bgcolor: form.errorFields.hasOwnProperty('roles') ? 'red' : '#f3f4f5'}}>
                                          Roles
                                      </ListSubheader>}>
                                    {app.ui.roles.map((role, idx) => {
                                        let visible = editable_roles.indexOf(role.name) !== -1 && role.level <= app.user.level;
                                        return (
                                            <ListItem key={idx} disablePadding>
                                                <ListItemButton role={undefined} disabled={form.loading || !visible} onClick={() => handleRoleCheckbox(role.ID, role.name)} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={form.fields.roles.hasOwnProperty(role.name)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            disabled={form.loading || !visible}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={role.nicename} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Grid>}
                        </Grid>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button size="small" disabled={form.loading} color="error" onClick={resetForm}>Discard</Button>
                    <Button size="small" disabled={form.loading} style={{ marginLeft: 'auto'}} onClick={onSave}>Create</Button>
                </CardActions>
            </Card>
        </Box>
    </Main>
}