import * as React from 'react';
import Main from "../components/Main";
import {make_url, mysql_date, mysql_time} from "../Common";
import {useContext, useState} from "react";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";
import AppContext from "../AppContext";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl, FormControlLabel,
    FormGroup, FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, Switch, TextField,
    Typography
} from "@mui/material";
import {DesktopDatePicker, DesktopTimePicker} from "@mui/lab";

export default function CourseCreate() {
    const app = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const [form, setForm] = useState({
        errorFields: {},
        error: false,
        action: '',
        fields: {
            product: '',
            date: null,
            location: '',
            address: '',
            exam_date: null,
            start_time: null,
            end_time: null,
            cancelled: 0,
            active: 1,
        }
    });

    const products = {...app.ui.products}
    const locations = [...app.ui.locations]

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const prepareFields = () => {
        const fields = {...form.fields};
        fields.exam_date = mysql_date(fields.exam_date);
        if(fields.start_time)
            fields.start_time = mysql_time(fields.start_time)
        if(fields.end_time)
            fields.end_time = mysql_time(fields.end_time)

        return fields;
    }

    const resetForm = () => {
        let newForm = {...form}
        newForm.action = '';
        newForm.fields = {
            product: '',
            date: null,
            location: '',
            address: '',
            exam_date: null,
            start_time: null,
            end_time: null,
            cancelled: 0,
            active: 1,
        }
        setForm(newForm);
    }

    const handleInput = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleDateChange = (field, newDate) => {
        let newForm = {...form}
        newForm.fields[field] = newDate;
        setForm(newForm);
    }
    const handleSwitch = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.checked ? 1 : 0;
        setForm(newForm);
    }

    const onSave = async () => {
        setForm({...form, loading: true, errorFields: {}})
        try {
            const form_data = {...prepareFields()}
            const res = await API({
                method: 'POST', url: `/course`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: form_data
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            enqueueSnackbar('Course created successfully.', { variant: 'success' });
            setTimeout(() => {
                window.location.href = make_url('/courses', {
                    order: 'desc',
                    orderby: 'createdAt',
                    open: res.data.result.ID
                })
            }, 1000);
        }
        catch(e) {
            HandleError(e, 'Create Course', enqueueSnackbar, app.logout);
            if(e?.response?.data?.error?.errors) {
                const errorFields = {}
                for (let k in e.response.data.error.errors)
                    errorFields[k] = e.response.data.error.errors[k];

                setForm({...form, loading: false, errorFields: errorFields})
            }
            else {
                setForm({...form, loading: false, errorFields: {}})
            }
        }
    }

    return <Main pageTitle="Create Course">
        <Box>
            <Card sx={{ maxWidth: 800 }} elevation={0}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        New Course
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        If you have to, you can manually create new course, but automation script should do that if needed.
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Typography component="h6" color="red">
                            {form.error}
                        </Typography>
                        <Grid container component="main" columnSpacing={1}>
                            <Grid item xs={12} sm={12} md={3}>
                                <FormGroup>
                                    <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty('product')}>
                                        <InputLabel htmlFor="product">Product</InputLabel>
                                        <Select
                                            id="product"
                                            value={form.fields.product}
                                            onChange={handleInput}
                                            label="Product"
                                            autoFocus
                                            name="product"
                                            readOnly={form.loading}
                                        >
                                            {Object.keys(products).map((key, idx) => {
                                                if(key.indexOf('+') !== -1)
                                                    return null;
                                                return <MenuItem key={idx} value={key}>{products[key]}</MenuItem>
                                            })}
                                        </Select>
                                        <FormHelperText>{form.errorFields?.product}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <FormGroup>
                                    <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty('location')}>
                                        <InputLabel htmlFor="location">Location</InputLabel>
                                        <Select
                                            id="location"
                                            value={form.fields.location}
                                            onChange={handleInput}
                                            label="Location"
                                            name="location"
                                            readOnly={form.loading}
                                        >
                                            {locations.map((key, idx) => {
                                                return <MenuItem key={idx} value={key}>{key}</MenuItem>
                                            })}
                                        </Select>
                                        <FormHelperText>{form.errorFields?.location}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container component="main" columnSpacing={1}>
                            <Grid item xs={12} sm={12} md={4}>
                                <FormGroup>
                                    <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty('date')}>
                                        <DesktopDatePicker
                                            label="Course Date"
                                            name="date"
                                            error={form.errorFields.hasOwnProperty('date')}
                                            inputFormat="dd-MM-yyyy"
                                            value={form.fields.date}
                                            onChange={(date) => handleDateChange('date', date)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        <FormHelperText>{form.errorFields?.date}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <FormGroup>
                                    <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty('exam_date')}>
                                        <DesktopDatePicker
                                            label="Exam Date"
                                            name="exam_date"
                                            error={form.errorFields.hasOwnProperty('exam_date')}
                                            inputFormat="dd-MM-yyyy"
                                            value={form.fields.exam_date}
                                            onChange={(date) => handleDateChange('exam_date', date)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        <FormHelperText>{form.errorFields?.exam_date}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormGroup>
                                    <FormControl sx={{ m: 1, mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('start_time')}>
                                        <DesktopTimePicker
                                            reduceAnimations={true}
                                            disableOpenPicker={true}
                                            ampm={false}
                                            ampmInClock={false}
                                            label="Start Time"
                                            name="start_time"
                                            error={form.errorFields.hasOwnProperty('start_time')}
                                            inputFormat="HH:mm"
                                            value={form.fields.start_time}
                                            readOnly={form.loading}
                                            minutesStep={5}
                                            clearable
                                            onChange={(time) => handleDateChange('start_time', time)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        <FormHelperText>{form.errorFields?.start_time}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormGroup>
                                    <FormControl sx={{ m: 1, mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('end_time')}>
                                        <DesktopTimePicker
                                            reduceAnimations={true}
                                            disableOpenPicker={true}
                                            ampm={false}
                                            ampmInClock={false}
                                            label="End Time"
                                            name="end_time"
                                            error={form.errorFields.hasOwnProperty('end_time')}
                                            inputFormat="HH:mm"
                                            value={form.fields.end_time}
                                            readOnly={form.loading}
                                            minutesStep={5}
                                            clearable
                                            onChange={(time) => handleDateChange('end_time', time)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        <FormHelperText>{form.errorFields?.end_time}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <FormGroup>
                                    <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty('address')}>
                                        <TextField
                                            id="address"
                                            value={form.fields.address}
                                            label="Address"
                                            name="address"
                                            onChange={handleInput}
                                            readOnly={form.loading} />
                                        <FormHelperText>{form.errorFields?.address}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container component="main" columnSpacing={2}>

                            <Grid item xs={12} sm={12} md={4}>
                                <FormGroup sx={{m: 2}}>
                                    <FormControlLabel
                                        label="Cancelled"
                                        labelPlacement="end"
                                        control={
                                            <Switch checked={form.fields.cancelled > 0}  name="cancelled" color="error" onChange={handleSwitch}/>
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <FormGroup sx={{m: 2}}>
                                    <FormControlLabel
                                        label="Active"
                                        labelPlacement="end"
                                        control={
                                            <Switch checked={form.fields.active > 0}  name="active" color="primary" onChange={handleSwitch}/>
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button size="small" color="error" onClick={resetForm}>Discard</Button>
                    <Button size="small" style={{ marginLeft: 'auto'}} onClick={onSave}>Create</Button>
                </CardActions>
            </Card>
        </Box>
    </Main>
}