import React, {Fragment, useContext, useState} from 'react';
import '../App.css';
import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore} from "@mui/icons-material";
import Routes from "../Routes";
import {useHistory, useLocation} from "react-router-dom";
import AppContext from "../AppContext";
import {make_url} from "../Common";

export default function Menu({ groupNumber }) {
    const app = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname;
    let initState = {};
    for(let idx = 0; idx < Object.keys(Routes).length; idx++) {
        const path = Object.keys(Routes)[idx];
        const r = Routes[path];
        if(!r?.menuTitle)
            continue;

        let menuKey = "" + r.menuGroup + idx;
        if(path === currentPath) {
            initState[menuKey] = true;
            break;
        }
        if(r?.items) {
            let isFound = false;
            for(let subIdx = 0; subIdx < Object.keys(r.items).length; subIdx++) {
                const subPath = Object.keys(r.items)[subIdx];
                if (subPath === currentPath) {
                    initState[menuKey] = true;
                    isFound = true;
                    break;
                }
            }
            if(isFound)
                break;
        }
    }

    const [menuOpen, setMenuOpen] = useState(initState);

    const openMenu = (menuGroup, idx) => {
        let m = {...menuOpen}
        m["" + menuGroup + idx] = true;
        setMenuOpen(m);
    }
    const closeMenu = (menuGroup, idx) => {
        let m = {...menuOpen}
        m["" + menuGroup + idx] = false;
        setMenuOpen(m);
    }
    const handleMenuClick = (e, path, route, idx, newTab) => {
        e.preventDefault();
        if(route?.items) {
            const isOpen = menuOpen?.["" + route.menuGroup + idx];
            isOpen ? closeMenu(route.menuGroup, idx) : openMenu(route.menuGroup, idx);
            return false;
        }
        if(newTab)
            window.open(make_url(path), '_blank');
        else
            history.push({
                pathname: path
            })
    }
    const handleMenuRightClick = (path, route, idx) => {
        console.log('Open in new tab');
    }
    return <>
        <List>
        {Object.keys(Routes).map((path, idx) => {
            const r = Routes[path];
            if(!r?.menuTitle || r?.menuGroup !== groupNumber)
                return null;

            const k = "" + r.menuGroup + idx;

            const isOpen = menuOpen?.[k];
            const items = r?.items ? r.items : {};
            const can = r?.cap ? app.can(r.cap.scope, r.cap.name) : true;
            if(!can)
                return null;

            return <Fragment key={k}>
                    <ListItem button selected={currentPath === path} onClick={(e) => { handleMenuClick(e, path, r, idx) }} >
                        <ListItemIcon>
                            {r.menuIcon}
                        </ListItemIcon>
                        <ListItemText primary={r.menuTitle} />
                        {Object.keys(items).length ? (isOpen ? <ExpandLess /> : <ExpandMore />) : ''}
                    </ListItem>
                    {(() => {
                        if(!Object.keys(items).length)
                            return null;

                        return <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <List>
                                {Object.keys(items).map((subPath, subIdx) => {
                                    let subR = items[subPath];

                                    let subCan = subR?.cap ? app.can(subR.cap.scope, subR.cap.name) : true;
                                    if(!subCan)
                                        return null;

                                    return <ListItem button selected={currentPath === subPath}
                                                     key={k + subIdx}
                                                     onClick={(e) => { handleMenuClick(e, subPath, subR, subIdx) }}
                                                    onContextMenu={(e) => { handleMenuClick(e, subPath, subR, subIdx, true)}}>
                                        <ListItemIcon>
                                            {subR.menuIcon}
                                        </ListItemIcon>
                                        <ListItemText primary={subR.menuTitle}/>
                                    </ListItem>
                                })}
                            </List>
                        </Collapse>
                    })()}
            </Fragment>
        })}
    </List>
    </>
}