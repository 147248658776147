import {styled} from "@mui/material/styles";
import Header from "./Header";
import {Box} from "@mui/material";
import * as React from "react";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
export default function Main({ pageTitle, children }) {
    return <>
        <Header title={pageTitle ?? 'Title Missing'} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            {children}
        </Box>
    </>
}