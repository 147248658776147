import axios from 'axios';
import {API_URL} from "./Vars";
// Next we make an 'instance' of it
const instance = axios.create({
    baseURL: API_URL,
    timeout: 30000
});

instance.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Content-Type': 'application/json',
    'X-Client-Version': process.env.REACT_APP_VERSION
};
export default instance;

export function HandleError(e, title, enqueueSnackbar, logout) {
    console.error(e);
    const custom = e?.response?.data;
    const status = e?.response?.status ?? e?.status;
    let msg = custom?.error?.message ?? e?.response?.statusText ?? e?.error?.message ?? e.message;

    if(title)
        msg = `${title}: ${msg}`;

    if(typeof custom?.error?.errors === "object")
        msg += "\n" + (Object.values(custom.error.errors).join("\n"));

    if(typeof enqueueSnackbar === 'function')
        enqueueSnackbar(msg, { variant: 'error', style: { whiteSpace: 'pre-line' } });

    if(status === 401) {
        if(typeof logout === 'function') {
            logout();
        }
    }
}

export async function getUser(id, token) {
    try {
        // fetch data from a url endpoint
        const res = await instance({ method: 'GET', url: `/user/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Cache-Control': 'no-cache'
            },
            data: {}
        })
        if(res.status < 200 || res.status > 299)
            throw res.data;

        console.log(res.data);
        return res.data;
    } catch(error) {
        console.error(error);
        return error;
    }
}