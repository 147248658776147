import {
    Chip,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import * as React from "react";
import './ErrorLog.css';

const ErrorLog = ({ log }) => {

    const chipColor = (type) => {
        switch(type) {
            case 'FATAL':
            case 'ERROR':
                return 'error';
            case 'WARN':
                return 'warning';
            default:
                return 'primary';
        }
    }

    return <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 550 }} className="log-table">
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ minWidth: 100 }}>
                            Time
                        </TableCell>
                        <TableCell style={{ minWidth: 100 }}>
                            Type
                        </TableCell>
                        <TableCell style={{ minWidth: 400 }}>
                            Message <small style={{ float: 'right', color: '#777' }}>(Showing last 100 messages)</small>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {log.length > 0 ? [...log].reverse().map((row, y) => {
                        return (
                            <TableRow role="checkbox" tabIndex={-1} key={y}>
                                <TableCell>{row.time}</TableCell>
                                <TableCell>
                                    <Chip label={row.type} color={chipColor(row.type)} variant={row.type === 'FATAL' ? 'standard' : 'outlined'} size="small" />
                                </TableCell>
                                <TableCell><div className="log-text">{row.text}</div></TableCell>
                            </TableRow>
                        );
                    }) : Array.from(Array(5).keys()).map((row, y) => {
                        return <TableRow role="checkbox" tabIndex={-1} key={y}>
                            <TableCell><Typography component="div" variant="body1"><Skeleton /></Typography></TableCell>
                            <TableCell><Typography component="div" variant="body1"><Skeleton /></Typography></TableCell>
                            <TableCell><Typography component="div" variant="body1"><Skeleton /></Typography></TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}
export default ErrorLog;