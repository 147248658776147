import * as React from 'react';
import Popup from "./Popup";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button, Chip,
    CircularProgress,
    DialogActions, Divider,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    Switch,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import { red } from '@mui/material/colors';
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import moment from "moment";
import {ExpandMore, GroupsOutlined, ReceiptLongOutlined} from "@mui/icons-material";
import {fdate, fdatetime, make_url, mysql_date, mysql_time} from "../Common";
import {DesktopDatePicker} from "@mui/lab";
import {DesktopTimePicker} from "@mui/lab";
import {styled} from "@mui/material/styles";

export default function CoursePopup({ popup, setPopup, table, setTable, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);

    const [form, setForm] = useState({
        errorFields: {},
        error: false,
        action: '',
        createdAt: '',
        updatedAt: '',
        sections: [],
        fields: {
            ID: 0,
            product: '',
            date: null,
            exam_date: null,
            start_time: null,
            end_time: null,
            location: '',
            address: '',
            cancelled: 0,
            active: 0,
            exams: {}
        }
    });

    useEffect(() => {
        if(popup.item?.ID && popup.reload)
            onLoad();

    }, [popup.item, popup.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    const resetForm = () => {
        let newForm = {...form}
        newForm.action = '';
        newForm.updatedAt = '';
        newForm.createdAt = '';
        newForm.sections = [];
        newForm.fields = {
            ID: 0,
            product: '',
            date: null,
            exam_date: null,
            start_time: null,
            end_time: null,
            location: '',
            cancelled: 0,
            active: 0,
            exams: {}
        }
        setForm(newForm);
    }
    const sectionVisible = (section) => {
        return form.sections.indexOf(section) !== -1
    }

    const toggleSection = (section) => {
        let sections = [...form.sections];
        const idx = sections.indexOf(section);
        if(idx !== -1) {
            sections.splice(idx, 1);
        }
        else {
            sections.push(section);
        }

        setForm({...form, sections: sections})
        return true;
    }

    const onLoad = async () => {
        setPopup({...popup, loading: true, reload: false })
        try {
            const res = await API({
                method: 'GET', url: `/course/${popup.item.ID}?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const item = res.data.result;

            setPopup({...popup, loading: false, item: item, reload: false, title: `Edit ${item.product} / ${item.location} in ${fdate(item.date)} #${item.ID}`})
            setForm({...form,
                loading: false,
                action: '',
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                fields: {
                    ID: item.ID,
                    product: item.product,
                    location: item.location,
                    address: item.address,
                    date: item.date ? new Date(item.date) : null,
                    exam_date: item.exam_date ? new Date(item.exam_date) : null,
                    start_time: item.start_time ? new Date('1 January 2023, ' + item.start_time) : null,
                    end_time: item.end_time ? new Date('1 January 2023, ' + item.end_time) : null,
                    cancelled: item.cancelled,
                    active: item.active,
                    exams: item.exams
                }})
        }
        catch(e) {
            onClose()
            HandleError(e, 'Load Course', enqueueSnackbar, app.logout);
        }
    }

    const onClose = () => {
        setPopup({...popup, open: false, item: null, title: '', loading: false, reload: true });
        resetForm();
    }

    const onDelete = () => {
        setForm({...form, action: 'delete'})
        setPopup({...popup, loading: true})
        setTimeout(() => {
            setPopup({...popup, loading: false})
        }, 2000);
    }

    const onSave = async () => {
        setPopup({...popup, loading: true, title: `Saving ${popup.item.product} / ${popup.item.location} in ${popup.item.date} #${popup.item.ID}...`})
        setForm({...form, loading: true, action: 'update'})
        try {


            const form_data = {...prepareFields()}
            const res = await API({
                method: 'PUT', url: `/course/${popup.item.ID}?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: form_data
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            //update visible row in table.
            const newResults = [...table.results]
            for(var i = 0; i < newResults.length; i++) {
                if(newResults[i].ID === res.data.result.ID) {
                    for(var k in res.data.result) {
                        if(newResults[i].hasOwnProperty(k))
                            newResults[i][k] = res.data.result[k];
                    }
                }
            }
            setTable({...table, results: newResults})
            setForm({...form, loading: false, action: ''})
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.product} / ${popup.item.location} in ${popup.item.date} #${popup.item.ID}...`})
        }
        catch(e) {
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.product} / ${popup.item.location} in ${popup.item.date} #${popup.item.ID}...`})
            HandleError(e, 'Save Course', enqueueSnackbar, app.logout);
        }
    }

    const handleInput = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.value;
        setForm(newForm);
    }
    const handleSwitch = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.checked ? 1 : 0;
        setForm(newForm);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }
    const handleDateChange = (field, newDate) => {
        let newForm = {...form}
        newForm.fields[field] = newDate;
        setForm(newForm);
    }

    const prepareFields = () => {
        const fields = {...form.fields};
        fields.exam_date = mysql_date(fields.exam_date);
        if(fields.start_time)
            fields.start_time = mysql_time(fields.start_time)
        if(fields.end_time)
            fields.end_time = mysql_time(fields.end_time)
        const remove = ['date', 'exams', 'location', 'product'];
        for(let x = 0; x < remove.length; x++)
            delete fields[remove[x]];

        for(var k in fields) {
            if(!canEdit(k))
                delete fields[k]
        }
        return fields;
    }

    const canEdit = (field) => {
        return app.can('course', 'fields', [field]);
    }

    var reservedExams = 0;
    var availableExams = 0;
    var purchasedExams = 0;
    Object.keys(form.fields.exams).forEach((cbr_location, idx) => {
        let exams = form.fields.exams[cbr_location];
        for(const time in exams) {
            reservedExams += exams[time].reserved;
            availableExams += exams[time].spots;
            purchasedExams += exams[time].purchased;
        }
    });

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const goToStudents = () => {
        if(!popup?.item?.ID)
            return;

        window.open(make_url('/students', {
            product: popup.item.product,
            product_multiple: 1,
            course_location: popup.item.location,
            course_date: `${popup.item.date} - ${popup.item.date}`
        }), "_blank")
    }

    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose} dialogActions={<DialogActions>
            <Box sx={{ m: 1, position: 'relative' }}>
                {app.can('course', 'delete', popup?.item?.product ? [popup.item.product] : []) && <Button onClick={onDelete} disabled={popup.loading} color="error">Delete</Button>}
                {popup.loading && form.action === 'delete' && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: red[400],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
            <div style={{flex: '1 0 0'}} />
            {app.can('student', 'read') && <Button onClick={goToStudents} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>
                <GroupsOutlined /> &nbsp; Students
            </Button>}
            <div style={{flex: '1 0 0'}} />
            <Box sx={{ m: 1, position: 'relative' }}>
                {app.can('course', 'update', popup?.item?.product ? [popup.item.product] : []) && <Button onClick={onSave} disabled={popup.loading}>Save</Button>}
                {popup.loading && form.action === 'update' && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: 'primary',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
            <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
        </DialogActions>}>
            {popup.loading || form.loading ? <CircularProgress /> :
            <>
                <Box component="form" noValidate onSubmit={handleSubmit}>
                    <Typography component="h6" color="red">
                        {form.error}
                    </Typography>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        sx={{mb: 4}}
                    >
                        <Item elevation={0}>Created: {form.fields?.ID ? fdatetime(form?.createdAt) : ''}</Item>
                        <Item elevation={0}>Updated: {form.fields?.ID ? fdatetime(form?.updatedAt) : ''}</Item>
                    </Stack>

                    <Grid container component="main" columnSpacing={2} justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container columnSpacing={6}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <FormGroup>
                                        <FormControl sx={{ mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('product')}>
                                            <TextField
                                                id="product"
                                                value={form.fields.product}
                                                label="Product"
                                                name="product"
                                                readOnly={true}
                                            />
                                            <FormHelperText>{form.errorFields?.product}</FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormGroup>
                                        <FormControl sx={{ mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('location')}>
                                            <TextField
                                                id="location"
                                                value={form.fields.location}
                                                label="Location"
                                                name="location"
                                                readOnly={true} />
                                            <FormHelperText>{form.errorFields?.location}</FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormGroup>
                                        <FormControl sx={{ mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('date')}>
                                            <TextField
                                                id="date"
                                                value={fdate(form.fields.date)}
                                                label="Course Date"
                                                name="date"
                                                readOnly={true} />
                                            <FormHelperText>{form.errorFields?.date}</FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormGroup>
                                        <FormControl sx={{ mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('exam_date')}>
                                            <DesktopDatePicker
                                                label="Exam Date"
                                                name="exam_date"
                                                error={form.errorFields.hasOwnProperty('exam_date')}
                                                inputFormat="dd-MM-yyyy"
                                                value={form.fields.exam_date}
                                                readOnly={form.loading}
                                                clearable
                                                minDate={moment(form.fields.date).add(1, 'day').toDate()}
                                                onChange={(date) => handleDateChange('exam_date', date)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <FormHelperText>{form.errorFields?.exam_date}</FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <FormGroup>
                                        <FormControl sx={{ mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('start_time')}>
                                            <DesktopTimePicker
                                                reduceAnimations={true}
                                                disableOpenPicker={true}
                                                ampm={false}
                                                ampmInClock={false}
                                                label="Start Time"
                                                name="start_time"
                                                error={form.errorFields.hasOwnProperty('start_time')}
                                                inputFormat="HH:mm"
                                                value={form.fields.start_time}
                                                readOnly={form.loading}
                                                minutesStep={5}
                                                clearable
                                                onChange={(time) => handleDateChange('start_time', time)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <FormHelperText>{form.errorFields?.start_time}</FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <FormGroup>
                                        <FormControl sx={{ mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('end_time')}>
                                            <DesktopTimePicker
                                                reduceAnimations={true}
                                                disableOpenPicker={true}
                                                ampm={false}
                                                ampmInClock={false}
                                                label="End Time"
                                                name="end_time"
                                                error={form.errorFields.hasOwnProperty('end_time')}
                                                inputFormat="HH:mm"
                                                value={form.fields.end_time}
                                                readOnly={form.loading}
                                                minutesStep={5}
                                                clearable
                                                onChange={(time) => handleDateChange('end_time', time)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <FormHelperText>{form.errorFields?.end_time}</FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormGroup>
                                        <FormControl sx={{ mb:2, minWidth: 140 }} error={form.errorFields.hasOwnProperty('address')}>
                                            <TextField
                                                id="address"
                                                value={form.fields.address}
                                                label="Address"
                                                name="address"
                                                onChange={handleInput}
                                                readOnly={form.loading} />
                                            <FormHelperText>{form.errorFields?.address}</FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container component="main" columnSpacing={1}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <FormGroup sx={{ml:1}}>
                                        <FormControlLabel
                                            label="Cancelled"
                                            labelPlacement="end"
                                            control={
                                                <Switch readOnly={form.loading} checked={form.fields.cancelled > 0}  name="cancelled" color="error" onChange={handleSwitch}/>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <FormGroup sx={{ml:1}}>
                                        <FormControlLabel
                                            label="Active"
                                            labelPlacement="end"
                                            control={
                                                <Switch readOnly={form.loading} checked={form.fields.active > 0}  name="active" color="primary" onChange={handleSwitch}/>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography className="chipSharpCornersParent" variant="overline" display="block" >
                                Available Exams &nbsp;
                                <Tooltip title="Reserved exams">
                                    <Chip label={reservedExams} size="small" color="success" />
                                </Tooltip> |&nbsp;
                                <Tooltip title="Available purchased exams">
                                    <Chip label={purchasedExams} size="small" color="primary" />
                                </Tooltip> |&nbsp;
                                <Tooltip title="Available spots for purchase on CBR since last sync.">
                                    <Chip label={availableExams} size="small" color="warning" />
                                </Tooltip>
                            </Typography>
                            {Object.keys(form.fields.exams).map((cbr_location, idx) => {
                                let reserved = 0;
                                let spots = 0;
                                let purchased = 0;
                                let exams = form.fields.exams[cbr_location];
                                for(let time in exams) {
                                    reserved += exams[time].reserved;
                                    spots += exams[time].spots;
                                    purchased += exams[time].purchased;
                                }
                                return <Accordion key={idx} expanded={sectionVisible(cbr_location)}
                                                  onChange={() => toggleSection(cbr_location)}
                                                  className="chipSharpCornersParent">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>
                                            {cbr_location}
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', width: '40%', textAlign: 'right' }}>
                                            <Tooltip title="Reserved exams">
                                                <Chip label={reserved} size="small" color="success" />
                                            </Tooltip> |&nbsp;
                                            <Tooltip title="Available purchased exams">
                                                <Chip label={purchased} size="small" color="primary" />
                                            </Tooltip> |&nbsp;
                                            <Tooltip title="Available spots for purchase on CBR since last sync.">
                                                <Chip label={spots} size="small" color="warning" />
                                            </Tooltip>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{p: 0}}>
                                        <List sx={{ width: '100%', p: 0}}>
                                            {Object.keys(exams).map((time, y) => {
                                                return <ListItem
                                                    key={y}
                                                    secondaryAction={
                                                        <Typography sx={{ color: 'text.secondary', textAlign: 'right' }}>
                                                            <Tooltip title="Reserved exams">
                                                                <Chip label={exams[time].reserved} size="small" color="success" />
                                                            </Tooltip> |&nbsp;
                                                            <Tooltip title="Available purchased exams">
                                                                <Chip label={exams[time].purchased} size="small" color="primary" />
                                                            </Tooltip> |&nbsp;
                                                            <Tooltip title="Available spots for purchase on CBR since last sync.">
                                                                <Chip label={exams[time].spots} size="small" color="warning" />
                                                            </Tooltip>
                                                        </Typography>
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton role={undefined} dense>
                                                        <ListItemText primary={time} />
                                                    </ListItemButton>
                                                </ListItem>
                                            })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            })}
                        </Grid>
                    </Grid>
                </Box>
                {children}
            </>}
        </Popup>
    )
}