import {
    Box,
    Divider,
    FormControl,
    FormGroup, IconButton, InputAdornment,
    InputLabel,
    MenuItem, OutlinedInput,
    Select,
    Stack
} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import {Clear} from "@mui/icons-material";
import AppContext from "../AppContext";
import { courseDateRanges } from "../Common";
import FlagsMenu from "./FlagsMenu";


var searchTimer;
export default function FiltersCourse({ table, setTable, filters, setFilters }) {
    const app = useContext(AppContext);

    const [dpOpen, setDpOpen] = useState(false);
    const [search, setSearch] = useState(filters.search);
    const mountedRef = useRef();
    const setRange = (range) => {
        const start = moment(range.startDate);
        const end = moment(range.endDate);
        setFilters({...filters, date_range: start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD')})
    }
    const openDp = () => setDpOpen(true);
    const toggleDp = () => setDpOpen(!dpOpen);

    useEffect(() => {
        if(mountedRef.current) {
            clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                setFilters({...filters, search: search })
            }, 500);
        }
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mountedRef.current = true;
    }, [])

    const products = {...app.ui.products}
    const locations = [...app.ui.locations]

    const searchBy = {
        'date': 'Course Date',
        'exam_date': 'Exam Date',
        'location': 'Location'
    }

    const flags = {
        active: 'Active',
        cancelled: 'Cancelled',
        has_spots: 'Has Spots',
        has_students: 'Has Students'
    }
    const handleProduct = (e) => {
        setFilters({...filters, product: e.target.value })
    }
    const handleLocation = (e) => {
        setFilters({...filters, location: e.target.value })
    }
    const handleSearch = (e) => {
        setSearch(e.target.value);
    }
    const handleSearchBy = (e) => {
        setFilters({...filters, search_by: e.target.value })
    }
    const clearFilter = (f) => {
        const newFilters = {...filters}
        newFilters[f] = '';
        setFilters(newFilters);
    }
    return <Box sx={{ width: '100%', p: 2, borderBottom: '1px solid #bbb' }}>
        <Stack direction="row" spacing={2}>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel htmlFor="filter-product">Product</InputLabel>
                    <Select
                        id="filter-product"
                        value={filters.product}
                        onChange={handleProduct}
                        label="Status"
                        readOnly={table.loading}
                    >
                        <MenuItem value="">
                            <em>Any</em>
                        </MenuItem>
                        {Object.keys(products).map((key, idx) => {
                            return <MenuItem key={idx} value={key}>{products[key]}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel htmlFor="filter-location">Location</InputLabel>
                    <Select
                        id="filter-location"
                        value={filters.location}
                        onChange={handleLocation}
                        label="Location"
                        readOnly={table.loading}
                    >
                        <MenuItem value="">
                            <em>Any</em>
                        </MenuItem>
                        {locations.map((key, idx) => {
                            return <MenuItem key={idx} value={key}>{key}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>
            <div className="dateRangePicker-wrapper">
                <FormControl sx={{ m: 1, minWidth: 260 }}>
                    <InputLabel htmlFor="filter-date_range">Date</InputLabel>
                    <OutlinedInput id="filter-date_range" readOnly={table.loading} onFocus={openDp} type="text" value={filters.date_range} label="Date"
                       endAdornment={
                           <InputAdornment position="end">
                               <IconButton
                                   onClick={() => clearFilter('date_range')}
                                   edge="end"
                               >
                                   {filters.date_range ? <Clear /> : null}
                               </IconButton>
                           </InputAdornment>
                       }
                    />
                </FormControl>
                <DateRangePicker
                    open={dpOpen}
                    toggle={toggleDp}
                    onChange={setRange}
                    definedRanges={courseDateRanges}
                />
            </div>
            <FlagsMenu flags={flags} filters={filters} setFilters={setFilters} table={table} />
            <div style={{marginLeft: 'auto', marginRight: 0 }}>
                <Stack direction="row" spacing={2}>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <InputLabel htmlFor="filter-search">Search</InputLabel>
                            <OutlinedInput type="search" id="filter-search" readOnly={table.loading} label="Search" onChange={handleSearch} value={search}  />
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel htmlFor="filter-search_by">Search by</InputLabel>
                            <Select
                                id="filter-search_by"
                                value={filters.search_by}
                                onChange={handleSearchBy}
                                label="Search by"
                                readOnly={table.loading}
                            >
                                <MenuItem value="">
                                    <em>Anything</em>
                                </MenuItem>
                                {Object.keys(searchBy).map((item, idx) => {
                                    return <MenuItem key={idx} value={item}>{searchBy[item]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Stack>
            </div>
        </Stack>
    </Box>
}