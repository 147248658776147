import * as React from 'react';
import Main from "../components/Main";
import {useContext, useEffect, useState} from "react";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";
import AppContext from "../AppContext";
import {
    Badge,
    Box, Tab, Tabs
} from "@mui/material";
import TabPanel from "../components/TabPanel";
import {styled} from "@mui/material/styles";
import ErrorLog from "../components/ErrorLog";

export default function ErrorsPage() {
    const app = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const [errors, setErrors] = useState({
        log: [],
        count: 0,
        file: null,
        files: [],
        reload: false
    })

    useEffect(() => {
        onLoad()
    }, [])

    useEffect(() => {
        if(errors.reload)
            refreshErrors();
    }, [errors.reload])

    const onLoad = async () => {
        try {
            const res = await API({
                method: 'GET', url: `/ui/errors`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const errData = {...errors, files: res.data.result }
            if(errData.files.length) {
                errData['file'] = 0;
                errData['count'] = 0;
                errData['reload'] = true;
            }
            setErrors({...errData})
        }
        catch(e) {
            HandleError(e, 'Load Errors', enqueueSnackbar, app.logout);
        }
    }

    const refreshErrors = async () => {
        try {
            const res = await API({
                method: 'GET', url: `/ui/errors/${errors.files[errors.file].filename}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            setErrors({...errors, reload: false, log: res.data.result, count: res.data.result.length})
        }
        catch(e) {
            setErrors({...errors, reload: false, count: 0, log: []})
            HandleError(e, 'Load Errors', enqueueSnackbar, app.logout);
        }
    }

    const handleErrorFile = (e, newValue) => {
        setErrors({...errors, reload: true, file: newValue, count: 0, log: [] })
    }

    function a11yProps(index) {
        return {
            id: `log-panel-${index}`,
        };
    }

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            top: -7,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));

    return <Main pageTitle="Error Log">
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={errors.file} onChange={handleErrorFile}>
                    {errors.files.map((file, idx) => {
                        return <Tab key={idx} label={<StyledBadge color="error" max={99} badgeContent={file.count}>{file.filename}</StyledBadge>} {...a11yProps(idx)} />
                    })}
                </Tabs>
            </Box>
            {errors.files.map((file, idx) => {
                return <TabPanel id="log-panel" value={errors.file} index={idx} key={idx}>
                    {errors.file === idx ? <ErrorLog log={errors.log} /> : ''}
                </TabPanel>
            })}
        </Box>
    </Main>
}