export const API_URL = 'https://api.ttruck.ovh';

export const fake_dates = {
    'other': 'Other',
    //'holiday': 'Holiday'
}
export const exam_results = {
    '': 'No result',
    'pass': 'Passed',
    'fail': 'Failed'
}

export const INACTIVITY_TIME = 120;