import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import {
    AddOutlined, Today, CameraIndoorOutlined,
    DashboardOutlined, ErrorOutlineOutlined,
    EventOutlined,
    GroupsOutlined, HomeOutlined, ListOutlined,
    LogoutOutlined, MiscellaneousServicesOutlined,
    MoneyOutlined,
    SchoolOutlined, Settings,
} from "@mui/icons-material";
import Users from "./pages/Users";
import UserCreate from "./pages/UserCreate";
import Students from "./pages/Students";
import Courses from "./pages/Courses";
import CourseCreate from "./pages/CourseCreate";
import Orders from "./pages/Orders";
import AppSettings from "./pages/AppSettings";
import ErrorsPage from "./pages/ErrorsPage";
import DemoPage from "./pages/DemoPage";
import Events from "./pages/Events";
import Exams from "./pages/Exams";
import CalendarPage from "./pages/CalendarPage";

export const Routes = {
    "welcome": {
        component: Dashboard,
        title: 'Welcome',
        menuTitle: 'Welcome',
        menuIcon: <HomeOutlined />,
        menuGroup: 1,
        items: {
            "/": {
                component: Dashboard,
                title: 'Dashboard',
                menuTitle: 'Dashboard',
                menuIcon: <DashboardOutlined />,
            },
            "/settings": {
                component: AppSettings,
                title: 'App Settings',
                menuTitle: 'App Settings',
                menuIcon: <Settings />,
            },
            "/calendar": {
                component: CalendarPage,
                title: 'Calendar',
                menuTitle: 'Calendar',
                menuIcon: <Today />,
            }
        }
    },
    "/login": {
        component: Login,
        title: 'Login',
        public: true,
    },
    "users": {
        component: Users,
        title: 'Users',
        menuTitle: 'Users',
        menuIcon: <GroupsOutlined />,
        menuGroup: 1,
        items: {
            "/users": {
                component: Users,
                menuIcon: <ListOutlined />,
                title: 'Users',
                menuTitle: 'All Users',
            },
            "/user/create": {
                component: UserCreate,
                menuIcon: <AddOutlined />,
                title: 'Create User',
                menuTitle: 'New User',
                cap: { scope: 'user', name: 'create' }
            }
        }
    },
    "orders": {
        component: Orders,
        title: 'Orders',
        menuTitle: 'Orders',
        menuIcon: <MoneyOutlined />,
        menuGroup: 1,
        cap: { scope: 'order', name: 'read' },
        items: {
            "/orders": {
                component: Orders,
                menuIcon: <ListOutlined />,
                title: 'Orders',
                menuTitle: 'All Orders',
                cap: { scope: 'order', name: 'read' },
            }
        }
    },
    "students": {
        component: Students,
        title: 'Students',
        menuTitle: 'Students',
        menuIcon: <SchoolOutlined />,
        menuGroup: 1,
        cap: { scope: 'student', name: 'read' },
        items: {
            "/students": {
                component: Students,
                menuIcon: <ListOutlined />,
                title: 'Students',
                menuTitle: 'All Students',
                cap: { scope: 'student', name: 'read' },
            }
        }
    },
    "courses": {
        component: Courses,
        title: 'Courses',
        menuTitle: 'Courses',
        menuIcon: <EventOutlined />,
        menuGroup: 1,
        cap: { scope: 'course', name: 'read' },
        items: {
            "/courses": {
                component: Courses,
                title: 'Courses',
                menuTitle: 'All Courses',
                menuIcon: <ListOutlined />,
                cap: { scope: 'course', name: 'read' },
            },
            "/courses/create": {
                component: CourseCreate,
                menuIcon: <AddOutlined />,
                title: 'Create Courses',
                menuTitle: 'New Course',
                cap: { scope: 'course', name: 'create' },
            },
            "/exams": {
                component: Exams,
                title: 'Exams',
                menuTitle: 'Exams',
                menuIcon: <ListOutlined />,
                cap: { scope: 'exam', name: 'read' },
            },
        }
    },
    "misc": {
        component: Dashboard,
        title: 'Misc',
        menuTitle: 'Misc',
        menuIcon: <MiscellaneousServicesOutlined />,
        menuGroup: 1,
        items: {
            "/errors": {
                component: ErrorsPage,
                title: 'Error Log',
                menuTitle: 'Error Log',
                menuIcon: <ErrorOutlineOutlined />,
                cap: { scope: 'error_log', name: 'read' },
            },
            "/mail-queue": {
                component: DemoPage,
                title: 'Mail Queue',
                menuTitle: 'Mail Queue',
                cap: { scope: 'mail_queue', name: 'read' },
            },
            "/events": {
                component: Events,
                title: 'Event Log',
                menuTitle: 'Event Log',
                menuIcon: <CameraIndoorOutlined />,
                cap: { scope: 'event', name: 'read' },
            }
        }
    },
    "/logout": {
        component: Logout,
        title: 'Logout',
        public: true,
        menuGroup: 2,
        menuTitle: 'Logout',
        menuIcon: <LogoutOutlined />
    }
}
export default Routes;