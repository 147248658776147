import {
    Box, Checkbox, FormControl,
    FormControlLabel,
    FormGroup, IconButton, InputAdornment,
    InputLabel, MenuItem, OutlinedInput,
    Select,
    Stack,
    Switch
} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import {Clear} from "@mui/icons-material";
import AppContext from "../AppContext";
import {courseDateRanges} from "../Common";
import FlagsMenu from "./FlagsMenu";

var searchTimer;
export default function FiltersStudent({ table, setTable, filters, setFilters }) {
    const app = useContext(AppContext);

    const [dpOpen, setDpOpen] = useState(false);
    const [search, setSearch] = useState(filters.search);
    const mountedRef = useRef();
    const setRange = (range) => {
        const start = moment(range.startDate);
        const end = moment(range.endDate);
        setFilters({...filters, course_date: start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD')})
    }
    const openDp = () => setDpOpen(true);
    const toggleDp = () => setDpOpen(!dpOpen);

    const products = {...app.ui.products};
    const locations = [...app.ui.locations];

    const flags = {
        confirmed: 'Confirmed',
        processed: 'Processed',
        cancelled: 'Cancelled',
        absent: 'Absent',
        result: 'Result',
        is_company: 'Is Company'
    }

    useEffect(() => {
        if(mountedRef.current) {
            clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                setFilters({...filters, search: search })
            }, 500);
        }
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mountedRef.current = true;
    }, [])

    const statuses = {...app.ui.statuses}

    const searchBy = {
        'full_name': 'Name',
        'email': 'E-Mail',
        'cbr_number': 'CBR Number',
        'company': 'Company'
    }
    const handleInput = (e) => {
        const newFilters = {...filters}
        newFilters[e.target.name] = e.target.value;
        setFilters({...newFilters })
    }
    const handleToggleSwitch = (e) => {
        const newFilters = {...filters}
        newFilters[e.target.name] = e.target.checked ? 0 : 1;
        setFilters({...newFilters })
    }
    const handleSearch = (e) => {
        setSearch(e.target.value);
    }
    const handleSearchBy = (e) => {
        setFilters({...filters, search_by: e.target.value })
    }
    const handleFakeSwitch = (e) => {
        setFilters({...filters, course_date: e.target.checked ? 'fake' : ''})
    }
    const clearFilter = (f) => {
        const newFilters = {...filters}
        newFilters[f] = '';
        setFilters(newFilters);
    }
    return <Box sx={{ width: '100%', p: 2, borderBottom: '1px solid #bbb' }}>
        <Stack direction="row" spacing={2} >
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <InputLabel htmlFor="filter-status">Status</InputLabel>
                    <Select
                        id="filter-status"
                        value={filters.status}
                        onChange={handleInput}
                        name="status"
                        label="Status"
                        readOnly={table.loading}
                    >
                        <MenuItem value="">
                            <em>Any</em>
                        </MenuItem>
                        {Object.keys(statuses).map((key, idx) => {
                            return <MenuItem key={idx} value={key}>{statuses[key]}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <InputLabel htmlFor="filter-status">Product</InputLabel>
                    <Select
                        id="filter-product"
                        value={filters.product}
                        onChange={handleInput}
                        name="product"
                        label="Product"
                        readOnly={table.loading}
                    >
                        <MenuItem value="">
                            <em>Any</em>
                        </MenuItem>
                        {Object.keys(products).map((key, idx) => {
                            return <MenuItem key={idx} value={key}>{products[key]}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>

            <FormGroup>
                <FormControlLabel sx={{mt: 2 }}
                                  label="Exact"
                                  labelPlacement="end"
                                  control={
                                      <Checkbox checked={filters.product_multiple == 0}
                                              disabled={table.loading}
                                              name="product_multiple" color="primary" onChange={handleToggleSwitch}/>
                                  }
                />
            </FormGroup>
            <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 140 }}>
                    <InputLabel htmlFor="filter-course_location">Location</InputLabel>
                    <Select
                        id="filter-course_location"
                        value={filters.course_location}
                        onChange={handleInput}
                        name="course_location"
                        label="Location"
                        readOnly={table.loading}
                    >
                        <MenuItem value="">
                            <em>Any</em>
                        </MenuItem>
                        {locations.map((key, idx) => {
                            return <MenuItem key={idx} value={key}>{key}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </FormGroup>
            <div className="dateRangePicker-wrapper">
                <FormControl sx={{ m: 1, minWidth: 240 }}>
                    <InputLabel htmlFor="filter-course_date">Course Date</InputLabel>
                    <OutlinedInput id="filter-course_date" readOnly={table.loading}
                                   disabled={table.loading}
                                   onFocus={openDp} type="text"
                                   value={filters.course_date !== 'fake' ? filters.course_date : ''} label="Course Date"
                       endAdornment={
                           <InputAdornment position="end">
                               <IconButton
                                   onClick={() => clearFilter('course_date')}
                                   edge="end"
                               >
                                   {filters.course_date ? <Clear /> : null}
                               </IconButton>
                           </InputAdornment>
                       }
                    />
                </FormControl>
                <DateRangePicker
                    open={dpOpen}
                    toggle={toggleDp}
                    onChange={setRange}
                    definedRanges={courseDateRanges}
                />
            </div>
            <FormGroup>
                <FormControlLabel sx={{mt: 2 }}
                    label="Fake"
                    labelPlacement="end"
                    control={
                        <Switch checked={filters.course_date === 'fake'}
                                disabled={table.loading}
                                name="course_date" color="primary" onChange={handleFakeSwitch}/>
                    }
                />
            </FormGroup>
            <FlagsMenu flags={flags} filters={filters} setFilters={setFilters} table={table} manager={"students"} />
            <div style={{marginLeft: 'auto', marginRight: 0 }}>
                <Stack direction="row" spacing={2}>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <InputLabel htmlFor="filter-search">Search</InputLabel>
                            <OutlinedInput type="search" id="filter-search" readOnly={table.loading} label="Search" onChange={handleSearch} value={search}  />
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel htmlFor="filter-search_by">Search by</InputLabel>
                            <Select
                                id="filter-search_by"
                                value={filters.search_by}
                                onChange={handleSearchBy}
                                label="Search by"
                                readOnly={table.loading}
                            >
                                <MenuItem value="">
                                    <em>Anything</em>
                                </MenuItem>
                                {Object.keys(searchBy).map((item, idx) => {
                                    return <MenuItem key={idx} value={item}>{searchBy[item]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Stack>
            </div>
        </Stack>
    </Box>
}