import React, {useContext, useEffect, useState} from 'react';
import '../App.css';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Menu from "./Menu";
import {
    Box,
    Divider,
    IconButton,
    List,
    Toolbar, Tooltip,
    Typography
} from "@mui/material";
import {ChevronLeft} from "@mui/icons-material";
import AppContext from "../AppContext";
import AvatarLetters from "./AvatarLetters";
import {make_url} from "../Common";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
export default function Header({ title }) {
    const app = useContext(AppContext);
    useEffect(() => {
        app.storeUI();
    }, [app.ui]);
    return <>
        <AppBar position="fixed" open={app.ui.isDrawerOpen}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={app.openDrawer}
                    edge="start"
                    sx={{
                        marginRight: '36px',
                        ...(app.ui.isDrawerOpen && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    {title ?? ''}
                </Typography>

                {app.ui.new_version != '' && app.ui.new_version !== process.env.REACT_APP_VERSION &&
                <Typography variant="span" component="div" noWrap sx={{ ml: 3 }}>
                    New version {app.ui.new_version} available! Please refresh all tabs.
                </Typography>}
                <Box sx={{ flexGrow: 1}} />
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="My Profile">
                        <a style={{textDecoration: 'none'}} href={make_url('/users', { search: app.user?.ID, open: app.user?.ID })} target="_blank">
                            <AvatarLetters name={app.user.full_name} size={48} />
                        </a>
                    </Tooltip>
                </Box>
            </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={app.ui.isDrawerOpen}>
            <DrawerHeader>
                <IconButton onClick={app.closeDrawer}>
                    <ChevronLeft />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <Menu groupNumber={1} />
            </List>
            <Divider />
            <List>
                <Menu groupNumber={2} />
            </List>
        </Drawer>
    </>
}