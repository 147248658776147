import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AppDataProvider } from "./AppContext";
import {SnackbarProvider} from "notistack";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

ReactDOM.render(
  <React.StrictMode>
      <AppDataProvider>
          <SnackbarProvider maxSnack={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
              </LocalizationProvider>
          </SnackbarProvider>
      </AppDataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);