import {Card, CardActionArea, CardContent, Grid, Typography} from "@mui/material";
import * as React from "react";
import './DashboardWidget.css';

const Widget = ({ onClick, children }) => ( onClick ?
        <CardActionArea style={{ paddingBottom: '8px' }} onClick={onClick}>
            {children}
        </CardActionArea>
        : children
);

const DashboardWidget = ({ xs, sm, md, lg, title, value, icon, children, onClick, cardProps }) => {

    return <Grid item xs={xs ?? 12} sm={sm ?? 12} md={md ?? 6} lg={lg ?? 2}>
        <Card className="dashboard-widget" {...cardProps}>
            <Widget onClick={onClick}>
                <CardContent>
                    {React.isValidElement(icon) ? React.cloneElement(icon, { className: 'widget-icon' }) : null}
                    <Typography sx={{ mb: 1.5 }} component="div" color="text.secondary" gutterBottom>
                        {title}
                    </Typography>
                    {value !== null ? <Typography variant="h5" component="div">
                        {value}
                    </Typography> : null}
                    {children}
                </CardContent>
            </Widget>
        </Card>
    </Grid>
}
export default DashboardWidget;