import * as React from 'react';
import Popup from "./Popup";
import {
    TableContainer,
    Table,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Divider,
    Grid, Paper,
    Stack,
    Typography, TableBody, TableRow, TableCell, Link
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {deepOrange, red} from '@mui/material/colors';
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {fdatetime, make_url, price} from "../Common";
import {styled} from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";
import ReactJson from "react-json-view";

export default function EventPopup({ popup, setPopup, table, setTable, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);

    const initForm = {
        errorFields: {},
        error: false,
        action: '',
        item: {},
    }

    const [form, setForm] = useState({...initForm});

    useEffect(() => {
        if(popup.item?.ID && popup.reload)
            onLoad();

    }, [popup.item, popup.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    const resetForm = () => {
        setForm({...initForm});
    }

    const onLoad = async () => {
        setPopup({...popup, loading: true, reload: false })
        try {
            const res = await API({
                method: 'GET', url: `/event/${popup.item.ID}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const item = res.data.result;

            setPopup({...popup, loading: false, item: item, reload: false, title: `Event ${app.ui.event_names?.[item.event] ?? item.event} #${item.ID}`})

            setForm({...form,
                loading: false,
                action: '',
                item: {...item}
            })
        }
        catch(e) {
            onClose()
            HandleError(e, 'Load Event', enqueueSnackbar, app.logout);
        }
    }
    const onClose = () => {
        setPopup({...popup, open: false, item: null, title: '', loading: false, reload: true });
        resetForm();
    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose}>
            {popup.loading || form.loading || !form.item?.ID ? <Box sx={{py: 6, textAlign: 'center'}}><CircularProgress /></Box> :
                <>
                    <Box component="div" sx={{ mt: 1 }}>
                        <Typography component="h6" color="red">
                            {form.error}
                        </Typography>
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                            sx={{mb: 4}}
                        >
                            <Item elevation={0}>Created: {form.item?.ID ? fdatetime(form.item.createdAt) : ''}</Item>
                        </Stack>
                        <Grid container component="main" columnSpacing={4} justifyContent="space-between">
                            <Grid item xs={12} sm={12} md={7}>
                                <Paper component="div" elevation={2}>
                                    <ReactJson theme={app.ui.darkMode ? 'railscasts' : 'rjv-default'} src={typeof form.item.description !== 'object' ? [form.item.description] : form.item.description}
                                               style={{maxHeight: '400px', overflow: 'auto'}} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 100 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" colSpan="2" scope="row">DETAILS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Commit ID</TableCell>
                                                <TableCell><code>{form.item.commit_id}</code></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Event</TableCell>
                                                <TableCell>{app.ui.event_names?.[form.item.event] ?? form.item.event}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">User ID</TableCell>
                                                <TableCell>{app.ui.users?.[form.item.user_id] ? `${app.ui.users?.[form.item.user_id]} #${form.item.user_id}` : `Unknown User #${form.item.user_id}`}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Object ID</TableCell>
                                                <TableCell>{form.item.object_id}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                    {children}
                    <DialogActions>
                        <div style={{flex: '1 1 1'}} />
                        <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
                    </DialogActions>
                </>}
        </Popup>
    )
}