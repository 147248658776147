import {useContext, useState} from "react";
import AppContext from "../AppContext";
import {Avatar, Box, Button, Grid, Link, Paper, TextField, Typography} from "@mui/material";
import {LockClockOutlined} from "@mui/icons-material";
import API, {getUser} from "../API";
import {Redirect} from "react-router-dom";

export default function Login() {
    const app = useContext(AppContext);

    const [form, setForm] = useState({
        email: '',
        password: '',
        errorFields: {},
        error: false,
        loading: false,
        tfaEnabled: false,
        tfa: ''
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        setForm({...form, loading: true, errorFields: {}, error: false});
        try {
            const resToken = await API({
                method: 'POST',
                url: '/login',
                data: {
                    email: form.email,
                    password: form.password,
                    tfa: form.tfa
                }
            });
            if (resToken.data.status < 200 || resToken.data.status > 299)
                throw resToken.data;

            if(resToken.data.status === 202) {
                //2-Factor authentication
                setForm({...form, tfaEnabled: true, tfa: ''})
                return;
            }

            const ui = resToken.data.ui;

            const resUser = await getUser(resToken.data.ID, resToken.data.token);
            if (resUser.status < 200 || resUser.status > 299)
                throw resUser;

            app.login(resToken.data.token, resUser.result, ui);
        } catch (err) {
            console.error(err);
            let newForm = {error: 'Unknown error.', errorFields: {}, loading: false}
            if (err?.response) {
                const r = err.response.data;
                newForm.error = r.error.message;
                for (let k in r.error.errors)
                    newForm.errorFields[k] = r.error.errors[k];

                if (r.status === 401) {
                    app.logout();
                }
            } else if (err.request) {
                console.error('REQUEST');
                console.error(err.request);
            } else {
                newForm.error = err.message;
                console.error(err.message);
            }
            setForm({...form, ...newForm});
        }
    }
    const handleInput = (e) => {
        let newForm = {...form}
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    return (app.isLoggedIn() ? <Redirect to="/" /> :
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockClockOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Typography component="h6" color="red">
                            {form.error}
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            error={form.errorFields.hasOwnProperty('email')}
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={form.email}
                            autoFocus
                            onChange={handleInput}
                            disabled={form.loading}
                            helperText={form.errorFields?.email}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            error={form.errorFields.hasOwnProperty('password')}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleInput}
                            value={form.password}
                            disabled={form.loading}
                            helperText={form.errorFields?.password}
                        />
                        {form.tfaEnabled && <TextField
                            margin="normal"
                            required
                            fullWidth
                            error={form.errorFields.hasOwnProperty('tfa')}
                            id="tfa"
                            label="2FA Code"
                            name="tfa"
                            autoComplete="off"
                            value={form.tfa}
                            autoFocus
                            onChange={handleInput}
                            disabled={form.loading}
                            helperText={form.errorFields?.tfa}
                        />}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={form.loading}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
                            {'Copyright © '}
                            <Link color="inherit" href="https://material-ui.com/">
                                TurboTheorie
                            </Link>{' '}
                            {new Date().getFullYear()}
                            {'.'}
                            <br />
                            Build Version: {process.env.REACT_APP_VERSION}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}