import * as React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function Popup({ popup, setPopup, children, onClose, dialogActions }) {
    return (<Dialog open={popup.open} style={popup.style} maxWidth={popup.maxWidth} fullWidth={popup.fullWidth}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography variant="h6" component="div">
                {popup.title}
            </Typography>
            {onClose ? <IconButton
                disabled={popup.loading}
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton> : null}
        </DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>
        {dialogActions}
    </Dialog>)
}