import * as React from 'react';
import Popup from "./Popup";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
    Button, Chip,
    CircularProgress,
    DialogActions, FormControl, FormControlLabel, FormGroup,
    Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Paper, Select, Switch,
    Typography
} from "@mui/material";
import {useContext, useState} from "react";
import { red } from '@mui/material/colors';
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import AvatarLetters from "./AvatarLetters";
import {ExpandMore} from "@mui/icons-material";

export default function StudentBulk({ popup, setPopup, table, setTable, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);

    const [form, setForm] = useState({
        fields: {
            cancelled: 0,
            absent: 0,
            confirmed: 0,
            status: ''
        },
        sections: []
    })

    const onClose = () => {
        setPopup({...popup, open: false, items: [], title: '', loading: false });
        setForm({
            fields: {
                suspended: 0,
                absent: 0,
                confirmed: 0,
                status: ''
            },
            sections: []
        })
    }

    const onConfirm = async () => {
        setPopup({...popup, loading: true, title: `Updating ${popup.items?.length} students...`})
        try {
            let ids = popup.items.map((item, idx) => item.ID);
            let fields = {...form.fields}
            for(var k in fields) {
                if(form.sections.indexOf(k) === -1)
                    delete fields[k];
            }
            const res = await API({
                method: 'PUT', url: `/students?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {
                    ids: ids,
                    fields: fields
                }
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const updated = res.data.updated;
            const failed = res.data.failed;
            if(failed.length) {
                let msg = [`Couldn't update ${failed.length} / ${ids.length} students:`]
                failed.forEach((err, idx) => {
                    if(err.message)
                        msg.push(`#${err.ID}: ${err.message}`);
                })
                enqueueSnackbar(msg.join("\n"), {
                    variant: 'error',
                    style: { whiteSpace: 'pre-line' }
                });
            }
            if(!updated.length) {
                if(!failed.length)
                    enqueueSnackbar(`No students were updated.`, { variant: 'error' });
            }
            else {
                const newResults = [...table.results]
                if(!failed.length) {
                    enqueueSnackbar(`Updated ${updated.length} student(s).`, {variant: 'success'});
                }
                else {
                    let msg = [`Updated ${updated.length} / ${ids.length} students:`]
                    msg.push(updated.map((item, idx) => item.ID).join(', '));
                    enqueueSnackbar(msg.join("\n"), {
                        variant: 'warning',
                        style: {whiteSpace: 'pre-line'}
                    });
                }
                updated.forEach((item, idx) => {
                    //update visible row in table.
                    for (var i = 0; i < newResults.length; i++) {
                        if (newResults[i].ID === item.ID) {
                            newResults[i] = item;
                        }
                    }
                })
                setTable({...table, results: newResults})
            }
            setPopup({...popup, loading: false })
        }
        catch(e) {
            setPopup({...popup, loading: false, title: `Update Students`})
            HandleError(e, 'Update Students', enqueueSnackbar, app.logout);
        }
    }

    const handleInput = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleSwitch = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.checked ? 1 : 0;
        setForm(newForm);
    }

    const enabled = (section) => {
        return form.sections.indexOf(section) !== -1
    }

    const toggleSection = (section) => {
        let sections = [...form.sections];
        const idx = sections.indexOf(section);
        if(idx !== -1) {
            sections.splice(idx, 1);
        }
        else {
            sections.push(section);
        }

        setForm({...form, sections: sections})
        return true;
    }
    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose}>
            {popup.loading ? <CircularProgress /> :
                <>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Grid container component="main" columnSpacing={6}>
                            <Grid item xs={12} sm={12} md={7} sx={{mb: 2}}>
                                <Typography variant="overline" display="block" >
                                    Toggle sections for bulk-update:
                                </Typography>
                                <Accordion expanded={enabled('status')}
                                           onChange={() => toggleSection('status')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            Status
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>Change status of student</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup sx={{mt: 3}}>
                                            <FormControl>
                                                <InputLabel htmlFor="status">Status</InputLabel>
                                                <Select
                                                    id="status"
                                                    value={form.fields.status}
                                                    onChange={handleInput}
                                                    label="Status"
                                                    name="status"
                                                    variant="outlined"
                                                    readOnly={form.loading}
                                                >
                                                    <MenuItem value="auto">Auto</MenuItem>
                                                    <MenuItem value="archived">Archived</MenuItem>
                                                    <MenuItem value="oos">Out of System</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={enabled('confirmed')}
                                           onChange={() => toggleSection('confirmed')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            Confirmed
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>Mark/unmark student as confirmed</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup sx={{mt: 3}}>
                                            <FormControlLabel
                                                label="Confirmed"
                                                labelPlacement="end"
                                                control={
                                                    <Switch checked={form.fields.confirmed > 0}  name="confirmed" color="error" onChange={handleSwitch}/>
                                                }
                                            />
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={enabled('cancelled')}
                                           onChange={() => toggleSection('cancelled')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            Cancelled
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>Mark/unmark student as cancelled</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup sx={{mt: 3}}>
                                            <FormControlLabel
                                                label="Cancelled"
                                                labelPlacement="end"
                                                control={
                                                    <Switch checked={form.fields.cancelled > 0}  name="cancelled" color="error" onChange={handleSwitch}/>
                                                }
                                            />
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={enabled('absent')}
                                           onChange={() => toggleSection('absent')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            Absent
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>Mark/unmark student as absent</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup sx={{mt: 3}}>
                                            <FormControlLabel
                                                label="Absent"
                                                labelPlacement="end"
                                                control={
                                                    <Switch checked={form.fields.absent > 0}  name="absent" color="error" onChange={handleSwitch}/>
                                                }
                                            />
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <Typography variant="overline" display="block" >
                                    {popup.items.length > 1 ? `${popup.items.length} users will be updated:` : `Are you sure you want to update this user?`}
                                </Typography>
                                <Paper>
                                    <List round="true" sx={{ width: '100%', maxHeight: 200, overflow: 'auto', bgcolor: 'background.paper'}}>
                                        {popup.items?.map((item, idx) => {
                                            return (
                                                <ListItem
                                                    key={idx}
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <AvatarLetters name={item?.full_name ?? 'NN'} size={32} />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={`${item.full_name}`} />
                                                        <Chip label={`#${item.ID}`} variant="outlined" />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                    {children}
                    <DialogActions>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button onClick={onConfirm} disabled={popup.loading}>Confirm</Button>
                            {popup.loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: red[400],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                        <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
                    </DialogActions>
                </>}
        </Popup>
    )
}