import * as React from 'react';
import Main from "../components/Main";
import ResultsTable from "../components/ResultsTable";
import {
    add_url_param,
    fill_filters_from_url,
    fill_table_from_url,
    parse_url_params,
    remove_url_param,
} from "../Common";
import {useContext, useEffect, useState} from "react";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";
import AppContext from "../AppContext";
import './Events.css';
import FiltersEvent from "../components/FiltersEvent";
import EventPopup from "../components/EventPopup";

export default function Events() {
    const app = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    let urlParams = parse_url_params(window.location.href);
    let openItem = isFinite(parseInt(urlParams?.open)) ? parseInt(urlParams?.open) : 0;

    const [popup, setPopup] = useState({
        loading: false,
        open: openItem > 0,
        item: openItem ? { ID: openItem } : null,
        reload: true,
        title: '',
        maxWidth: 'xl',
        fullWidth: true
    });

    useEffect(() => {
        if(popup.open) {
            let url = add_url_param(window.location.href, 'open', popup?.item?.ID);
            window.history.replaceState(null, null, url);
        }
        else {
            let url = remove_url_param(window.location.href, 'open');
            if(url !== window.location.href)
                window.history.replaceState(null, null, url);
        }
    }, [popup.open, popup?.item?.ID])

    const initTable = {
        title: 'Events',
        get_url: '/events',
        results: [],
        results_count: 0,
        total_pages: 0,
        total_count: 0,
        loading: false,
        selected: [],
        per_page: 10,
        page: 1,
        order: 'desc',
        order_by: 'createdAt',
        columns: {
            'Event': {
                field: 'event',
                sortable: true,
                visible: true
            },
            'User': {
                field: 'user_id',
                sortable: true,
                visible: true
            },
            "Object": {
                field: "object_id",
                sortable: true,
                visible: true
            },
            "Description": {
                field: "description",
                sortable: false,
                visible: true
            },
            "Created At": {
                field: "createdAt",
                sortable: true,
                visible: true
            }
        },
        visible_columns: ['event', 'user_id', 'object_id', 'description', 'createdAt']
    };

    const initFilters = {
        search: '',
        search_by: '',
        search_by_options: ['description', 'commit_id', 'object_id'],
        event: [],
        object_id: '',
        user_id: '',
        createdAt: '',
    };

    const [table, setTable] = useState(initTable);
    const [filters, setFilters] = useState(initFilters);

    fill_table_from_url(initTable);
    fill_filters_from_url(initFilters, ['object_id', 'user_id'])

    const onMainRowClick = (item) => {
        setPopup({...popup,
            open: true,
            item: item,
            title: `Loading preview...`,
            loading: true
        })
    }

    const refreshTable = async () => {
        setTable({...table, loading: true})
        try {
            let params = {
                order: encodeURIComponent(table.order),
                order_by: encodeURIComponent(table.order_by),
                per_page: table.per_page,
                page: table.page
            };
            let fields = ['ID', 'commit_id', 'event_id'];
            fields = fields.concat(table.visible_columns)

            if(table.visible_columns.length)
                params['fields'] = fields.map((v) => encodeURIComponent(v)).join(',');

            if(Object.keys(filters).length) {
                for(var k in filters) {
                    if(filters[k] !== '' && filters[k] !== null && filters[k] !== undefined) {
                        params[k] =  typeof filters[k] === "number" ? filters[k] : encodeURIComponent(filters[k]);
                    }
                }
            }

            delete params['search_by_options'];

            let url = '';
            if(Object.keys(params).length) {
                for(let k in params) {
                    url += (url === '' ? '?' : '&') + k + '=' + params[k];
                }
            }

            url = table.get_url + url;
            const res = await API({ method: 'GET', url: url,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })
            if(res.status < 200 || res.status > 299)
                throw res.data;

            setTable({...table,
                selected: [],
                results: res.data.results,
                results_count: res.data.results.length,
                page: res.data.page,
                total_pages: res.data.pages,
                total_count: res.data.count,
                loading: false
            })
        }
        catch(e) {
            setTable({...table,
                results: [],
                selected: [],
                results_count: 0,
                total_pages: 0,
                total_count: 0,
                page: 1,
                loading: false
            })
            HandleError(e, table.title, enqueueSnackbar, app.logout);
        }
    }

    return <Main pageTitle="Events">
        <ResultsTable manager="events" onMainRowClick={onMainRowClick}
                      onBulkClick={null}
                      onDeleteClick={null}
                      setTable={setTable} table={table}
                      refreshTable={refreshTable} setFilters={setFilters} filters={filters}>
            <FiltersEvent />
        </ResultsTable>
        <EventPopup setPopup={setPopup} popup={popup} />
    </Main>
}