import {Typography} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/material/styles";

const FormCaptionTypography = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
}))
const FormCaption = (props) => {
    return <FormCaptionTypography variant="button" gutterBottom component="div" {...props}>
        {props.children}
    </FormCaptionTypography>
}
export default FormCaption;