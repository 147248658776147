import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import {stringToColor} from "../Common";

function stringAvatar(name, size) {
    if(!name || !name?.split?.())
        return {}

    if(name.length === 1)
        name += name;

    let i = name.split(' ');
    let f = name[0];
    let l = i.length > 1 ? i[1][0] : name?.[1];

    size = size ?? 32;
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: size,
            height: size,
            fontSize: size <= 42 ? '12px' : '16px',
            fontWeight: size <= 42 ? 'bold' : 'normal'
        },
        children: `${f}${l}`,
    };
}

export default function AvatarLetters({ name, size }) {
    let n = name?.full_name ?? (name?.first_name ? name?.first_name + ' ' + name?.last_name : name);
    if(!n)
        n = 'Na';

    return (
        <Avatar {...stringAvatar(n, size)} />
    );
}