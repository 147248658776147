import * as React from 'react';
import Popup from "./Popup";
import cloneDeep from "lodash/cloneDeep"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    DialogActions,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid, IconButton, InputAdornment,
    InputLabel, List, ListItem, ListItemAvatar, ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    Switch, TextareaAutosize,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import { red } from '@mui/material/colors';
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import moment from "moment";
import {
    DeleteOutlined,
    ExpandMore,
    LockOpenOutlined,
    LockOutlined, MailOutline,
    PushPin,
    PushPinOutlined, ReceiptLongOutlined, WhatsApp
} from "@mui/icons-material";
import {fdate, fdatetime, lockItem, make_url, unlockItem} from "../Common";
import {styled} from "@mui/material/styles";
import {Alert, DesktopDatePicker} from "@mui/lab";
import TextBox from "./TextBox";
import FormCaption from "./FormCaption";
import TextInput from "./TextInput";
import {exam_results} from "../Vars";
import AvatarLetters from "./AvatarLetters";
import StudentNotes from "./StudentNotes";

export default function StudentPopup({ popup, setPopup, mailPopup, whatsappPopup, notesPopup, setNotesPopup,
                                         setMailPopup, setWhatsappPopup, table, setTable, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);


    const products = {...app.ui.products}
    const studentCoursesObj = {}
    for(let p in app.ui.products) {
        if(p.indexOf('+') !== -1)
            continue;

        studentCoursesObj[p] = {
            product: p,
            is_fake: 0,
            location: '',
            ID: 0,
            booking: {
                time: '',
                code: '',
                status: '',
                account: '',
                booked_account: '',
                cbr_location: '',
                result: '',
                score: 0,
                cancelled: 0,
                absent: 0,
                confirmed: 0,
            }
        }
    }
    const studentData = {
        status: '',
        product: '',
        courses: {...studentCoursesObj},
        email: '',
        admin_notes: [],
        birth_date: '',
        first_name: '',
        last_name: '',
        mobile: '',
        addr_city: '',
        addr_street: '',
        addr_zip: '',
        cbr_number: '',
        cbr_name: '',
        discount: '',
        payment: '',
        notes: '',
        company: ''
    }

    const initForm = {
        errorFields: {},
        error: false,
        beginLock: false,
        action: '',
        sections: [],
        editCourses: false,
        fields: {...studentData},
        item: {},
        locations: {},
        courses: {...app.ui.courses},
        original: cloneDeep(studentData),
    }

    const [form, setForm] = useState({...initForm});

    useEffect(() => {
        if(popup.item?.ID && popup.reload)
            onLoad();

    }, [popup.item, popup.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(form.beginLock) {
            lockItem(app.token, 'student', form.item.ID, (data) => {
                if(form.item.lock_user?.ID !== app.user.ID) {
                    let newItem = {...form.item}
                    console.log(newItem);
                    newItem['lock_user'] = {'ID': app.user.ID, 'full_name': app.user.full_name}
                    setForm({...form, item: newItem})
                    console.log(newItem);
                }
            })
        }
        else {
            clearInterval(window.lockingTimer);
        }
    }, [form.beginLock])

    const resetForm = () => {
        setForm({...initForm});
    }

    const toggleEditCourses = (e) => {
        setForm({...form, editCourses: !!e.target.checked})
    }
    const courseChanged = () => {
        let changed = false;
        for(var p in form.original.courses) {
            var course = form.original.courses[p];
            var newCourse = form.fields.courses[p];
            if(course.ID !== newCourse.ID || course.is_fake !== newCourse.is_fake) {
                changed = true;
                break;
            }
        }
        return changed;
    }

    const openMail = () => {
        setMailPopup({...mailPopup, reload: true, item: form.item, open: true });
    }
    const openWhatsapp = () => {
        setWhatsappPopup({...whatsappPopup, reload: true, item: form.item, open: true });
    }
    const openNotes = () => {
        setNotesPopup({...notesPopup, reload: true, item: form.item, open: true });
    }
    const onLoad = async () => {
        setPopup({...popup, loading: true, reload: false })
        try {
            const res = await API({
                method: 'GET', url: `/student/${popup.item.ID}?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const item = res.data.result;

            setPopup({...popup, loading: false, item: item, reload: false, title: `Edit ${item.full_name} #${item.ID}`})
            let fields = {
                ID: item.ID,
                email: item.email,
                first_name: item.first_name,
                last_name: item.last_name,
                product: item.product,
                birth_date: item.birth_date,
                status: item.status,
                courses: item.courses,
                mobile: item.mobile,
                addr_city: item.addr_city,
                addr_street: item.addr_street,
                addr_zip: item.addr_zip,
                cbr_number: item.cbr_number,
                cbr_name: item.cbr_name,
                price: item.price,
                payment: item.payment,
                notes: item.notes,
                admin_notes: item.admin_notes
            }
            //reformat courses
            let courses = {}
            for(let p in item.courses) {
                let c = item.courses[p];
                c['is_fake'] = c?.ID === 0 ? 1 : 0;
                courses[p] = c;
            }
            fields['courses'] = {...form.fields.courses, ...courses};
            setForm({...form,
                loading: false,
                action: '',
                fields: {...fields},
                original: cloneDeep(fields),
                item: {...item},
                beginLock: true
            })

            console.log('On Load');
        }
        catch(e) {
            onClose()
            HandleError(e, 'Load Student', enqueueSnackbar, app.logout);
        }
    }

    const onClose = () => {
        unlockItem(app.token, 'student', popup.item.ID);
        setForm({...form, beginLock: false})
        setPopup({...popup, open: false, item: null, title: '', loading: false, reload: true });
        resetForm();
    }

    const goToOrders = () => {
        if(!form?.item?.ID)
            return;

        window.open(make_url('/orders', { search_by: 'student', search: form.item.ID }), "_blank")
    }

    const onDelete = () => {
        setForm({...form, action: 'delete'})
        setPopup({...popup, loading: true})
        setTimeout(() => {
            setPopup({...popup, loading: false})
        }, 2000);
    }

    const handleDateChange = (field, newDate) => {
        let newForm = {...form}
        newForm.fields[field] = newDate;
        setForm(newForm);
    }

    const onSave = async () => {

        setPopup({...popup, loading: true, title: `Saving ${popup.item.full_name} #${popup.item.ID}...`})
        setForm({...form, loading: true, action: 'update'})
        try {
            const form_data = {...prepareFields()}
            const res = await API({
                method: 'PUT', url: `/student/${popup.item.ID}?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: form_data
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            //update visible row in table.
            const newResults = [...table.results]
            for(var i = 0; i < newResults.length; i++) {
                if(newResults[i].ID === res.data.result.ID) {
                    for(var k in res.data.result) {
                        if(newResults[i].hasOwnProperty(k))
                            newResults[i][k] = res.data.result[k];
                    }
                }
            }
            setTable({...table, results: newResults})
            setForm({...form, loading: false, action: ''})
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.full_name} #${popup.item.ID}...`})
            enqueueSnackbar('Student updated successfully.', { variant: 'success' });
        }
        catch(e) {
            setForm({...form, loading: false, action: ''})
            setPopup({...popup, loading: false, reload: true, title: `Loading ${popup.item.full_name} #${popup.item.ID}...`})
            HandleError(e, 'Save Student', enqueueSnackbar, app.logout);
        }
    }

    const handleInput = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.value;
        setForm(newForm);
    }
    const handleSwitch = (e) => {
        let newForm = {...form}
        newForm.fields[e.target.name] = e.target.checked ? 1 : 0;
        setForm(newForm);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const toggleFake = (e, product) => {
        let newFields = {...form.fields}
        newFields.courses[product].is_fake = e.target.checked ? 1 : 0;
        setForm({...form, fields: newFields});
    }

    const togglePin = async (id) => {
        setPopup({...popup, loading: true, title: `Pinning note...`})
        let pin = 0;
        let index = -1;
        for(let x = 0; x < form.fields.admin_notes.length; x++) {
            if(id == form.fields.admin_notes[x].ID) {
                index = x;
                pin = form.fields.admin_notes[x].pinned ? 0 : 1;
                break;
            }
        }
        if(index === -1) {
            alert('Note not found, weird.');
            return;
        }

        try {
            const res = await API({
                method: 'PUT', url: `/student/${popup.item.ID}/note/${id}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {
                    pinned: pin
                }
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let newFields = {...form.fields}
            newFields['admin_notes'] = [...res.data.results]
            setForm({...form, fields: newFields})
            setPopup({...popup, loading: false, title:  `Edit ${popup.item.full_name} #${popup.item.ID}...`})
        }
        catch(e) {
            setPopup({...popup, loading: false, title:  `Edit ${popup.item.full_name} #${popup.item.ID}...`})
            HandleError(e, 'Pin Note', enqueueSnackbar, app.logout);
        }
    }
    const deleteNote = async (id) => {
        if(!window.confirm('Are you sure you want to delete this note?'))
            return;

        setPopup({...popup, loading: true, title: `Deleting note...`})
        try {
            const res = await API({
                method: 'DELETE', url: `/student/${popup.item.ID}/note/${id}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let newFields = {...form.fields}
            newFields['admin_notes'] = [...res.data.results]
            setForm({...form, fields: newFields})
            setPopup({...popup, loading: false, title:  `Edit ${popup.item.full_name} #${popup.item.ID}...`})
        }
        catch(e) {
            setPopup({...popup, loading: false, title:  `Edit ${popup.item.full_name} #${popup.item.ID}...`})
            HandleError(e, 'Delete Note', enqueueSnackbar, app.logout);
        }
    }

    const prepareFields = () => {
        const fields = {...form.fields};
        delete fields['admin_notes'];
        let product = fields.product;
        let my_courses = {...fields.courses};
        for(var k in fields) {
            if(!canEdit(k))
                delete fields[k]

            if(k === 'discount') {
                if(typeof fields[k] === 'string')
                    fields[k] = parseFloat(fields[k].replace(/,/, '.'));
            }
        }

        if(canEdit('courses')) {
            const courses = {};
            for (var p in my_courses) {
                if (product.indexOf(p) === -1)
                    continue;

                let course = my_courses[p];
                let booking = {...course.booking};

                delete booking['prev_course'];
                //delete booking['booked_account'];

                if (course.is_fake) {
                    booking['fake_location'] = course.location;
                    booking['fake_date'] = course.date;
                }

                for(var k in booking) {
                    if(!canEdit(`course_${k}`))
                        delete booking[k]
                }

                if(Object.keys(booking).length > 0) {
                    if (course.is_fake) {
                        courses[p] = {
                            ...booking
                        }
                    } else {
                        courses[p] = {
                            ID: course.ID,
                            ...booking
                        }
                    }
                }
            }

            if(Object.keys(courses).length > 0) {
                fields['courses'] = courses;
            }
        }

        return fields;
    }

    const canEdit = (field) => {
        return app.can('student', 'fields', [field]);
    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const sectionVisible = (section) => {
        return form.sections.indexOf(section) !== -1
    }
    const toggleSection = (section) => {
        let sections = [...form.sections];
        const idx = sections.indexOf(section);
        if(idx !== -1) {
            sections.splice(idx, 1);
        }
        else {
            sections.push(section);
        }

        setForm({...form, sections: sections})
        return true;
    }
    const handleCourseInput = (e) => {
        let product = e.target?.getAttribute('data-product');

        if(!product)
            return;

        let newFields = {...form.fields}
        let value = e.target.value;
        if(e.target.name === 'ID')
            value = parseInt(e.target.value);
        if(['time', 'code', 'account', 'booked_account', 'cbr_location', 'result',
            'score', 'status', 'absent', 'confirmed', 'cancelled'].indexOf(e.target.name) !== -1)
            newFields.courses[product].booking[e.target.name] = value;
        else
            newFields.courses[product][e.target.name] = value;
        setForm({...form, fields: newFields});
    }

    const handleCourseSwitch = (e) => {
        console.log(e);
        let product = e.target?.getAttribute('data-product');
        console.log(product);

        if(!product)
            return;

        let newFields = {...form.fields}
        newFields.courses[product].booking[e.target.name] = e.target.checked ? 1 : 0;
        setForm({...form, fields: newFields});
    }

    const handleCourseSelect = (e, product) => {
        let newFields = {...form.fields}
        let value = e.target.value;
        if(e.target.name === 'ID')
            value = parseInt(e.target.value);
        if(['time', 'code', 'account', 'booked_account', 'cbr_location', 'result', 'score',
            'status', 'absent', 'confirmed', 'cancelled'].indexOf(e.target.name) !== -1)
            newFields.courses[product].booking[e.target.name] = value;
        else
            newFields.courses[product][e.target.name] = value;
        setForm({...form, fields: newFields});
    }

    const isLocked = () => {
        return form.item?.lock_user?.ID > 0 && form.item.lock_user.ID !== app.user.ID;
    }

    const customStatus = ['archived', 'oos'].indexOf(form.fields.status) !== -1;

    return (
        <><Popup popup={popup} setPopup={setPopup} onClose={onClose} dialogActions={<DialogActions>
            <Box sx={{ m: 1, position: 'relative' }}>
                {app.can('student', 'delete', [form.fields.product]) && !isLocked() && <Button onClick={onDelete} disabled={popup.loading} color="error">Delete</Button>}
                {popup.loading && form.action === 'delete' && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: red[400],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
            <div style={{flex: '1 0 0'}} />
            {app.can('order', 'read') && <Button onClick={goToOrders} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>
                <ReceiptLongOutlined /> &nbsp; Orders
            </Button>}
            {app.can('student', 'mail') && <Button onClick={openMail} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>
                <MailOutline /> &nbsp; Mail
            </Button>}
            {app.can('student', 'whatsapp') && <Button onClick={openWhatsapp} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>
                <WhatsApp /> &nbsp; WhatsApp
            </Button>}
            <div style={{flex: '1 0 0'}} />
            {canEdit('admin_notes') && <Button onClick={openNotes} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>
                Add Admin Notes
            </Button>}
            <div style={{flex: '1 0 0'}} />
            <Box sx={{ m: 1, position: 'relative' }}>
                {app.can('student', 'update', [form.fields.product]) && !isLocked() && <Button onClick={onSave} disabled={popup.loading}>Save</Button>}
                {popup.loading && form.action === 'update' && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: 'primary',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
            <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
        </DialogActions>}>
            {popup.loading || form.loading || !form.item?.ID ? <Box sx={{py: 6, textAlign: 'center'}}><CircularProgress /></Box> :
                <>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Typography component="h6" color="red">
                            {form.error}
                        </Typography>
                        {isLocked() && <Alert severity="warning" sx={{mb: 1}}>
                            Currently locked by {form.item.lock_user.full_name}. Update is disabled.
                        </Alert>}
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                            sx={{mb: 4}}
                        >
                            <Item elevation={0}>Created: {form.item?.ID ? fdatetime(form.item.createdAt) : ''}</Item>
                            <Item elevation={0}>Updated: {form.item?.ID ? fdatetime(form.item.updatedAt) : ''}</Item>
                            <Item elevation={0}>
                                <Chip variant="outlined" size="small" label={'Product: ' + (form.item?.ID ? (products?.[form.item.product] ?? form.item.product) : '')} />
                            </Item>
                            <Item elevation={0}>{form.item?.ID ? app.statusLabel(form.item.status) : ''}</Item>
                            <Item elevation={0}>{form.item?.ID ? app.paymentLabel(form.item) : ''}</Item>
                        </Stack>
                        <Grid container component="main" columnSpacing={4} justifyContent="space-between">
                            <Grid item xs={12} sm={12} md={7}>
                                <Grid container component="main">
                                    <TextInput gridProps={{ md: 3}} label="CBR Number" name="cbr_number"
                                               form={form} onChange={handleInput} canEdit={canEdit} />
                                    <TextInput gridProps={{ md: 5}} label="CBR Name" name="cbr_name"
                                               form={form} onChange={handleInput} canEdit={canEdit} />
                                    <Grid item xs={12} sm={12} md={4}>
                                        <FormGroup>
                                            <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty('status')}>
                                                <InputLabel htmlFor="status">Status</InputLabel>
                                                <Select
                                                    id="status"
                                                    value={form.fields.status || ""}
                                                    onChange={handleInput}
                                                    label="Status"
                                                    name="status"
                                                    variant="outlined"
                                                    readOnly={form.loading || !canEdit('status')}
                                                >
                                                    <MenuItem value={customStatus ? 'auto' : form.fields.status}>
                                                        {customStatus || form.fields.status === 'auto' ? 'Auto' : `Auto (${form.fields.status})`}
                                                    </MenuItem>
                                                    <MenuItem value='archived'>Archived</MenuItem>
                                                    <MenuItem value='oos'>Out of System</MenuItem>
                                                </Select>
                                                <FormHelperText>{form.errorFields?.status}</FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container component="main">
                                    <TextInput gridProps={{ md: 4}} label="First Name" name="first_name"
                                               form={form} onChange={handleInput} canEdit={canEdit} />
                                    <TextInput gridProps={{ md: 4}} label="Last Name" name="last_name"
                                               form={form} onChange={handleInput} canEdit={canEdit} />
                                    <Grid item xs={12} sm={12} md={4}>
                                        <FormGroup>
                                            <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty('birth_date')}>
                                                <DesktopDatePicker
                                                    readOnly={form.loading || !canEdit('birth_date')}
                                                    label="Birth Date"
                                                    name="birth_date"
                                                    error={form.errorFields.hasOwnProperty('birth_date')}
                                                    inputFormat="dd-MM-yyyy"
                                                    value={form.fields.birth_date || ""}
                                                    clearable
                                                    maxDate={moment().add(-14, 'year').toDate()}
                                                    onChange={(date) => handleDateChange('birth_date', date)}
                                                    renderInput={(params) => <TextField variant="outlined" {...params} />}
                                                />
                                                <FormHelperText>{form.errorFields?.birth_date}</FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <TextInput gridProps={{ md: 7}} label="E-Mail" name="email"
                                               form={form} onChange={handleInput} canEdit={canEdit} />
                                    <TextInput gridProps={{ md: 5}} label="Mobile No." name="mobile"
                                               form={form} onChange={handleInput} canEdit={canEdit} />
                                </Grid>
                                <FormCaption>Address</FormCaption>
                                <Grid container component="main">
                                    <TextInput gridProps={{ md: 4}} label="City" name="addr_city"
                                        form={form} onChange={handleInput} canEdit={canEdit} />
                                    <TextInput gridProps={{ md: 5}} label="Street" name="addr_street"
                                        form={form} onChange={handleInput} canEdit={canEdit} />
                                    <TextInput gridProps={{ md: 3}} label="Postcode" name="addr_zip"
                                        form={form} onChange={handleInput} canEdit={canEdit} />
                                </Grid>
                                <Grid container>
                                    <TextInput gridProps={{ md: 6 }} label="Company" name="company"
                                               disabled={form.loading}
                                               form={form} onChange={handleInput} />
                                </Grid>
                                <FormCaption>Student Notes</FormCaption>
                                <FormGroup>
                                    <FormControl sx={{ m: 1, minWidth: 140 }}>
                                        <TextField
                                            id="notes"
                                            label="Notes"
                                            variant="outlined"
                                            InputProps={{ readOnly: true }}
                                            defaultValue={form.fields.notes || ""}
                                            multiline
                                            rows={2} />
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <Paper variant="" elevation={0} sx={{mt: 0, pb: 2, px: 2, backgroundColor: ''}}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={9}>
                                            <FormCaption sx={{mt: 0}}>
                                                {form.fields.product.indexOf('+') !== -1 ? 'Courses' : 'Course'}
                                                {form?.item?.ID && form?.item?.courses_pending_change > 0 &&
                                                <Tooltip title={"Some of courses have been changed recently and is waiting for CBR script to take care of the change."}>
                                                    <Chip sx={{ml: 2}} variant="outlined" color="warning" size="small"
                                                          label={'Pending Change'} />
                                                </Tooltip>}
                                            </FormCaption>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} sx={{pt: 0, pr: 1, textAlign: 'right'}}>
                                            <FormControlLabel
                                                label={<Tooltip title="Enable/Disable course editing">{form.editCourses ? <LockOpenOutlined /> : <LockOutlined />}</Tooltip>}
                                                labelPlacement="start"
                                                disabled={form.loading || !canEdit('courses')}
                                                control={
                                                    <Switch size="small"
                                                            checked={form.editCourses}
                                                            disabled={form.loading || !canEdit('courses')}
                                                            onChange={toggleEditCourses}/>
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box sx={{mb: 2, mt: 2}}>
                                        {courseChanged() && <Alert severity="warning" sx={{mb: 1}}>
                                            Course change detected. All CBR info regarding old course will be deleted.
                                        </Alert>}
                                        {Object.keys(studentCoursesObj).map((p, idx) => {
                                            let my_products = form.fields.product.split('+');
                                            if(my_products.indexOf(p) === -1)
                                                return null;

                                            let locations = [];
                                            let dates = {};
                                            if(form.fields.courses[p].is_fake) {
                                                locations = [...app.ui.locations]
                                            }
                                            else {
                                                locations = Object.keys(app.ui.courses[p]) ?? [];
                                                if (form.fields.courses[p].location && app.ui.courses[p].hasOwnProperty(form.fields.courses[p].location)) {
                                                    dates = {...app.ui.courses[p][form.fields.courses[p].location]}
                                                }

                                                if (form.fields.courses[p].ID > 0) {
                                                    if (!dates.hasOwnProperty(form.fields.courses[p].ID))
                                                        dates[form.fields.courses[p].ID] = form.fields.courses[p];

                                                    if (locations.indexOf(form.fields.courses[p].location) === -1)
                                                        locations.push(form.fields.courses[p].location);
                                                }
                                            }

                                            return <Accordion key={idx} expanded={sectionVisible(p)}
                                                              onChange={() => toggleSection(p)} elevation={1}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore />}
                                                >
                                                    <Typography sx={{ width: '66%', flexShrink: 0 }}>
                                                        {products[p]} Course
                                                    </Typography>
                                                    <Box sx={{ color: 'text.secondary' }}>
                                                        <FormGroup sx={{m: 0}}>
                                                            <FormControlLabel
                                                                label="Fake"
                                                                labelPlacement="end"
                                                                disabled={form.loading || !sectionVisible(p) || !form.editCourses || (!canEdit('course_location') && !canEdit('course_date'))}
                                                                control={
                                                                    <Switch size="small"
                                                                            checked={form.fields.courses[p]?.is_fake > 0}
                                                                            onChange={(e) => toggleFake(e, p)}/>
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{pt: 0, pb: 1, px: 1}}>
                                                    {form.fields.courses[p].is_fake <= 0 &&
                                                    <Grid container component="main" columnSpacing={1}>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty(`${p}_location`)}>
                                                                    <InputLabel htmlFor={`${p}_location`}>Location</InputLabel>
                                                                    <Select
                                                                        id={`${p}_location`}
                                                                        value={form.fields.courses[p]?.location || ""}
                                                                        onChange={(e) => handleCourseSelect(e, p)}
                                                                        label="Location"
                                                                        name="location"
                                                                        variant="outlined"
                                                                        readOnly={form.loading || !form.editCourses || !canEdit('course_location')}
                                                                    >
                                                                        {locations.map((key, idx) => {
                                                                            return <MenuItem key={idx} value={key}>{key}</MenuItem>
                                                                        })}
                                                                    </Select>
                                                                    <FormHelperText>{form.errorFields?.[`${p}_location`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty(`${p}_date`)}>
                                                                    <InputLabel htmlFor={`${p}_date`}>Course</InputLabel>
                                                                    <Select
                                                                        id={`${p}_date`}
                                                                        value={form.fields.courses[p].ID || ""}
                                                                        data-product={p}
                                                                        onChange={(e) => handleCourseSelect(e, p)}
                                                                        label="Course"
                                                                        name="ID"
                                                                        variant="outlined"
                                                                        readOnly={form.loading || !form.editCourses || !canEdit('course_date')}
                                                                    >
                                                                        {Object.keys(dates).map((course_id, idx) => {
                                                                            let course = dates[course_id];
                                                                            return <MenuItem key={idx} value={course_id}>{fdate(course.date)}</MenuItem>
                                                                        })}
                                                                    </Select>
                                                                    <FormHelperText>{form.errorFields?.[`${p}_date`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>}
                                                    {form.fields.courses[p].is_fake > 0 &&
                                                    <Grid container component="main" columnSpacing={1}>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty(`${p}_location`)}>
                                                                    <InputLabel htmlFor={`${p}_location`}>Fake Location</InputLabel>
                                                                    <Select
                                                                        id={`${p}_location`}
                                                                        value={form.fields.courses[p].location || ""}
                                                                        onChange={(e) => handleCourseSelect(e, p)}
                                                                        label="Fake Location"
                                                                        name="location"
                                                                        variant="outlined"
                                                                        readOnly={form.loading || !form.editCourses || !canEdit('course_location')}
                                                                    >
                                                                        {locations.map((key, idx) => {
                                                                            return <MenuItem key={idx} value={key}>{key}</MenuItem>
                                                                        })}
                                                                    </Select>
                                                                    <FormHelperText>{form.errorFields?.[`${p}_location`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty(`${p}_date`)}>
                                                                    <InputLabel htmlFor={`${p}_date`}>Fake Course</InputLabel>
                                                                    <Select
                                                                        id={`${p}_date`}
                                                                        value={form.fields.courses?.[p].date || ""}
                                                                        onChange={(e) => handleCourseSelect(e, p)}
                                                                        label="Fake Course"
                                                                        name="date"
                                                                        variant="outlined"
                                                                        readOnly={form.loading || !form.editCourses || !canEdit('course_date')}
                                                                    >
                                                                        {Object.keys(app.ui.other_dates).map((course, idx) => {
                                                                            return <MenuItem key={idx} value={course}>{app.ui.other_dates[course]}</MenuItem>
                                                                        })}
                                                                    </Select>
                                                                    <FormHelperText>{form.errorFields?.[`${p}_date`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>}
                                                    <Grid container component="main" columnSpacing={1}>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty(`${p}_status`)}>
                                                                    <TextBox
                                                                        id={`${p}_status`}
                                                                        value={form.fields.courses[p]?.booking?.status || ""}
                                                                        variant="outlined"
                                                                        inputProps={{'data-product': p, readOnly: form.loading || !form.editCourses || !canEdit('course_status')}}
                                                                        onChange={handleCourseInput}
                                                                        label="Status"
                                                                        name="status"
                                                                    />
                                                                    <FormHelperText>{form.errorFields?.[`${p}_status`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty(`${p}_time`)}>
                                                                    <TextBox
                                                                        id={`${p}_time`}
                                                                        value={form.fields.courses[p]?.booking?.time || ""}
                                                                        inputProps={{'data-product': p, readOnly: form.loading || !form.editCourses || !canEdit('course_time')}}
                                                                        onChange={handleCourseInput}
                                                                        label="Time"
                                                                        name="time"
                                                                        variant="outlined"
                                                                    />
                                                                    <FormHelperText>{form.errorFields?.[`${p}_time`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty(`${p}_code`)}>
                                                                    <TextBox
                                                                        id={`${p}_code`}
                                                                        value={form.fields.courses[p]?.booking?.code || ""}
                                                                        inputProps={{'data-product': p, readOnly: form.loading || !form.editCourses || !canEdit('course_code')}}
                                                                        onChange={handleCourseInput}
                                                                        label="Code"
                                                                        name="code"
                                                                        variant="outlined"
                                                                    />
                                                                    <FormHelperText>{form.errorFields?.[`${p}_code`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty(`${p}_account`)}>
                                                                    <TextBox
                                                                        id={`${p}_account`}
                                                                        value={form.fields.courses[p]?.booking?.account || ""}
                                                                        inputProps={{'data-product': p, readOnly: form.loading || !form.editCourses || !canEdit('course_account')}}
                                                                        onChange={handleCourseInput}
                                                                        label="Account"
                                                                        name="account"
                                                                        variant="outlined"
                                                                    />
                                                                    <FormHelperText>{form.errorFields?.[`${p}_account`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty(`${p}_booked_account`)}>
                                                                    <TextBox
                                                                        id={`${p}_booked_account`}
                                                                        value={form.fields.courses[p]?.booking?.booked_account || ""}
                                                                        inputProps={{'data-product': p, readOnly: form.loading || !form.editCourses || !canEdit('course_booked_account')}}
                                                                        onChange={handleCourseInput}
                                                                        label="Booked Account"
                                                                        name="booked_account"
                                                                        variant="outlined"
                                                                    />
                                                                    <FormHelperText>{form.errorFields?.[`${p}_booked_account`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty(`${p}_cbr_location`)}>
                                                                    <TextBox
                                                                        id={`${p}_cbr_location`}
                                                                        value={form.fields.courses[p]?.booking?.cbr_location || ""}
                                                                        inputProps={{'data-product': p, readOnly: form.loading || !form.editCourses || !canEdit('course_cbr_location')}}
                                                                        onChange={handleCourseInput}
                                                                        label="CBR Location"
                                                                        name="cbr_location"
                                                                        variant="outlined"
                                                                    />
                                                                    <FormHelperText>{form.errorFields?.[`${p}_cbr_location`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty(`${p}_result`)}>
                                                                    <InputLabel htmlFor={`${p}_result`}>Exam Result</InputLabel>
                                                                    <Select
                                                                        id={`${p}_result`}
                                                                        value={form.fields.courses[p]?.booking?.result || ""}
                                                                        onChange={(e) => handleCourseSelect(e, p)}
                                                                        label="Exam Result"
                                                                        name="result"
                                                                        variant="outlined"
                                                                        readOnly={form.loading || !form.editCourses || !canEdit('course_result')}
                                                                    >
                                                                        {Object.keys(exam_results).map((key, idx) => {
                                                                            return <MenuItem key={idx} value={key}>{exam_results[key]}</MenuItem>
                                                                        })}
                                                                    </Select>
                                                                    <FormHelperText>{form.errorFields?.[`${p}_result`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4}>
                                                            <FormGroup>
                                                                <FormControl sx={{ m: 1 }} error={form.errorFields.hasOwnProperty(`${p}_score`)}>
                                                                    <TextBox
                                                                        id={`${p}_score`}
                                                                        value={form.fields.courses[p]?.booking?.score || ""}
                                                                        variant="outlined"
                                                                        inputProps={{
                                                                            'data-product': p, readOnly: form.loading || !form.editCourses || !canEdit('course_score'),
                                                                            type: 'number',
                                                                            step: 1
                                                                        }}
                                                                        onChange={handleCourseInput}
                                                                        label="Exam Score"
                                                                        name="score"
                                                                    />
                                                                    <FormHelperText>{form.errorFields?.[`${p}_score`]}</FormHelperText>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container component="main" columnSpacing={2}>
                                                        <Grid item xs={12} sm={12} md={4}>
                                                            <FormGroup sx={{m: 2}}>
                                                                <FormControlLabel
                                                                    label="Confirmed"
                                                                    labelPlacement="end"
                                                                    disabled={form.loading || !form.editCourses || !canEdit('course_confirmed')}
                                                                    control={
                                                                        <Switch checked={form.fields.courses[p]?.booking?.confirmed > 0} name="confirmed" id={`${p}_confirmed`}
                                                                                inputProps={{'data-product': p}} color="success" onChange={handleCourseSwitch}/>
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4}>
                                                            <FormGroup sx={{m: 2}}>
                                                                <FormControlLabel
                                                                    label="Cancelled"
                                                                    labelPlacement="end"
                                                                    disabled={form.loading || !form.editCourses || !canEdit('course_cancelled')}
                                                                    control={
                                                                        <Switch checked={form.fields.courses[p]?.booking?.cancelled > 0} name="cancelled" id={`${p}_cancelled`}
                                                                                inputProps={{'data-product': p}} color="error" onChange={handleCourseSwitch}/>
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4}>
                                                            <FormGroup sx={{m: 2}}>
                                                                <FormControlLabel
                                                                    label="Absent"
                                                                    labelPlacement="end"
                                                                    disabled={form.loading || !form.editCourses || !canEdit('course_absent')}
                                                                    control={
                                                                        <Switch checked={form.fields.courses[p]?.booking?.absent > 0} name="absent" id={`${p}_absent`}
                                                                                inputProps={{'data-product': p}} color="warning" onChange={handleCourseSwitch}/>
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        })}
                                    </Box>
                                    <FormCaption>Pricing</FormCaption>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormGroup>
                                                <FormControl sx={{ m: 1, minWidth: 140 }} error={form.errorFields.hasOwnProperty('payment')}>
                                                    <InputLabel htmlFor="payment">Payment</InputLabel>
                                                    <Select
                                                        id="payment"
                                                        value={form.fields.payment || ""}
                                                        onChange={handleInput}
                                                        label="Payment"
                                                        name="payment"
                                                        variant="outlined"
                                                        readOnly={form.loading || !canEdit('payment')}
                                                    >
                                                        {Object.keys(app.ui.payment_methods).map((key, idx) => {
                                                            return <MenuItem key={idx} value={key}>{app.ui.payment_methods[key]}</MenuItem>
                                                        })}
                                                    </Select>
                                                    <FormHelperText>{form.errorFields?.['payment']}</FormHelperText>
                                                </FormControl>
                                            </FormGroup>
                                        </Grid>
                                        <TextInput gridProps={{ md: 6}} label="Manual Discount" name="discount"
                                                   disabled={form.loading || form.fields.payment === 'free' || !canEdit('discount')}
                                                   inputProps={{ type: 'number', step: '0.01', min: 0 }}
                                                   InputProps={{ 'startAdornment': <InputAdornment position="start">€</InputAdornment> }}
                                                   form={form} onChange={handleInput} />
                                    </Grid>
                                    <FormCaption>Admin Notes</FormCaption>
                                    <List className="admin-notes-list" sx={{ width: '100%',
                                        maxHeight: 300,
                                        overflow: 'auto',
                                        bgcolor: 'background.paper' }}>
                                        {form.fields.admin_notes.map((note, idx) => {
                                            return <React.Fragment key={idx}>
                                                {idx > 0 && <Divider variant="inset" component="li" />}
                                                <ListItem key={idx} alignItems="flex-start" className={note.pinned ? 'pinned' : ''}>
                                                    <Tooltip title={`${note.user.full_name} #${note.user.ID}`}>
                                                        <ListItemAvatar>
                                                            <AvatarLetters name={note.user.full_name} />
                                                        </ListItemAvatar>
                                                    </Tooltip>
                                                    <ListItemText
                                                        primary={note.text}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    {note.user.full_name}
                                                                </Typography>
                                                                {' at ' + fdatetime(note.date)}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                    <div className="admin-note-actions">
                                                        {app.can('student', 'pin_notes') && <IconButton size="small" onClick={() => togglePin(note.ID)} color="warning" component="span">
                                                            {note.pinned ? <PushPin fontSize="inherit" /> : <PushPinOutlined fontSize="inherit" />}
                                                        </IconButton>}
                                                        {app.user.ID === note.user.ID && <IconButton size="small" onClick={() => deleteNote(note.ID)} color="error" component="span">
                                                            <DeleteOutlined fontSize="inherit" />
                                                        </IconButton>}
                                                    </div>
                                                </ListItem>
                                            </React.Fragment>
                                        })}
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                    {children}
                </>}
        </Popup>
        <StudentNotes popup={notesPopup} setPopup={setNotesPopup} studentForm={form} setStudentForm={setForm} />
        </>
    )
}