import * as React from 'react';
import Main from "../components/Main";
import ResultsTable from "../components/ResultsTable";
import {
    fdate,
    fill_filters_from_url,
    fill_table_from_url,
} from "../Common";
import {useContext, useState} from "react";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";
import AppContext from "../AppContext";
import FiltersExam from "../components/FiltersExam";

export default function Exams() {
    const app = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();


    const initTable = {
        title: 'Exams',
        get_url: '/exams',
        results: [],
        results_count: 0,
        total_pages: 0,
        total_count: 0,
        loading: false,
        selected: [],
        per_page: 10,
        page: 1,
        order: 'desc',
        order_by: 'createdAt',
        columns: {
            'Product': {
                field: 'product',
                sortable: true,
                visible: true
            },
            'Date': {
                field: 'date',
                sortable: true,
                visible: true
            },
            'CBR Location': {
                field: 'cbr_location',
                sortable: false,
                visible: true
            },
            'Assigned': {
                field: 'assigned',
                sortable: true,
                visible: true
            },
            'Times': {
                field: 'times',
                sortable: true,
                visible: true
            },
            'Created At': {
                field: 'createdAt',
                sortable: true,
                visible: true
            }
        }
    };

    const initFilters = {
        search: '',
        search_by: '',
        search_by_options: ['date', 'cbr_location', 'time', 'product'],
        product: '',
        cbr_location: '',
        date_range: '',
        group: 1,
        has_spots: null,
        has_purchased: null,
        has_reserved: null,
        assigned: null
    };

    const [table, setTable] = useState(initTable);
    const [filters, setFilters] = useState(initFilters);

    fill_table_from_url(initTable);
    fill_filters_from_url(initFilters, ['has_spots', 'has_reserved', 'has_purchased', 'assigned'])

    const refreshTable = async () => {
        setTable({...table, results: [], selected: [], results_count: table.results.length, loading: true})
        try {
            let params = {
                order: encodeURIComponent(table.order),
                order_by: encodeURIComponent(table.order_by),
                per_page: table.per_page,
                page: table.page
            };
            let fields = ['ID', 'time', 'reserved', 'spots', 'purchased', 'linked_courses'];

            for(let field_name in table.columns) {
                let col = table.columns[field_name];
                if(col.visible) {
                    fields.push(col.field);
                }
            }

            if(fields.length > 1)
                params['fields'] = fields.map((v) => encodeURIComponent(v)).join(',');

            if(Object.keys(filters).length) {
                for(var k in filters) {
                    if(filters[k] !== '' && filters[k] !== null && filters[k] !== undefined)
                        params[k] =  typeof filters[k] === "number" ? filters[k] : encodeURIComponent(filters[k]);
                }
            }

            delete params['search_by_options'];

            let url = '';
            if(Object.keys(params).length) {
                for(let k in params) {
                    url += (url === '' ? '?' : '&') + k + '=' + params[k];
                }
            }

            url = table.get_url + url;
            const res = await API({ method: 'GET', url: url,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })
            if(res.status < 200 || res.status > 299)
                throw res.data;

            setTable({...table,
                selected: [],
                results: res.data.results,
                results_count: res.data.results.length,
                page: res.data.page,
                total_pages: res.data.pages,
                total_count: res.data.count,
                loading: false
            })
        }
        catch(e) {
            setTable({...table,
                results: [],
                selected: [],
                results_count: 0,
                total_pages: 0,
                total_count: 0,
                page: 1,
                loading: false
            })
            HandleError(e, table.title, enqueueSnackbar, app.logout);
        }
    }

    const onCreateCourseClick = async (product, date, cbr_location) => {
        if(!window.confirm(`Are you sure you want to create course?\n\nProduct: ${product}\nDate: ${fdate(date)}\nCBR Location: ${cbr_location}`))
            return;

        setTable({...table, loading: true })
        try {
            let url = '/course/from-exam';
            const res = await API({ method: 'POST', url: url,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {
                    product: product,
                    date: date,
                    cbr_location: cbr_location,
                }
            })
            if(res.status < 200 || res.status > 299)
                throw res.data;

            enqueueSnackbar('Course(s) created successfully.', { variant: 'success' });
            await refreshTable();
        }
        catch (e) {
            HandleError(e, 'Create Course', enqueueSnackbar, app.logout);
            await refreshTable();
        }
    }

    return <Main pageTitle="Exams">
        <ResultsTable manager="exams" onMainRowClick={null}
                      onBulkClick={null}
                      onDeleteClick={null}
                      setTable={setTable} table={table}
                      refreshTable={refreshTable} setFilters={setFilters} filters={filters}
                      selectedRow={null}
                      onCreateCourseClick={onCreateCourseClick}>
            <FiltersExam />
        </ResultsTable>
    </Main>
}