import * as React from 'react';
import Main from "../components/Main";
import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import preval from 'preval.macro'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Divider,
    FormControlLabel,
    FormGroup, FormHelperText,
    Paper, Stack,
    Switch,
    Typography,
    Alert
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import styled from "@emotion/styled";

export default function AppSettings() {
    const app = useContext(AppContext);
    const [sections, setSections] = useState([]);
    useEffect(() => {

    }, [app.ui]);

    const sectionVisible = (section) => {
        return sections.indexOf(section) !== -1
    }

    const toggleSection = (section) => {
        let s = [...sections];
        const idx = s.indexOf(section);
        if(idx !== -1) {
            s.splice(idx, 1);
        }
        else {
            s.push(section);
        }
        setSections(s)
        return true;
    }
    const handleSwitch = (e) => {
        let newUI = {...app.ui}
        newUI[e.target.name] = !!e.target.checked;
        app.set({...app, ui: newUI })
    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        width: '100%',
        color: theme.palette.text.secondary
    }));

    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

    return <Main pageTitle="App Settings">
        <Box sx={{ width: '100%' }}>
            <Accordion expanded={sectionVisible('appearance')}
                       onChange={() => toggleSection('appearance')} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                >
                    <Typography sx={{ width: '25%', flexShrink: 0 }}>
                        Appearance
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Change appearance of the app.</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{pt: 0, pb: 1, px: 1}}>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        divider={<Divider orientation="horizontal" flexItem />}
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Item elevation={0}>
                            <FormGroup>
                                <FormControlLabel
                                    label="Colorful Events Log"
                                    labelPlacement="end"
                                    control={
                                        <Switch checked={app.ui.colorEvents}  name="colorEvents" color="primary" onChange={handleSwitch}/>
                                    }
                                />
                                <FormHelperText>
                                    Enable/disable coloring of rows with same commit ID in Events Log.
                                </FormHelperText>
                            </FormGroup>
                        </Item>
                        <Item elevation={0}>
                            <FormGroup>
                                <FormControlLabel
                                    label="Dark Mode"
                                    labelPlacement="end"
                                    control={
                                        <Switch checked={app.ui.darkMode}  name="darkMode" color="primary" onChange={handleSwitch}/>
                                    }
                                />
                                <FormHelperText>
                                    Enable/disable Dark mode theme.
                                </FormHelperText>
                            </FormGroup>
                        </Item>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={sectionVisible('performance')}
                       onChange={() => toggleSection('performance')} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                >
                    <Typography sx={{ width: '25%', flexShrink: 0 }}>
                        Performance
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Enable/disable features to improve performance over the look and feel.</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{pt: 0, pb: 1, px: 1}}>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        divider={<Divider orientation="horizontal" flexItem />}
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Item elevation={0}>
                            <FormGroup>
                                <FormControlLabel
                                    label="Performance Mode"
                                    labelPlacement="end"
                                    control={
                                        <Switch checked={app.ui.performanceMode}  name="performanceMode" color="primary" onChange={handleSwitch}/>
                                    }
                                />
                                <FormHelperText>
                                    Performance Mode disables animations and transitions, improving overall performance of the application at the cost of eye-candy.
                                </FormHelperText>
                            </FormGroup>
                        </Item>
                        {app.can('general', 'hw_stats') &&
                        <Item elevation={0}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                divider={<Divider orientation="vertical" flexItem />}
                                alignItems="flex-start"
                                spacing={1}
                            >
                                <Item elevation={0}>
                                    <Typography component="span" color={app.ui.hw_stats?.cpu?.idle < 20 ? "error" : "primary"}>CPU Usage: {100 - app.ui.hw_stats?.cpu?.idle}%</Typography>
                                </Item>
                                <Item elevation={0}>
                                    RAM: {app.ui.hw_stats?.mem_free} free of {app.ui.hw_stats?.mem_total}
                                </Item>
                                <Item elevation={0}>
                                    Disk: {app.ui.hw_stats?.disk_free} free
                                </Item>
                                <Item elevation={0}>
                                    Temp size: {app.ui.hw_stats?.log_size}
                                </Item>
                            </Stack>
                        </Item>}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Alert severity="info" sx={{mt: 2}}>
                App Settings are stored in browser's local storage. If you delete cache or browsing data, it is possible
                that settings will be removed as well so keep that in mind.
            </Alert>
        </Box>
        <Box sx={{mt:6}}>
            <small>
                App Version: {process.env.REACT_APP_VERSION} &nbsp; Build Date: {dateTimeStamp}
                {app.ui.new_version != '' && app.ui.new_version !== process.env.REACT_APP_VERSION ?
                    <Typography variant="span" component="div" noWrap color="error">
                        New version {app.ui.new_version} available! Please refresh all tabs.
                    </Typography> : <Typography variant="span" component="div" noWrap>
                        Up to date :)
                    </Typography>}
            </small>
        </Box>
    </Main>
}