import * as React from 'react';
import Popup from "./Popup";
import {
    TableContainer,
    Table,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Divider,
    Grid, Paper,
    Stack,
    Typography, TableBody, TableRow, TableCell, Link
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {deepOrange, red} from '@mui/material/colors';
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {fdatetime, make_url, price} from "../Common";
import {styled} from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";

export default function OrderPopup({ popup, setPopup, table, setTable, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);

    const initForm = {
        errorFields: {},
        error: false,
        action: '',
        sections: [],
        item: {},
    }

    const [form, setForm] = useState({...initForm});

    useEffect(() => {
        if(popup.item?.ID && popup.reload)
            onLoad();

    }, [popup.item, popup.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    const resetForm = () => {
        setForm({...initForm});
    }

    const onLoad = async () => {
        setPopup({...popup, loading: true, reload: false })
        try {
            const res = await API({
                method: 'GET', url: `/order/${popup.item.ID}?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const item = res.data.result;

            setPopup({...popup, loading: false, item: item, reload: false, title: `View Order #${item.ID}`})

            setForm({...form,
                loading: false,
                action: '',
                item: {...item}
            })
        }
        catch(e) {
            onClose()
            HandleError(e, 'Load Order', enqueueSnackbar, app.logout);
        }
    }

    const onDelete = () => {
        setForm({...form, action: 'delete'})
        setPopup({...popup, loading: true})
        setTimeout(() => {
            setPopup({...popup, loading: false})
        }, 2000);
    }

    const onClose = () => {
        setPopup({...popup, open: false, item: null, title: '', loading: false, reload: true });
        resetForm();
    }

    const goToStudent = () => {
        if(!form?.item?.ID)
            return;

        if(!form?.item?.student?.ID) {
            alert('Student not found.');
            return;
        }
        window.open(make_url('/students', { search: form.item.student.ID, open: form.item.student.ID }), "_blank")
    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const onSync = async () => {
        setPopup({...popup, loading: true, title: `Synchronizing #${popup.item.ID}...`})
        setForm({...form, loading: true, action: 'update'})
        try {
            const res = await API({
                method: 'PUT', url: `/order/${popup.item.ID}?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;
            //update visible row in table.
            const newResults = [...table.results]
            for (var i = 0; i < newResults.length; i++) {
                if (newResults[i].ID === res.data.result.ID) {
                    for (var k in res.data.result) {
                        if (newResults[i].hasOwnProperty(k))
                            newResults[i][k] = res.data.result[k];
                    }
                }
            }
            setTable({...table, results: newResults})
            setForm({...form, loading: false, action: ''})
            setPopup({...popup, loading: false, reload: true, title: `Loading #${popup.item.ID}...`})
        } catch (e) {
            setForm({...form, loading: false, action: ''})
            setPopup({...popup, loading: false, reload: true, title: `Loading #${popup.item.ID}...`})
            HandleError(e, 'Synchronize Order', enqueueSnackbar, app.logout);
        }
    }
    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose}>
            {popup.loading || form.loading || !form.item?.ID ? <Box sx={{py: 6, textAlign: 'center'}}><CircularProgress /></Box> :
                <>
                    <Box component="div" sx={{ mt: 1 }}>
                        <Typography component="h6" color="red">
                            {form.error}
                        </Typography>
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                            sx={{mb: 4}}
                        >
                            <Item elevation={0}>Created: {form.item?.ID ? fdatetime(form.item.createdAt) : ''}</Item>
                            <Item elevation={0}>Updated: {form.item?.ID ? fdatetime(form.item.updatedAt) : ''}</Item>
                            <Item elevation={0}>Synced: {form.item?.ID ? fdatetime(form.item.syncedAt) : ''}</Item>
                            <Item elevation={0}>Paid: {form.item?.ID ? fdatetime(form.item.paidAt) : ''}</Item>
                        </Stack>
                        <Grid container component="main" columnSpacing={4} justifyContent="space-between">
                            <Grid item xs={12} sm={12} md={6}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 100 }} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" colSpan="2" scope="row">ORDER DETAILS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow selected>
                                                <TableCell component="th" scope="row">Transaction ID</TableCell>
                                                <TableCell><code>{form.item.transaction_id}</code></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Status</TableCell>
                                                <TableCell>{app.orderStatusLabel(form.item.status)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Product</TableCell>
                                                <TableCell>{app.orderProductLabel(form.item.product)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Description</TableCell>
                                                <TableCell>{form.item.description}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ pt: 4 }} align="right" component="th" scope="row">Method</TableCell>
                                                <TableCell sx={{ pt: 4 }} align="right">{form.item.method}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">Amount</TableCell>
                                                <TableCell align="right">€{price(form.item.amount)}</TableCell>
                                            </TableRow>
                                            {form.item.refunded_amount > 0 &&
                                            <TableRow>
                                                <TableCell align="right" sx={{ color: deepOrange[500] }} component="th" scope="row">Refunded Amount</TableCell>
                                                <TableCell align="right" sx={{ color: deepOrange[500] }}>€{price(form.item.refunded_amount)}</TableCell>
                                            </TableRow>}
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row"><strong>Total Paid</strong></TableCell>
                                                <TableCell align="right"><strong>€{price(form.item.amount - form.item.refunded_amount)}</strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 100 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" colSpan="2" scope="row">CUSTOMER DETAILS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Student</TableCell>
                                                <TableCell>
                                                    <strong>
                                                        <Link underline="none" target="_blank" rel="noreferrer"
                                                           href={make_url('/students', { search: form.item.student_id, open: form.item.student_id })}>
                                                            {form.item.student?.ID ? `${form.item.student.full_name} #${form.item.student_id}` : `#${form.item.student_id} (not found)`}
                                                        </Link>
                                                    </strong>
                                                    {form.item.student?.ID > 0 && <>
                                                        <br />
                                                        {form.item.student.email}
                                                    </>}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">IP Address</TableCell>
                                                <TableCell>{form.item.ip_address}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Consumer Name</TableCell>
                                                <TableCell>{form.item.consumer_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Consumer Account</TableCell>
                                                <TableCell>{form.item.consumer_account}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Consumer BIC</TableCell>
                                                <TableCell>{form.item.consumer_bic}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ mt: 2}}>
                            <Table sx={{ minWidth: 100 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" colSpan="2" scope="row">URLs</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Status</TableCell>
                                        <TableCell>{form.item.status_url}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Checkout</TableCell>
                                        <TableCell>{form.item.checkout_url}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    {children}
                    <DialogActions>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            {app.can('order', 'delete', [form.item.product]) && <Button onClick={onDelete} disabled={popup.loading} color="error">Delete</Button>}
                            {popup.loading && form.action === 'delete' && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: red[400],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                        <div style={{flex: '1 0 0'}} />
                        <Button onClick={goToStudent} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>View Student</Button>
                        <div style={{flex: '1 0 0'}} />
                        <Box sx={{ m: 1, position: 'relative' }}>
                            {app.can('order', 'sync', []) && <Button onClick={onSync} disabled={popup.loading}>Synchronize</Button>}
                            {popup.loading && form.action === 'update' && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: 'primary',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                        <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
                    </DialogActions>
                </>}
        </Popup>
    )
}