import * as React from 'react';
import Main from "../components/Main";
import ResultsTable from "../components/ResultsTable";
import {
    add_url_param,
    fill_filters_from_url,
    fill_table_from_url,
    parse_url_params,
    remove_url_param,
} from "../Common";
import {useContext, useEffect, useState} from "react";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";
import AppContext from "../AppContext";
import FiltersCourse from "../components/FiltersCourse";
import CoursePopup from "../components/CoursePopup";
import CourseBulk from "../components/CourseBulk";
import CourseDelete from "../components/CourseDelete";

export default function Courses() {
    const app = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    let urlParams = parse_url_params(window.location.href);
    let openItem = isFinite(parseInt(urlParams?.open)) ? parseInt(urlParams?.open) : 0;

    const [popup, setPopup] = useState({
        loading: false,
        open: openItem > 0,
        item: openItem ? { ID: openItem } : null,
        reload: true,
        title: '',
        maxWidth: 'lg',
        fullWidth: true
    });
    const [deletePopup, setDeletePopup] = useState({
        loading: false,
        open: false,
        items: [],
        title: '',
        maxWidth: 'sm',
        fullWidth: true
    });
    const [bulkPopup, setBulkPopup] = useState({
        loading: false,
        open: false,
        items: [],
        title: '',
        maxWidth: 'md',
        fullWidth: true
    });

    useEffect(() => {
        if(popup.open) {
            let url = add_url_param(window.location.href, 'open', popup?.item?.ID);
            window.history.replaceState(null, null, url);
        }
        else {
            let url = remove_url_param(window.location.href, 'open');
            if(url !== window.location.href)
                window.history.replaceState(null, null, url);
        }
    }, [popup.open, popup?.item?.ID])

    const initTable = {
        title: 'Courses',
        get_url: '/courses',
        results: [],
        results_count: 0,
        total_pages: 0,
        total_count: 0,
        loading: false,
        selected: [],
        per_page: 10,
        page: 1,
        order: 'desc',
        order_by: 'createdAt',
        columns: {
            'Product': {
                field: 'product',
                sortable: true,
                visible: true
            },
            'Date': {
                field: 'date',
                sortable: true,
                visible: true
            },
            'Location': {
                field: 'location',
                sortable: false,
                visible: true
            },
            'Exam Date': {
                field: 'exam_date',
                sortable: true,
                visible: true
            },
            'Free Spots': {
                field: 'spots',
                sortable: true,
                visible: true
            },
            'Cancelled': {
                field: 'cancelled',
                sortable: true,
                visible: true
            },
            'Active': {
                field: 'active',
                sortable: true,
                visible: true
            },
            'Created At': {
                field: 'createdAt',
                sortable: true,
                visible: true
            }
        }
    };

    const initFilters = {
        search: '',
        search_by: '',
        search_by_options: ['date', 'location', 'exam_date', 'product'],
        product: '',
        location: '',
        date_range: '',
    };

    const [table, setTable] = useState(initTable);
    const [filters, setFilters] = useState(initFilters);

    fill_table_from_url(initTable);
    fill_filters_from_url(initFilters, ['cancelled', 'active', 'has_spots', 'has_students'])

    const onMainRowClick = (item) => {
        setPopup({...popup,
            open: true,
            item: item,
            title: `Loading edit...`,
            loading: true
        })
    }

    const onDeleteClick = () => {
        if(table.selected.length) {
            const items = table.results.filter((val, idx) => {
                if(table.selected.indexOf(val.ID) !== -1)
                    return val;

                return false;
            })
            setDeletePopup({
                ...deletePopup,
                open: true,
                items: items,
                title: 'Delete Courses'
            })
        }
    }
    const onBulkClick = () => {
        if(table.selected.length) {
            const items = table.results.filter((val, idx) => {
                if(table.selected.indexOf(val.ID) !== -1)
                    return val;

                return false;
            })
            setBulkPopup({
                ...bulkPopup,
                open: true,
                items: items,
                title: 'Update Courses'
            })
        }
    }

    const refreshTable = async () => {
        setTable({...table, results: [], selected: [], results_count: table.results.length, loading: true})
        try {
            let params = {
                order: encodeURIComponent(table.order),
                order_by: encodeURIComponent(table.order_by),
                per_page: table.per_page,
                page: table.page
            };
            let fields = ['ID'];

            for(let field_name in table.columns) {
                let col = table.columns[field_name];
                if(col.visible) {
                    fields.push(col.field);
                }
            }

            if(fields.length > 1)
                params['fields'] = fields.map((v) => encodeURIComponent(v)).join(',');

            if(Object.keys(filters).length) {
                for(var k in filters) {
                    if(filters[k] !== '' && filters[k] !== null && filters[k] !== undefined)
                        params[k] =  typeof filters[k] === "number" ? filters[k] : encodeURIComponent(filters[k]);
                }
            }

            delete params['search_by_options'];

            let url = '';
            if(Object.keys(params).length) {
                for(let k in params) {
                    url += (url === '' ? '?' : '&') + k + '=' + params[k];
                }
            }

            url = table.get_url + url;
            const res = await API({ method: 'GET', url: url,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })
            if(res.status < 200 || res.status > 299)
                throw res.data;

            setTable({...table,
                selected: [],
                results: res.data.results,
                results_count: res.data.results.length,
                page: res.data.page,
                total_pages: res.data.pages,
                total_count: res.data.count,
                loading: false
            })
        }
        catch(e) {
            setTable({...table,
                results: [],
                selected: [],
                results_count: 0,
                total_pages: 0,
                total_count: 0,
                page: 1,
                loading: false
            })
            HandleError(e, table.title, enqueueSnackbar, app.logout);
        }
    }

    return <Main pageTitle="Courses">
        <ResultsTable manager="courses" onMainRowClick={onMainRowClick}
                      onBulkClick={onBulkClick}
                      onDeleteClick={app.has_cap('course', 'delete') ? onDeleteClick : null}
                      setTable={setTable} table={table}
                      refreshTable={refreshTable} setFilters={setFilters} filters={filters}
                      selectedRow={popup.item?.ID}>
            <FiltersCourse />
        </ResultsTable>
        <CoursePopup popup={popup} setPopup={setPopup} table={table} setTable={setTable} />
        <CourseDelete popup={deletePopup} setPopup={setDeletePopup} table={table} setTable={setTable} />
        <CourseBulk popup={bulkPopup} setPopup={setBulkPopup} table={table} setTable={setTable} />
    </Main>
}