import * as React from 'react';
import Main from "../components/Main";
import {useContext, useEffect, useState} from "react";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";
import AppContext from "../AppContext";
import SignupsPerCity from "../components/dashboard/SignupsPerCity";
import Signups from "../components/dashboard/Signups";
import {
    Grid,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {
    DoneAllOutlined,
    GroupsOutlined,
    OutboxOutlined,
    SchoolOutlined, WarningOutlined
} from "@mui/icons-material";
import DashboardWidget from "../components/dashboard/DashboardWidget";
import {fdatetime, make_url} from "../Common";

export default function Dashboard() {
    const app = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const [signups, setSignups] = useState({
        data: [],
        dataKeys: [],
        period: 'daily'
    })
    const [signupsPerCityData, setSignupsPerCityData] = useState({
        data: [],
        dataKeys: []
    })
    const [widgets, setWidgets] = useState({
        pending_students: 0,
        mail_queue: 0,
        online_users: 0,
        error_count: 0
    })

    useEffect(() => {
        onLoad()
    }, [])

    useEffect(() => {
        if(signups.reload)
            refreshSignups();
    }, [signups.reload])

    const onLoad = async () => {
        try {
            const res = await API({
                method: 'GET', url: `/ui/dashboard`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            setSignups({...signups, ...res.data.signups})
            setSignupsPerCityData({...res.data.signups_per_city})
            setWidgets({...widgets,
                pending_students: res.data.pending_students,
                mail_queue: res.data.mail_queue,
                online_users: res.data.online_users,
                error_count: res.data.error_count
            })
        }
        catch(e) {
            HandleError(e, 'Load Dashboard', enqueueSnackbar, app.logout);
        }
    }

    const refreshSignups = async () => {
        try {
            const res = await API({
                method: 'GET', url: `/ui/dashboard/signups?period=${signups.period}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            setSignups({...signups, ...res.data.result, reload: false})
        }
        catch(e) {
            setSignups({...signups, reload: false})
            HandleError(e, 'Load Dashboard', enqueueSnackbar, app.logout);
        }
    }

    const handleSignupsPeriod = (e, newValue) => {
        setSignups({...signups, period: newValue, reload: true })
    }
    const goToPendingStudents = () => {
        window.open(make_url('/students', { status: 'pending' }), "_blank")
    }
    const goToErrorLog = () => {
        window.open(make_url('/errors'), "_blank")
    }
    return <Main pageTitle="Dashboard">
        <Grid container spacing={2} sx={{mb: 2}}>
            <DashboardWidget lg={2} value={widgets.online_users} title={'Online Users'} icon={<GroupsOutlined />} />
            {app.can('student', 'read') && <DashboardWidget lg={2} value={widgets.pending_students} title={'Pending Students'}
                                                            icon={widgets.pending_students > 0 ? <SchoolOutlined /> : <DoneAllOutlined />} onClick={goToPendingStudents} />}
            {app.can('mail_queue', 'read') && <DashboardWidget lg={2} value={widgets.mail_queue} title={'Mail in Queue'} icon={<OutboxOutlined />} />}
            {app.can('error_log', 'read') && <DashboardWidget lg={2} value={widgets.error_count} title={'Error Count'}
                             icon={widgets.error_count > 0 ? <WarningOutlined /> : <DoneAllOutlined />} onClick={goToErrorLog} />}
            {app.user?.level >= 100 && <DashboardWidget cardProps={{ elevation: 0, style: { textAlign: 'right'} }} lg={4} value={fdatetime()} title={'Last Backup'} />}
        </Grid>
        <Grid container spacing={2} sx={{mb: 2}}>
            <DashboardWidget md={12} lg={6} title={<><span>Signups</span><ToggleButtonGroup sx={{float: 'right'}} size="small" exclusive value={signups.period} onChange={handleSignupsPeriod}>
                <ToggleButton value="daily">Daily</ToggleButton>
                <ToggleButton value="monthly">Monthly</ToggleButton>
                <ToggleButton value="yearly">Yearly</ToggleButton>
            </ToggleButtonGroup></>}>

                <Signups chartData={signups} setChartData={setSignups} />
            </DashboardWidget>
            <DashboardWidget md={12} lg={6} title="Signups Per City">
                <SignupsPerCity chartData={signupsPerCityData} setChartData={setSignupsPerCityData} />
            </DashboardWidget>
        </Grid>
    </Main>
}