import React, {useState} from 'react';
import {Chip} from "@mui/material";

const DEFAULT_STATE = {
    token: '',
    user: '',
    ui: {
        pageTitle: '',
        isDrawerOpen: true,
        performanceMode: true,
        colorEvents: true,
        darkMode: false,
        //server-side
        new_version: '',
        roles: [],
        statuses: {},
        order_statuses: {},
        order_products: {},
        products: {},
        locations: [],
        cbr_locations: [],
        courses: {},
        other_dates: {},
        payment_methods: {},
        mail_templates: {},
        whatsapp_templates: {},
        event_names: {},
        hw_stats: {},
        users: {}
    }
};

const AppContext = React.createContext({...DEFAULT_STATE});

export const AppDataProvider = ({ children }) => {

    let token = window.localStorage.getItem('token');
    if(token?.length <= 10)
        token = '';
    let user = window.localStorage.getItem('user');
    if(user?.length) {
        try {
            user = JSON.parse(user);
        }
        catch(e) {
            user = '';
        }
    }
    let ui = window.localStorage.getItem('ui');
    if(ui?.length) {
        try {
            ui = JSON.parse(ui);
        }
        catch(e) {
            ui = {...DEFAULT_STATE.ui};
        }
    }
    else {
        ui = {...DEFAULT_STATE.ui};
    }
    const [data, setData] = useState({...DEFAULT_STATE, token: token, user: user, ui: {
        ...DEFAULT_STATE.ui, ...ui
    } });

    const storeUI = () => {
        window.localStorage.setItem('ui', JSON.stringify({...data.ui}));
    }
    const login = (token, user, ui) => {
        console.log('Login');
        setData({...data, token: token, user: user, ui: {...data.ui, ...ui} });
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', JSON.stringify(user));
        window.localStorage.setItem('ui', JSON.stringify({...data.ui, ...ui}));
    }

    const storePing = (user, ui) => {
        setData({...data, user: user, ui: {...data.ui, ...ui} });
        window.localStorage.setItem('user', JSON.stringify(user));
        window.localStorage.setItem('ui', JSON.stringify({...data.ui, ...ui}));
    }

    const hasToken = () => {
        return data.token?.length > 10;
    }

    const isLoggedIn = () => {
        let is = data.user !== '' && data.user?.ID > 0 && data.token?.length > 10;
        console.log('Logged in?', is);
        return data.user !== '' && data.user?.ID > 0 && data.token?.length > 10;
    }

    const logout = () => {
        console.log('Logout');
        window.localStorage.setItem('token', '');
        window.localStorage.setItem('user', '');
        window.localStorage.setItem('ui', '');
        setData({...data, token: '', user: '' });
    }

    const openDrawer = () => {
        let ui = {...data.ui}
        ui['isDrawerOpen'] = true;
        setData({...data, ui: ui })
    }
    const closeDrawer = () => {
        let ui = {...data.ui}
        ui['isDrawerOpen'] = false;
        setData({...data, ui: ui })
    }

    const has_cap = (scope, permission, get_options) => {
        if(!data.user?.ID)
            return false;

        let options = [];
        for(var r in data.user.roles) {
            const role = data.user.roles[r];
            for(var cid in role.capabilities) {
                const cap = role.capabilities[cid];
                if(cap.scope === scope && cap.name === permission) {
                    if(!cap.options?.length)
                        return true;

                    for(let i = 0; i < cap.options.length; i++) {
                        if(options.indexOf(cap.options[i]) === -1)
                            options.push(cap.options[i])
                    }
                }
            }
        }
        return options.length ? (get_options ? options : true) : false;
    }
    const can = (scope, permission, options) => {
        if(!data.user?.ID)
            return false;

        if(!isNaN(scope) && !permission)
            return parseInt(scope) <= data.user.level;

        if(!options)
            options = [];
        else if(!Array.isArray(options))
            options = [options];

        let can = false;
        for(var r in data.user.roles) {
            if(can)
                break;

            const role = data.user.roles[r];
            for(var cid in role.capabilities) {
                if(can)
                    break;

                const cap = role.capabilities[cid];
                if(cap.scope === scope && cap.name === permission) {
                    if(!cap.options.length) {
                        can = true;
                        break;
                    }
                    let matches = 0;
                    for(let i = 0; i < options.length; i++) {
                        const o = options[i];
                        if(o.substr(0, 1) === '!' && cap.options.indexOf(o.substr(1)) === -1)
                            matches++;
                        else if(cap.options.indexOf(o) !== -1)
                            matches++;

                        can = matches === options.length ? cap.options : false;
                        if(can)
                            break;
                    }
                }
            }
        }
        // console.log(data.user.roles);
        //console.log(`UserCan: ${scope}:${permission} [${options}] ? ${can}`);
        return can;
    }

    //UI Helper functions
    const statusLabel = (status, props = { size: 'small' }) => {
        let name = data.ui.statuses?.[status] ?? status;
        let classes = {
            'pending': { variant: 'outlined', color: 'info' },
            'processed': { variant: 'standard', color: 'info' },
            'semi_confirmed': { variant: 'outlined', color: 'success' },
            'confirmed': { variant: 'standard', color: 'success' },
            'archived': { variant: 'outlined'},
            'oos': { variant: 'outlined', color: 'error' }
        }
        let defProps = classes?.[status] ?? {};
        return <Chip {...defProps} {...props} label={name} />
    }
    const orderStatusLabel = (status, props = { size: 'small' }) => {
        let name = data.ui.order_statuses?.[status] ?? status;
        let classes = {
            'pending': { variant: 'outlined', color: 'info' },
            'open': { variant: 'outlined', color: 'info' },
            'paid': { variant: 'outlined', color: 'success' },
            'authorized': { variant: 'outlined', color: 'success' },
            'canceled': { variant: 'outlined', color: 'error' },
            'expired': { variant: 'outlined', color: 'error' },
            'failed': { variant: 'outlined', color: 'error' }
        }
        let defProps = classes?.[status] ?? {};
        return <Chip {...defProps} {...props} label={name} />
    }
    const orderProductLabel = (product, props = { size: 'small' }) => {
        let name = data.ui.order_products?.[product] ?? product;
        let classes = {
            'all': { variant: 'outlined', color: 'success' },
            'prepaid': { variant: 'outlined', color: 'info' }
        }
        let defProps = classes?.[product] ?? {};
        return <Chip {...defProps} {...props} label={name} />
    }
    const paymentLabel = (student, props = { size: 'small' }) => {
        if(!student?.payment)
            return <Chip variant="outlined" label="Payment Unknown" {...props} />

        let map = {
            'free': 'Free',
            'paid': 'Paid (Manual)',
            'cash': 'Cash',
            'online': 'Online'
        }

        if(['paid'].indexOf(student?.payment) !== -1) {
            let out = []

            if(student.paid_amount > 0)
                out.push(<div style={{marginBottom: 5}}><Chip variant="outlined" color="success" label={`Paid €${student.paid_amount} (Online)`} {...props} /></div>)
            if(student.paid_cash_amount > 0)
                out.push(<Chip variant="outlined" color="success" label={`Paid €${student.paid_cash_amount} (Cash)`} {...props} />)

            return out
        }

        if(['free'].indexOf(student?.payment) !== -1 && student?.unpaid_amount == 0)
            return <Chip variant="outlined" color="success" label={map[student.payment]} {...props} />

        if(student?.unpaid_amount > 0) {
            let label = 'Due €' + student.unpaid_amount + ' (' + (map?.[student.payment] ?? student.payment) + ')';
            if(student?.paid_amount > 0)
                return <><Chip variant="outlined" color="error" label={label} {...props} /><div style={{marginTop: 5}}><Chip variant="outlined" color="default" label={`Paid €${student.paid_amount}`} {...props} /></div></>

            return <Chip variant="outlined" color="error" label={label} {...props} />
        }
        else {
            let label = 'Paid €' + student.total_price + ' (' + (map?.[student.payment] ?? student.payment) + ')';
            return <Chip variant="outlined" color="success" label={label} {...props} />
        }
    }

    const set = (newData) => {
        setData({...data, ...newData})
    }
    return (
        <AppContext.Provider value={{...data, set, storeUI, login, isLoggedIn, logout, hasToken, openDrawer, closeDrawer,
            can, has_cap, statusLabel, paymentLabel, orderStatusLabel, orderProductLabel, storePing}}>
            {children}
        </AppContext.Provider>
    )
};

export default AppContext;