import * as React from 'react';
import Main from "../components/Main";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import AppContext from "../AppContext";
import { Box } from "@mui/material";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "../App.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import API, {HandleError} from "../API";
import {useSnackbar} from "notistack";
import CalendarEventPopup from "../components/CalendarEventPopup";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

export default function CalendarPage() {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);
    const [events, setEvents] = useState([])
    const [popup, setPopup] = useState({
        loading: false,
        open: false,
        item: null,
        reload: true,
        title: '',
        maxWidth: 'sm',
        fullWidth: true
    });

    const [cal, setCal] = useState({
        view: 'month',
        date: ''
    })

    useEffect(() => {
        loadEvents()
    }, [cal.date])

    function Event({ event }) {
        return (
            <div><small>{event.product}</small> {event.location}</div>
        )
    }

    function EventAgenda({ event }) {
        return (
            <div>
                <div><small>{event.product}</small> {event.location}</div>
                <small style={{ wordWrap: 'break-word', display: 'block', overflowWrap: 'anywhere' }}>{event.desc}</small>
            </div>
        )
    }

    const { components, defaultDate } = useMemo(
        () => ({
            components: {
                agenda: {
                    event: EventAgenda,
                },
                event: Event,
            }
        }),
        []
    )

    const loadEvents = async () => {
        try {
            const res = await API({
                method: 'GET', url: `/calendar?date=${cal.date}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let formatted = []
            for(let x = 0; x < res.data.results.length; x++) {
                let d = res.data.results[x]
                d.start = moment(d.start).toDate();
                d.end = moment(d.end).toDate();
                //console.log(d.end)
                formatted.push(d)
            }
            setEvents([...formatted])
        }
        catch(e) {
            HandleError(e, 'Load Dashboard', enqueueSnackbar, app.logout);
        }
    }
    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = event?.color ? event.color : '#2196f3';
        var textColor = event?.textColor ? event.textColor : '#fff';
        var style = {
            backgroundColor: backgroundColor,
            opacity: 0.8,
            color: textColor,
            border: '0px',
        };
        return {
            style: style
        };
    }
    const onEventResize = (data) => {
        if(!data.event?.movable) {
            console.log('cannot be resized');
            return;
        }
        const { start, end } = data;
        console.log(data);
        //
        // this.setState((state) => {
        //     state.events[0].start = start;
        //     state.events[0].end = end;
        //     return { events: [...state.events] };
        // });
    };

    const onEventDrop = (data) => {
        if(!data.event?.movable) {
            console.log('cannot be moved');
            return;
        }
        console.log(data);
    };

    const handleSelectEvent = useCallback(
        (event) => setPopup({...popup, open: true, item: event, reload: true, title: moment(event.start).format('LLLL')}), []
    )

    return <Main pageTitle="Calendar">
        <Box sx={{ width: '100%', p: 6 }}>
            <Calendar
                defaultDate={moment().toDate()}
                defaultView="month"
                events={events}
                localizer={localizer}
                onEventDrop={onEventDrop}
                onEventResize={onEventResize}
                onNavigate={(date, view) => {
                    setEvents([])
                    setCal({ view: view, date: moment(date).format('YYYY-MM-DD') })
                }}
                onView={(view, date) => {
                    console.log(view, date);
                }}
                resizable
                style={{ height: 800 }}
                components={components}
                eventPropGetter={eventStyleGetter}
                onSelectEvent={handleSelectEvent}
            />
        </Box>
        <CalendarEventPopup popup={popup} setPopup={setPopup} />
    </Main>
}