import {FormControl, FormGroup, FormHelperText, Grid, TextField} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/material/styles";

const FormControlStyled = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1)
}))
const TextInput = ({ form, value, label, name, id, id_prefix, onChange, gridProps, inputProps, InputProps, disabled, canEdit}) => {
    gridProps = gridProps ?? {}
    inputProps = inputProps ?? {}
    const finalGridProps = {
        xs: 12,
        sm: 12,
        md: 12,
        ...gridProps
    }

    return <Grid item {...finalGridProps}>
        <FormGroup>
            <FormControlStyled error={name && form?.errorFields?.hasOwnProperty(name)}>
                <TextField
                    id={id ?? (id_prefix ? id_prefix + name : name)}
                    value={value ?? form?.fields?.[name] ?? form?.item?.[name]}
                    onChange={onChange}
                    label={label}
                    name={name}
                    disabled={disabled}
                    variant="outlined"
                    InputProps={InputProps}
                    inputProps={{readOnly: form.loading || (typeof canEdit === "function" && !canEdit(name)), ...inputProps}}
                />
                <FormHelperText>{form.errorFields?.[name]}</FormHelperText>
            </FormControlStyled>
        </FormGroup>
    </Grid>
}
export default TextInput;