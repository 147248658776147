import {LineChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Line, ResponsiveContainer} from 'recharts';
import {stringToColor} from "../../Common";
import {useContext} from "react";
import AppContext from "../../AppContext";
import {Button, ButtonGroup, useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";
const Signups = ({ chartData, setChartData }) => {
    const app = useContext(AppContext);
    const theme = useTheme();

    const palette = theme.palette;

    const style = {
        label: { color: palette.text.primary },
        content: { background: palette.background.default, borderColor: palette.action.selected, borderRadius: '5px' },
        cursor: { fill: palette.action.hover }
    }

    return <>
        <ResponsiveContainer width="100%" height={240}>
        <LineChart data={chartData.data}>
            <CartesianGrid strokeDasharray="3" />
            <XAxis dataKey="name" tick={{ fontSize: "13px" }} />
            <YAxis tick={{ fontSize: "13px" }} />
            <Tooltip cursor={style.cursor} labelStyle={style.label} contentStyle={style.content} />
            <Legend wrapperStyle={{ fontSize: "13px"}} />
            {chartData.dataKeys.map((key, idx) => {
                return <Line dataKey={key} type="monotone" key={idx} stroke={stringToColor(key, 36, 60)} />
            })}
        </LineChart>
    </ResponsiveContainer></>
}
export default Signups;