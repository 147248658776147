import * as React from 'react';
import Popup from "./Popup";
import {
    Box,
    Button, Chip,
    CircularProgress,
    DialogActions,
    List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper,
    Typography
} from "@mui/material";
import {useContext} from "react";
import { red } from '@mui/material/colors';
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import AvatarLetters from "./AvatarLetters";

export default function OrderDelete({ popup, setPopup, table, setTable, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);

    const onClose = () => {
        setPopup({...popup, open: false, items: [], title: '', loading: false });
    }

    const onConfirm = async () => {
        setPopup({...popup, loading: true, title: `Deleting ${popup.items?.length} orders...`})
        try {
            let ids = popup.items.map((item, idx) => item.ID);

            const res = await API({
                method: 'DELETE', url: `/orders?lock=1`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: { ids: ids }
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const deleted = res.data.deleted;
            const failed = res.data.failed;
            if(failed.length) {
                let msg = [`Couldn't delete ${failed.length} / ${ids.length} orders:`]
                failed.forEach((err, idx) => {
                    if(err.message)
                        msg.push(`#${err.ID}: ${err.message}`);
                })
                enqueueSnackbar(msg.join("\n"), {
                    variant: 'error',
                    style: { whiteSpace: 'pre-line' }
                });
            }
            if(!deleted.length) {
                if(!failed.length)
                    enqueueSnackbar(`No orders were deleted.`, { variant: 'error' });
            }
            else {
                const newResults = [...table.results]
                if(!failed.length) {
                    enqueueSnackbar(`Deleted ${deleted.length} order(s).`, {variant: 'success'});
                }
                else {
                    let msg = [`Deleted ${deleted.length} / ${ids.length} orders:`]
                    msg.push(deleted.join(', '));
                    enqueueSnackbar(msg.join("\n"), {
                        variant: 'warning',
                        style: {whiteSpace: 'pre-line'}
                    });
                }
                deleted.forEach((ID, idx) => {
                    //update visible row in table.
                    for (var i = 0; i < newResults.length; i++) {
                        if (newResults[i].ID === ID) {
                            newResults.splice(i, 1);
                        }
                    }
                })
                setTable({...table, results: newResults, selected: []})
            }
            onClose();
        }
        catch(e) {
            setPopup({...popup, loading: false, title: `Delete Orders`})
            HandleError(e, 'Delete Orders', enqueueSnackbar, app.logout);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose}>
            {popup.loading ? <CircularProgress /> :
                <>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Typography variant="overline" display="block" >
                            {popup.items.length > 1 ? `${popup.items.length} orders will be deleted:` : `Are you sure you want to delete this order?`}
                        </Typography>
                        <Paper>
                            <List round sx={{ width: '100%', maxHeight: 300, overflow: 'auto', bgcolor: 'background.paper'}}>
                                {popup.items?.map((item, idx) => {
                                    return (
                                        <ListItem
                                            key={idx}
                                            disablePadding
                                        >
                                            <ListItemButton>
                                                <ListItemText primary={`${item.transaction_id}`} />
                                                <Chip label={`#${item.ID}`} variant="outlined" />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Paper>
                    </Box>
                    {children}
                    <DialogActions>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button onClick={onConfirm} disabled={popup.loading}>Confirm</Button>
                            {popup.loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: red[400],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                        <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
                    </DialogActions>
                </>}
        </Popup>
    )
}