import * as React from 'react';
import Popup from "./Popup";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Divider, FormControlLabel, FormGroup,
    Grid, Stack, Switch,
    Typography
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import PopupItem from "./PopupItem";
import {ExpandMore} from "@mui/icons-material";
import MailHistory from "./MailHistory";

export default function StudentMail({ popup, setPopup, children }) {
    const { enqueueSnackbar } = useSnackbar();
    const app = useContext(AppContext);
    const [history, setHistory] = useState([])

    useEffect(() => {
        if(popup.item?.ID && popup.reload)
            onLoad();

    }, [popup.item, popup.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    const onLoad = async () => {
        setPopup({...popup, loading: true, reload: false, title: `Loading ${popup.item.full_name}'s mail history...` })
        try {
            const res = await API({
                method: 'GET', url: `/student/${popup.item.ID}/mail`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const history = res.data.results;

            setPopup({...popup, loading: false,  reload: false, title: `${popup.item.full_name} #${popup.item.ID}'s Mail History`})
            setHistory(history)
        }
        catch(e) {
            onClose()
            HandleError(e, 'Load Student Mail History', enqueueSnackbar, app.logout);
        }
    }

    const onClose = () => {
        setPopup({...popup, open: false, title: '', loading: false, reload: false });
        setHistory([])
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <Popup popup={popup} setPopup={setPopup} onClose={onClose} dialogActions={<DialogActions>
            <div style={{flex: '1 0 0'}} />
            <Button onClick={onClose} disabled={popup.loading} sx={{color: (theme) => theme.palette.grey[700]}}>Close</Button>
        </DialogActions>}>
            {popup.loading || !popup.item?.ID ? <Box sx={{py: 6, textAlign: 'center'}}><CircularProgress /></Box> :
                <>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <MailHistory history={history} setHistory={setHistory} item={popup.item} />
                    </Box>
                    {children}
                </>}
        </Popup>
    )
}