import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import AppContext from "../AppContext";

export default function PrivateRoute({ component: Component, ...rest }) {
    const app = useContext(AppContext);
    return <Route {...rest} render={
        props => {
            if (app.isLoggedIn()) {
                return <Component {...rest} {...props} />
            } else {
                return <Redirect to={
                    {
                        pathname: '/login',
                        state: {
                            from: props.location
                        }
                    }
                } />
            }
        }
    } />
}