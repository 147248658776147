import {BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer} from 'recharts';
import {stringToColor} from "../../Common";
import {useContext} from "react";
import AppContext from "../../AppContext";
import {useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";
const SignupsPerCity = ({ chartData, setChartData }) => {
    const app = useContext(AppContext);
    const theme = useTheme();

    const palette = theme.palette;

    const style = {
        label: { color: palette.text.primary },
        content: { background: palette.background.default, borderColor: palette.action.selected, borderRadius: '5px' },
        cursor: { fill: palette.action.hover }
    }

    return <ResponsiveContainer width={'100%'} height={240}>
        <BarChart data={chartData.data}>
            <CartesianGrid strokeDasharray="3" />
            <XAxis dataKey="name" tick={{ fontSize: "13px" }} />
            <YAxis tick={{ fontSize: "13px" }} />
            <Tooltip cursor={style.cursor} labelStyle={style.label} contentStyle={style.content} />
            {chartData.dataKeys.map((key, idx) => {
                return <Bar dataKey={key} key={idx} fill={stringToColor(key, 36, 60)} />
            })}
        </BarChart>
    </ResponsiveContainer>
}
export default SignupsPerCity;